import { Dialog } from "@mui/material";
import React from "react";

export default function GeneralPopUpModel({
  open,
  title,
  setOpen,
  componentToPassDown,
  dashBoard,
  paperClass,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          className: `p-3 general-popup-model ${
            dashBoard ? "general-popup-model_update" : ""
          } ${paperClass || ""}`,
        }}
      >
        <div
          class={dashBoard ? "cross-icon-cross" : "cross-icon"}
          onClick={() => setOpen(false)}
        >
          x
        </div>
        {title && (
          <>
            {" "}
            <div className={`popup-title ${dashBoard ? "text-center" : ""}`}>
              <h2 style={{ width: "90%", margin: "auto" }}>{title}</h2>
            </div>
            <hr />
          </>
        )}
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
