import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const ContentSettingApi = async (data) => {
  const requestObj = {
    path: `api/content_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const member_init_with_token_api = async () => {
  const requestObj = {
    path: `api/member_init/with_token/v3`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const remove_google_access_api = async () => {
  const requestObj = {
    path: `api/member/remove_google_access/remove`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const member_init_without_token = async () => {
  const requestObj = {
    path: `api/member_init/without_token`,
    method: "GET",
    headers: {
      // "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const navbarListAPi = async (data) => {
  const requestObj = {
    path: `api/member/menu_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
