import React from "react";
import { dd_date_format } from "src/utils/constants";

export default function CongratulationMessage(props) {
  const {
    thanks_description,
    handleCross,
    handleOpenCreateModal,
    missionContentSettings,
    missionComoleted,
    scheduleData,
    schedule,
  } = props;
  const replacedStringNote = (string) => {
    if (!string) return;
    return string
      .replace(/{completed_date}/g, dd_date_format(schedule.completed_at))
      .replace(/{rewarded_coins}/g, schedule.reward_coins);
  };

  return (
    <div className="unlock-video p-2 congratulation-message">
      <div className="unlock-popup-cross-icon" onClick={handleCross}>
        x
      </div>
      {thanks_description && (
        <div
          dangerouslySetInnerHTML={{
            __html: replacedStringNote(thanks_description),
          }}
          className="pe-3"
        ></div>
      )}
      {/* {missionComoleted &&
        missionContentSettings?.reward_post_button_text &&
        missionContentSettings?.allow_user_to_create_reward_feed && (
          <button
            className="mc-button-contained mb-3 mt-4"
            onClick={handleOpenCreateModal}
          >
            {missionContentSettings?.reward_post_button_text}
          </button>
        )} */}
      {missionContentSettings?.reward_post_button_text &&
        scheduleData?.allow_user_to_create_reward_feed && (
          <button
            className="mc-button-contained mb-3 mt-4"
            onClick={handleOpenCreateModal}
          >
            {missionContentSettings?.reward_post_button_text}
          </button>
        )}
    </div>
  );
}
