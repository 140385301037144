import React from "react";
import {
  communityfirecracker_1f9e8,
  communityHandScreenshot,
  communityHeart,
  communityMoneyImage,
} from "src/assets";
import CloseIcon from "@mui/icons-material/Close";

export default function HowToEarn({
  coin_description,
  like_description,
  stick_description,
  gratitude_description,
  setShowInfoBox,
}) {
  return (
    <div className="info-card">
      <h2 className="mb-3">How To Earn...</h2>
      <div
        className="cross-sigh"
        onClick={() => {
          setShowInfoBox(false);
        }}
      >
        <span>
          <CloseIcon />
        </span>
      </div>
      <div className="row mt-2">
        <div className="col-2 mt-1">
          <img src={communityMoneyImage} alt="" />
        </div>
        <div className="col-9 p-0">
          <p className="mb-0">{coin_description}</p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-2 mt-1">
          <img src={communityHeart} alt="" />
        </div>
        <div className="col-9 p-0">
          <p className="mb-0">{like_description}</p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-2 mt-1">
          <img src={communityfirecracker_1f9e8} alt="" />
        </div>
        <div className="col-9 p-0">
          <p className="mb-0">{stick_description}</p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-2 mt-1">
          <img src={communityHandScreenshot} alt="" />
        </div>
        <div className="col-9 p-0">
          <p className="mb-0">{gratitude_description}</p>
        </div>
      </div>
    </div>
  );
}
