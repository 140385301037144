import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Avatar } from "@mui/material";
import { useParams } from "react-router-dom";
import { mission_level_detail_New_api_load_more } from "src/DAL/MissionLevels/MissionLevels";
import { NotFoundNew, point } from "src/assets";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import { get_kmb_number } from "src/utils/formatNumber";
import { medal_1, medal_2, medal_3 } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "0%",
    marginTop: "20%",
    marginBottom: "20%",
  },
  resize: {
    fontSize: 20,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  minHeight: 380,
  maxHeight: 480,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  pt: 0,
  borderRadius: 1,
  overflow: "auto",
};

export default function CoinsLeadBoardPopup({
  openLikesModal,
  streakLeaderBoard,
  setStreakLeaderBoard,
  handleCloseSimpleBox,
  loadMorePath,
  setLoadMorePath,
}) {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { userInfo } = useContentSetting();

  const observer = useRef();
  const { mission__id } = useParams();
  const get_mission_detail = async () => {
    let PostData = {
      mission_id: mission__id,
      tab: "dashboard",
      data: "coins_leader_board",
    };
    const result = await mission_level_detail_New_api_load_more(
      loadMorePath,
      PostData
    );
    if (result.code == 200) {
      let newArray = streakLeaderBoard.concat(result.coins_leader_board_stats);

      setStreakLeaderBoard(newArray);

      setLoadMorePath(result.coins_load_more);
      setIsLoadingMore(false);
    } else {
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );
  const loadMoreData = () => {
    setIsLoadingMore(true);
    if (loadMorePath) {
      get_mission_detail(loadMorePath);
    }
  };

  return (
    <div>
      <Modal
        open={openLikesModal}
        onClose={handleCloseSimpleBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="create-post-title-form">
          <Box sx={style} className="modal-theme show-all-likes-box">
            <span
              className="cross-show-all-likes-model"
              onClick={handleCloseSimpleBox}
            >
              x
            </span>
          </Box>
          {streakLeaderBoard.length > 0 ? (
            streakLeaderBoard.map((item, index) => {
              return (
                <div
                  className={`d-flex justify-content-between align-items-center mt-2 `}
                  key={item._id}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ width: "70%" }}
                  >
                    {index == 0 ? (
                      <img
                        src={medal_1}
                        alt="medal_1"
                        className="me-2 medal-img"
                      />
                    ) : index == 1 ? (
                      <img
                        src={medal_2}
                        alt="medal_2"
                        className="me-2 medal-img  "
                      />
                    ) : index == 2 ? (
                      <img
                        src={medal_3}
                        alt="medal_3"
                        className="me-2  medal-img  "
                      />
                    ) : (
                      <span className="me-2 ms-2">{index + 1}. </span>
                    )}
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      alt={item.user_info?.name}
                      src={s3baseUrl + item.user_info?.profile_image}
                    />
                    <div className="user-name ms-2 d-flex align-items-center">
                      {item.user_info?.first_name +
                        " " +
                        item.user_info?.last_name}
                      {item.user_info?._id == userInfo._id && (
                        <img src={point} className={`pointer_hand`} />
                      )}
                    </div>
                  </div>
                  <div className="user-coins" style={{ width: "30%" }}>
                    {get_kmb_number(item.mission_attracted_coins)} Coins
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <RecordNotFound title="Data not found" show_new_image={true} />
            </>
          )}
          {loadMorePath ? (
            <div className="col-12 text-center">
              <button
                ref={lastBookElementRef}
                className="small-contained-button mt-3"
              >
                {isLoadingMore ? "Loading..." : "Load More"}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </div>
  );
}
