import React, { useEffect, useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import CoinsLeaderboardData from "./CoinsLeaderboardData";
import { DashboardAppNew } from "src/DAL/Calender/Calender";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const CoinsLeaderboard = ({ dashBoardData, currentUser }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const getEventListing = async (item) => {
    setIsLoading(true);
    const result = await DashboardAppNew(item.data._id);
    if (result.code === 200) {
      setData(result.dashboard_data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = dashBoardData?.all_badge_levels?.map((item, index) => {
    return {
      title: item.title,
      onClick: (e, item) => {
        getEventListing(item);
      },
      data: item,
      component: (
        <CoinsLeaderboardData
          coins_leader_board={
            data ? data.coins_leader_board : dashBoardData.coins_leader_board
          }
          currentUser={currentUser}
          isLoading={isLoading}
          badgeId={item._id}
        />
      ),
    };
  });

  useEffect(() => {
    const currentIndex = dashBoardData?.all_badge_levels.findIndex(
      (item) => item._id === dashBoardData?.current_badge_level._id
    );
    setTabValue(currentIndex);
  }, [dashBoardData]);

  return (
    <div className="w-100">
      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
        className="challenge-video-days-tabs"
      />
    </div>
  );
};

export default CoinsLeaderboard;
