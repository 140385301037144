import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "../../config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import {
  source_session_list_api,
  source_session_list_api_v1,
} from "src/DAL/SourceSession/SourceSession";
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function SourceSessionsListing() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();

  const [sourceSessionList, setSourceSessionList] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadMorePath, setLoadMorePath] = useState(
    `api/source_session/list_source_session_active_v1?page=0&limit=20`
  );
  const observer = useRef();

  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  const getFilteredData = (query) => {
    let dataToFilter = [...sourceSessionList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const handleClick = (object) => {
    navigate(`/source-session/${object._id}/source-session-recordings`);
  };

  const getSourceSessionDetail = async (loadMorePath, query) => {
    const path = loadMorePath
      ? loadMorePath
      : "api/source_session/list_source_session_active_v1?page=0&limit=20";

    let postData = {
      search: query ? query : "",
    };
    const result = await source_session_list_api_v1(path, postData);
    if (result.code === 200) {
      setSourceSessionList((prevSourceSessionList) => {
        const newSessions = result.source_session.map(
          (source_session_category) => ({
            ...source_session_category,
            card_title: source_session_category.title,
            card_short_description: source_session_category.short_description,
            card_image: s3baseUrl + source_session_category.image.thumbnail_1,
            card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
          })
        );
        if (loadMorePath) {
          return [...prevSourceSessionList, ...newSessions];
        } else {
          return [...newSessions];
        }
      });

      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  const searchFunction = (e) => {
    setIsLoading(true);
    if (e) {
      e.preventDefault();
    }
    localStorage.setItem("sourceSessionList", query);
    getSourceSessionDetail("", query);
  };
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );
  const loadMoreData = () => {
    setIsLoadingMore(true);
    if (loadMorePath) {
      getSourceSessionDetail(loadMorePath);
    }
  };
  useEffect(() => {
    let sourceSessionList = localStorage.getItem("sourceSessionList");
    if (sourceSessionList) {
      setQuery(sourceSessionList);
    }
    getSourceSessionDetail("", sourceSessionList);
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <h2>Source Sessions</h2>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-5 d-flex ">
            <FormControl
              sx={{ m: 1, width: "25ch" }}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                value={query}
                onChange={handleChangeSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
            <div className="mt-2 ms-1 me-1 text-end">
              <button
                className="small-contained-button"
                onClick={searchFunction}
              >
                Search
              </button>
            </div>{" "}
          </div>
        </div>
        {sourceSessionList.length > 0 ? (
          <CustomMUICard data={sourceSessionList} handleClick={handleClick} />
        ) : (
          <RecordNotFound title="Source Sessions Not Found" />
        )}
        {loadMorePath ? (
          <div className="col-12 text-center">
            <button
              ref={lastBookElementRef}
              className="small-contained-button mt-3"
            >
              {isLoadingMore ? "Loading..." : "Load More"}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
