import { Avatar, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { videoShowLock } from "src/assets";
import ImageBox from "src/components/GeneralComponents/ImageBox";
import { s3baseUrl } from "src/config/config";
import LockIcon from "@mui/icons-material/Lock";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
export default function MissionsJourney({ title, data }) {
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();
  const { mission__id } = useParams();
  const handleClose = () => {
    setImageUrl(null);
  };
  return (
    <div
      className="card p-2 members-leader-board mb-1"
      style={{
        maxHeight: "520px",
        overflow: "auto",
        height: "100%",
      }}
    >
      {imageUrl && (
        <ImageBox open={true} handleClose={handleClose} image_url={imageUrl} />
      )}
      <span className="challenge-heading unlock-color">{title}</span>
      <hr />
      <div className="d-flex justify-content-between flex-wrap">
        {data?.length > 0 ? (
          data.map((item, index) => {
            return (
              <>
                {item?.content_settings?.show_in_mission_journey && (
                  <div
                    className="journey-item position-relative"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(
                        `/mission-levels/schedule-details/${mission__id}/${item._id}`,
                        {
                          state: item,
                        }
                      );
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ width: "23%", position: "relative" }}>
                        <img
                          src={
                            s3baseUrl +
                            item?.content_settings?.mission_journey_image
                          }
                        />
                        {item.schedule_status == "locked" && (
                          <div className="popular_title">
                            <span className="lock-icon-journey">
                              <LockIcon
                                style={{ fontSize: "16px", color: "black" }}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                      <div style={{ width: "75%" }}>
                        <h3
                          className=" mb-0 ms-2 challenge-heading"
                          style={{ fontWeight: 500, fontSize: "14px" }}
                        >
                          {item?.content_settings?.mission_journey_title}
                        </h3>
                        <p className="badge-new-mission ms-2 mb-0">
                          {item?.content_settings?.mission_journey_description}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })
        ) : (
          <div className="no-data-image mt-2 w-100">
            <RecordNotFound title="Data not found" show_new_image={true} />
          </div>
        )}
      </div>
    </div>
  );
}
