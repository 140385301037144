import React from "react";
import { dd_date_format } from "src/utils/constants";

export default function InfoPopup(props) {
  const { handleCross, showInfoPopup } = props;
  return (
    <div className="unlock-video p-2 congratulation-message">
      <div className="unlock-popup-cross-icon" onClick={handleCross}>
        x
      </div>
      {showInfoPopup && (
        <div
          dangerouslySetInnerHTML={{
            __html: showInfoPopup,
          }}
          className="pe-3"
        ></div>
      )}
    </div>
  );
}
