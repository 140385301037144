import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_affiliate_links = async () => {
  const requestObj = {
    path: `api/member/list/affiliate_links`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_source_api_link = async (slug) => {
  const requestObj = {
    path: `api/member/create/branch_code/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_source_api_link_v1 = async (data) => {
  const requestObj = {
    path: `api/member/create/branch_code_v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
