import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import moment_tz from "moment-timezone";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const DateComponent = ({ date, handleChangeDate, userCreateAt }) => {
  const { userInfo } = useContentSetting();

  const today_date = moment_tz().tz(userInfo.time_zone).format("YYYY-MM-DD");
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format="DD-MM-YYYY"
          mask="__/__/____"
          inputFormat="DD-MM-YYYY"
          label="Date"
          value={date}
          fullWidth
          maxDate={today_date}
          minDate={new Date(userCreateAt || today_date)}
          onChange={(newValue) => {
            handleChangeDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              required
              className="inputs-fields"
              size="small"
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};
export default DateComponent;
