import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";
import moment from "moment";
import { s3baseUrl } from "src/config/config";

function CurrentPods({ livePods, startingSoonPods }) {
  const navigate = useNavigate();
  const handleNavigate = (item) => {
    navigate(`/pods/pods-detail/${item.room_slug}`);
  };

  const { userInfo, adminTimeZone } = useContentSetting();
  if (livePods.length > 0 || startingSoonPods.length > 0) {
    return (
      <>
        <div className={`profile-cards p-3 community-card-title mb-3`}>
          <h4>WHAT'S ON NOW</h4>
          {livePods.length > 0 && <h6>JOIN CURRENT PODS</h6>}
          {livePods &&
            livePods.map((pod, index) => {
              return (
                <div className="row community-current-pods" key={index}>
                  <div className="col-3 pe-0">
                    <img
                      src={s3baseUrl + pod.room_image.thumbnail_3}
                      onClick={() => {
                        handleNavigate(pod);
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="col-7">
                    <h5
                      className="text-start mb-1 cursor-pointer"
                      onClick={() => {
                        handleNavigate(pod);
                      }}
                    >
                      {htmlDecode(pod.title)}
                    </h5>
                    <div
                      className="pods-description"
                      dangerouslySetInnerHTML={{
                        __html: pod.short_description,
                      }}
                    ></div>
                    <p className="card-title mb-0 ">
                      {get_date_with_user_time_zone(
                        moment(pod.start_date).format("YYYY-MM-DD") +
                          " " +
                          pod.start_time,
                        "dddd h A",
                        userInfo.time_zone,
                        adminTimeZone
                      )}
                    </p>
                    <p className="card-title mb-0 ">
                      {pod.room_user.length} Attendees
                    </p>
                  </div>
                  <div className="col-2 p-0 text-center live-svg">
                    <p className="card-title mb-0">LIVE</p>
                    <Icon icon="mdi:checkbox-blank-circle" />
                  </div>
                </div>
              );
            })}
          {startingSoonPods &&
            startingSoonPods.map((pod, index) => {
              return (
                <div className="row community-current-pods mt-3" key={index}>
                  <div className="col-3 pe-0">
                    <img
                      src={s3baseUrl + pod.room_image.thumbnail_3}
                      onClick={() => {
                        handleNavigate(pod);
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="col-6">
                    <h5
                      className="text-start mb-1 cursor-pointer"
                      onClick={() => {
                        handleNavigate(pod);
                      }}
                    >
                      {htmlDecode(pod.title)}
                    </h5>
                    <div
                      className="pods-description"
                      dangerouslySetInnerHTML={{
                        __html: pod.short_description,
                      }}
                    ></div>
                    <p className="card-title mb-0 ">
                      {get_date_with_user_time_zone(
                        moment(pod.start_date).format("YYYY-MM-DD") +
                          " " +
                          pod.start_time,
                        "dddd h A",
                        userInfo.time_zone,
                        adminTimeZone
                      )}
                    </p>
                    {/* <p className="card-title mb-0 ">THURSDAY 5PM-6PM</p> */}
                    <p className="card-title mb-0 ">
                      {pod.room_user.length} Attendees
                    </p>
                  </div>
                  <div className="col-3 p-0 text-center starting-svg">
                    <p className="card-title mb-0">STARTING SOON</p>
                    <Icon icon="mdi:checkbox-blank-circle" />
                  </div>
                </div>
              );
            })}
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

export default CurrentPods;
