import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import moment_tz from "moment-timezone";

export default function ActionBarComponentProps({
  diaryDate,
  handleChangeDate,
  userCreateAt,
  containerProps = {},
}) {
  const { userInfo } = useContentSetting();
  const today_date = moment_tz().tz(userInfo.time_zone).format("YYYY-MM-DD");

  return (
    <div className="mission-detail" {...containerProps}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          className="static-date-picker"
          value={diaryDate}
          onChange={(newValue) => handleChangeDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
          maxDate={today_date}
          minDate={new Date(userCreateAt || today_date)}
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
