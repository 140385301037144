import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Divider,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { convertCurrencyToSign } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { makeStyles } from "@mui/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  AddtoCArt,
  removetoCArt,
  removetoCArtall,
} from "src/DAL/ShopApi/ApiShop";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CustomConfirmationShop from "./CustomConfirmationShop";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    width: "20px !important",
    height: "auto !important",
    position: "absolute",
    left: "-15px",
  },
}));
export default function CartPopover() {
  const { handleUpdateCard, cartElement, setCardElement } = useContentSetting();
  const anchorRef = useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const [loadingPopUp, setloadingPopUp] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [prevLength, setPrevLength] = useState(cartElement.length);
  const [deletePasssValue, setdeletePasssValue] = useState();
  const handleAgreeDelete = (item, index) => {
    let data_object = {
      item: item,
      index: index,
    };
    setdeletePasssValue(data_object);
    setOpenDelete(true);
  };

  const [isLoadingRemove, setIsLoadingRemove] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (cartElement.length === 0) {
      navigate(`/shop`);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRemove = (itemId) => {
    if (cartElement.length === 1) {
      setOpen(false);
      navigate(`/shop`);
    }
    setCardElement((data) => data.filter((item) => item._id !== itemId));
  };
  const handleRemoveall = (itemId) => {
    setCardElement([]);
  };
  const totalPrice = cartElement?.reduce((total, product) => {
    return total + product.selected_quantity * product.price;
  }, 0);
  const handleSubmit = async () => {
    setloadingPopUp(true);
    let postData = { product_id: deletePasssValue.item.product_id };
    const result = await removetoCArt(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenDelete(false);
      handleRemove(deletePasssValue.item._id);
      setloadingPopUp(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
      setIsLoadingRemove(null);
      setloadingPopUp(false);
    }
  };
  const handleSubmitAdd = async (item, quantityCount) => {
    console.log(item, "itemitemitem");
    let postData = {
      product_id: item.product_id,
      quantity: quantityCount,
      price: item.price,
    };
    const result = await AddtoCArt(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleIncrease = (item) => {
    let quantityCount = Number(item.selected_quantity) + 1;
    setCardElement((old) =>
      old.map((product) => {
        if (product._id == item._id) {
          return {
            ...product,
            selected_quantity: Number(product.selected_quantity) + 1,
          };
        } else {
          return product;
        }
      })
    );
    handleSubmitAdd(item, quantityCount);
  };
  const handleDecrease = (item) => {
    let quantityCount = Number(item.selected_quantity) - 1;
    if (item.selected_quantity > 1) {
      setCardElement((old) =>
        old.map((product) => {
          if (product._id == item._id) {
            return {
              ...product,
              selected_quantity: Number(product.selected_quantity) - 1,
            };
          } else {
            return product;
          }
        })
      );
    }
    handleSubmitAdd(item, quantityCount);
  };
  const handleChangeQuantity = (event, item) => {
    let value = event.target.value;
    if (value >= 0 && value <= item.remaining_quantity) {
      setCardElement((old) =>
        old.map((product) => {
          if (product._id == item._id) {
            return {
              ...product,
              selected_quantity: value ? value : "",
            };
          } else {
            return product;
          }
        })
      );
      if (value) {
        handleSubmitAdd(item, value);
      }
    }
  };

  const handleShoppingCart = () => {
    navigate(`/shop/shopping-checkout`);
    handleClose();
  };

  return (
    <>
      {/* {chatList.length > 0 && ( */}
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? "primary" : "default"}
        className="notification-icons-btn"
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Badge badgeContent={cartElement.length} color="error">
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
      {/* )} */}

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360, border: "none" }}
        className="notification-popover-paper"
      >
        <Box
          sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}
          className="notification-popover"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Shopping Cart</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {cartElement.length} item in your Shopping Cart
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar className="simplebar-content-wrapper  for-mobile">
          <List disablePadding>
            {cartElement.length > 0 ? (
              cartElement.map((item, index) => {
                return (
                  <div
                    className={"chat-list-user selected-chat d-flex p-2"}
                    key={index}
                  >
                    <div className="container">
                      <div className="row mb-1">
                        <div className="col-3 imgDetail">
                          <div>
                            {item?.image ? (
                              <Avatar
                                variant="square"
                                sx={{ height: "51px", width: "71px" }}
                                src={s3baseUrl + item?.image[0]?.image}
                                alt={item.name}
                              />
                            ) : (
                              ""
                            )}
                            {/* <img className="detail-img" /> */}
                          </div>
                        </div>
                        <div className="col-8 orderdes">
                          <h4>{item.name}</h4>

                          <div className="popover-counters">
                            <p className="mt-1">
                              {convertCurrencyToSign(item.currency)}
                              {item.price.toFixed(2)}
                            </p>
                            <Tooltip
                              title={`Quantity should be less than or equal to ${item.remaining_quantity}`}
                            >
                              <div className="detail-item-quantity-popover ms-4">
                                <div className="item-quantity-popover m-auto">
                                  <span
                                    onClick={
                                      item.selected_quantity <= 1
                                        ? ""
                                        : () => handleDecrease(item)
                                    }
                                    className={`cursor-pointer px-2 ${
                                      item.selected_quantity <= 1
                                        ? "item-quantity-diabled"
                                        : ""
                                    }`}
                                  >
                                    -
                                  </span>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    value={item.selected_quantity}
                                    onChange={(e) => {
                                      handleChangeQuantity(e, item);
                                    }}
                                  />
                                  <span
                                    className={`cursor-pointer px-2 ${
                                      item.selected_quantity >=
                                      item.remaining_quantity
                                        ? "item-quantity-diabled"
                                        : ""
                                    }`}
                                    onClick={
                                      item.selected_quantity >=
                                      item.remaining_quantity
                                        ? ""
                                        : () => handleIncrease(item)
                                    }
                                  >
                                    +
                                  </span>
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                          <span
                            style={{
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            total price:
                          </span>
                          <span
                            className="mt-1"
                            style={{
                              fontSize: "12px",
                              color: "gray",
                            }}
                          >
                            {" " + " " + convertCurrencyToSign(item.currency)}
                            {(item.price * item.selected_quantity).toFixed(2)}
                          </span>
                        </div>
                        <div className="col-1 position-relative">
                          <span
                            className="icon-container-card icon-postion"
                            onClick={() => handleAgreeDelete(item, index)}
                          >
                            x
                          </span>
                        </div>
                      </div>
                      <div className="row"></div>
                      <hr />
                    </div>
                  </div>
                );
              })
            ) : (
              <RecordNotFound title={"Items Not Found"} />
            )}
          </List>
        </Scrollbar>

        {cartElement.length > 0 && (
          <>
            <Divider />
            <div className="carttotla pt-2">
              <h3 style={{ color: "var(--portal-theme-primary)" }}>subtotal</h3>
              <h3>{"£" + totalPrice.toFixed(2)}</h3>
            </div>
            <Divider />
            <Box className="notification-popover" sx={{ p: 1 }}>
              <div>
                <Button
                  // fullWidth
                  sx={{ width: "50%" }}
                  disableRipple
                  onClick={() => setOpen(false)}
                  component={RouterLink}
                  to="/ShopCart"
                >
                  View All
                </Button>
                {cartElement.length >= 0 ? (
                  <Button sx={{ width: "50%" }} onClick={handleShoppingCart}>
                    Checkout
                  </Button>
                ) : (
                  <Button
                    sx={{ width: "50%" }}
                    className={` ${"disable-pointer"}`}
                    disableRipple
                    component={RouterLink}
                    onClick={""}
                  >
                    Checkout
                  </Button>
                )}
              </div>
            </Box>
          </>
        )}
      </MenuPopover>
      <CustomConfirmationShop
        open={openDelete}
        setOpen={setOpenDelete}
        setloadingPopUp={setloadingPopUp}
        loadingPopUp={loadingPopUp}
        title={"Are you sure you want to remove this item"}
        handleAgree={handleSubmit}
      />
    </>
  );
}
