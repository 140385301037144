import React from "react";
import { Card } from "@mui/material";
import { htmlDecode } from "src/utils/convertHtml";
import { toolsLockIcon } from "src/assets";
import {
  get_date_with_user_time_zone,
  is_lesson_locked,
} from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { s3baseUrl } from "src/config/config";
import { useNavigate } from "react-router-dom";

function  SideImageCard({ data, handleClick, programmesDetail }) {
  const { userInfo, adminTimeZone } = useContentSetting();
  const navigate = useNavigate();
  const handle_get_lock = (object) => {
    return is_lesson_locked(
      object,
      programmesDetail,
      userInfo.time_zone,
      adminTimeZone,
      get_date_with_user_time_zone
    );
  };

  const handleClickLesson = (object) => {
    if (handleClick) {
      handleClick(object);
      return;
    }
    if (programmesDetail && !handle_get_lock(object)) {
      navigate(`/lessons/${object.lesson_slug}`, {
        state: object,
      });
    }
  };

  return (
    <div className="row">
      {data.map((item, index) => {
        return (
          <div className={item.card_classes} key={index}>
            <Card
              className="side-image-mui-card"
              onClick={() => {
                handleClickLesson(item);
              }}
            >
              <div className="row p-3">
                <div className="col-lg-2 col-md-3 col-sm-12">
                  <img
                    className="side-image-mui-card-image"
                    src={item.card_image}
                    alt={item.card_title}
                  />
                </div>
                <div className="col-lg-7 col-md-6 col-sm-9">
                  <h4 className="side-image-mui-card-heading">
                    {htmlDecode(item.card_title)}
                  </h4>
                  <p className="normal-font">
                    {htmlDecode(item.card_short_description)}
                  </p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  {programmesDetail && handle_get_lock(item) == true && (
                    <span className="d-flex justify-content-between">
                      <span> {item?.lock_statement}</span>
                      <img
                        src={
                          item?.lock_icon
                            ? s3baseUrl + item?.lock_icon
                            : toolsLockIcon
                        }
                        width={22}
                        height={22}
                        className="ms-auto"
                      />
                    </span>
                  )}
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
}

export default SideImageCard;
