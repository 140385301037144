import { Card } from "@mui/material";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import { get_short_string } from "src/utils/constants";
import { get_video_height } from "./live_event_functions";

const ReplaysList = ({ categories, eventDetail }) => {
  const navigate = useNavigate();
  const liveChatRef = useRef(null);
  const { event_id, category_id } = useParams();
  const handleNavigate = (category_id) => {
    navigate(`/live-events/category/${eventDetail._id}/${category_id}`);
  };
  useEffect(() => {
    const updateHeight = () => {
      if (liveChatRef.current) {
        const live_comments = liveChatRef.current;
        const mainHeight = Number(localStorage.getItem("live_comments_height"));

        const height = mainHeight ? mainHeight : get_video_height();
        if (height) {
          live_comments.style.height = `${height - 50}px`;
        } else {
          console.error("get_video_height() did not return a valid height");
        }
      }
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [get_video_height]);
  if (!get_video_height()) {
    return null;
  }
  return (
    <>
      <Card
        style={{
          color: "white",
          overflowY: "scroll",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
        className="live-comments"
        ref={liveChatRef}
      >
        {categories.length > 0 ? (
          categories.map((category, index) => (
            <div
              className={`d-flex  replay-container w-100 ${
                index === 0 ? "mt-0" : "mt-1"
              }`}
              style={{
                backgroundColor: "#1d1c1d",
                padding: "12px",
                borderRadius: "2px",
                cursor: "pointer",
                border: category_id == category._id ? "2px solid #f6bd4b" : "",
              }}
              onClick={() => handleNavigate(category._id)}
            >
              <img
                src={
                  s3baseUrl +
                  (category?.image
                    ? category?.image?.thumbnail_1
                    : eventDetail?.images?.thumbnail_2)
                }
                alt="replay"
                style={{
                  height: "70px",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              />
              <div className="replay-details ms-2">
                <h3
                  className="card-title"
                  style={{
                    fontSize: "14px",
                    color: "white",
                    marginBottom: "10px",
                  }}
                >
                  {category.title}
                </h3>
                {category.short_description && (
                  <p style={{ fontSize: "12px", color: "#acacac" }}>
                    {get_short_string(category.short_description, 70)}
                  </p>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">
            <RecordNotFound />
          </div>
        )}
      </Card>
    </>
  );
};

export default ReplaysList;
