import React from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import AddIcon from "@mui/icons-material/Add";

export default function WinCreator(props) {
  const { getCardBg, handleOpenWinBox } = props;
  const { userInfo } = useContentSetting();
  return (
    <div
      className="pe-1 mb-3 win-story-card create-win-post-card-box"
      onClick={handleOpenWinBox}
    >
      <div className="position-relative schadule-card">
        <div
          className={`h-100 schedule-click p-0 mb-2`}
          style={{ background: getCardBg({ user: userInfo }) }}
        >
          <div className="story-user-name-box" onClick={handleOpenWinBox}>
            <div className="create-story-circule-border">
              <div className="create-story-circule">
                <AddIcon />
              </div>
            </div>
            <h6 className="story-user-name">Win Post</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
