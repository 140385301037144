import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import GeneralQuestionsList from "src/pages/GeneralQuestions/GeneralQuestionsList";
import { vectorTickIcon, videoShowLock } from "src/assets";
import CongratulationMessage from "src/components/MissionLevels/CongratulationMessage";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import DayVideoTab from "src/components/MissionLevels/DayVideoTab";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import {
  complete_mission_schedule_api,
  mission_level_detail_api,
  start_mission_api,
  unlock_mission_schedule_api,
  update_mission_schedule_data_api,
} from "src/DAL/MissionLevels/MissionLevels";
import UnlockVideo from "src/components/MissionLevels/UnlockVideo";
import {
  dd_date_format,
  dd_date_format_UPDATE,
  number_with_comma,
  two_date_difference,
} from "src/utils/constants";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { htmlDecode } from "src/utils/convertHtml";
import StartMission from "src/components/MissionLevels/StartMission";
import CongratulationAnimation from "src/components/challenges/CongratulationAnimation";
import CreatePostMission from "./CreatePostMission";
import moment from "moment";
import MissionDynamiteDiary from "../DynamiteDiary/MissionDynamiteDiary";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ScheduleDetailActions({
  missionSchedules,
  hide_Top_section,
}) {
  const { state } = useLocation();

  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openMissionConfirm, setOpenMissionConfirm] = useState(false);
  const [missionDetail, setMissionDetail] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const { mission__id, schadule_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabData, setTabData] = useState(0);
  const [missionResult, setMissionResult] = useState({});
  const [unLockCoin, setUnLockCoin] = useState(0);
  const [userCoin, setUserCoin] = useState(0);
  const [missionContentSettings, setMissionContentSettings] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [rewardData, setRewardData] = useState({});
  const [missionComoleted, setMissionCompleted] = useState(false);
  const [data, setData] = useState(null);
  const [hideSubmitButton, setHideSubmitButton] = useState(false);
  const [ToolObject, setToolObject] = useState(null);
  const [open, setOpen] = useState(false);
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setTabData(schedules[newValue]);
  };
  const handleOpenCreateModal = () => {
    setOpenModal(0);
    setOpenCreateModal(true);
  };

  const get_mission_detail = async () => {
    const result = await mission_level_detail_api(mission__id);
    if (result.code == 200) {
      setMissionResult(result);
      setRewardData(result.mission.content_settings);
      setMissionContentSettings(result.mission.content_settings);

      setMissionDetail({ ...result.mission, user_data: result.user_data });
      let mission_schedules = result.mission_schedules.map((item) => {
        item.allowed_actions.map((i) => {
          let find_answer = item.completed_allowed_actions.find(
            (j) => j._id == i._id
          );
          i.is_checked = Boolean(find_answer);
          return i;
        });
        return item;
      });
      if (missionSchedules) {
        let find_index = mission_schedules.findIndex(
          (item) => item._id == missionSchedules._id
        );
        if (find_index >= 0) {
          setTabValue(find_index);
          setTabData(mission_schedules[find_index]);
        }
      }

      setSchedules(mission_schedules);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });

      setIsLoading(false);
    }
  };

  const handleUnlock = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start Schedule", {
        variant: "error",
      });
      return;
    }
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setOpenConfirm(true);
    setTimeout(() => {
      setOpenModal(0);
    }, 500);
  };

  const handleConfirmSubmit = async () => {
    setIsSubmitting(true);
    const result = await unlock_mission_schedule_api(selectedSchedule._id);
    if (result.code == 200) {
      setSchedules((old) =>
        old.map((item) => {
          if (item._id === selectedSchedule._id) {
            item.schedule_status = "in_progress";
          }
          return item;
        })
      );
      setOpenConfirm(false);
      setIsSubmitting(false);
      setOpenModal(0);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleClick = (schedule) => {
    const today = moment();
    const startedAt = moment(schedule.started_at);
    setSelectedSchedule(schedule);
    if (schedule.schedule_status === "locked") {
      if (startedAt.isAfter(today, "day")) {
        let error = `You can't unlock it before ${dd_date_format_UPDATE(
          schedule.started_at
        )}`;
        enqueueSnackbar(error, { variant: "error" });
        return;
      }
    }
    setSelectedSchedule(schedule);
    setOpenModal(3);
  };

  const handleCheckStatement = async (tab_data) => {
    let postData = {
      allowed_actions: tab_data.allowed_actions
        .filter((item) => item.is_checked == true)
        .map((item) => ({ _id: item._id })),
    };
    await update_mission_schedule_data_api(tabData._id, postData);
  };

  const handleChangeAction = (id, isDisabled, schedule, action) => {
    if (isDisabled) return;
    if (action.is_checked) {
      return;
    }
    const ObjectToPassDown = {
      id,
      isDisabled,
      schedule,
      action,
    };
    if (action.action_type == "growth_tool") {
      setOpen(true);
      setToolObject(ObjectToPassDown);
    } else {
      complete_action(ObjectToPassDown);
    }
  };
  const complete_action = (data) => {
    const { id, schedule } = data;
    let schedule_data = [...schedules];
    let find_index = schedule_data.findIndex(
      (item) => item._id === schedule._id
    );
    let tab_data = schedule_data[find_index];
    tab_data.allowed_actions.map((item) => {
      if (item._id === id) {
        let is_checked = Boolean(item.is_checked);
        item.is_checked = !is_checked;
      }
      return item;
    });
    schedule_data[find_index] = tab_data;
    setSchedules(schedule_data);
    handleCheckStatement(tab_data);
  };

  const handleSuccess = (result) => {
    if (tabData.question_configration.thanks_description) {
      setOpenModal(2);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
    }
  };

  const handleSubmit = async (e, schedule) => {
    if (e) {
      e.preventDefault();
    }

    let find = tabData.allowed_actions.find(
      (item) => item.is_required === true && !item.is_checked
    );
    if (find) {
      enqueueSnackbar("Actions with * must be checked", { variant: "error" });
      return;
    }
    setIsSubmitting(true);

    let postData = {
      allowed_actions: tabData.allowed_actions
        .filter((item) => item.is_checked)
        .map((item) => ({ _id: item._id })),
    };
    const result = await complete_mission_schedule_api(tabData._id, postData);

    if (result.code == 200) {
      setMissionCompleted(result.user_mission_completed);
      setSchedules(result.mission_schedules);
      if (tabData.content_settings?.congratulation_description) {
        setOpenModal(4);
      } else {
        enqueueSnackbar(result.message, { variant: "success" });
      }
      setIsSubmitting(false);
    } else {
      if (result.is_question_required) {
        setOpenModal(1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
      setHideSubmitButton(false);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitting(false);
    }
  };

  const openQuestionsPopup = (e, schedule) => {
    e.preventDefault();
    if (schedule.selected_questions.length > 0) {
      setOpenModal(1);
    } else {
      handleSubmit(e);
    }
  };
  const handleStartMission = async () => {
    const result = await start_mission_api(missionDetail._id);
    if (result.code == 200) {
      let mission_status = "in_progress";
      let user_data = result.userMission.user_info;
      setMissionDetail((old) => ({ ...old, mission_status, user_data }));
      setSchedules(result.userMission.mission_schedules);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setOpenMissionConfirm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setOpenMissionConfirm(false);
    }
  };

  const handleMissionStart = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start this Mission", {
        variant: "error",
      });
      return;
    }
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setOpenMissionConfirm(true);
    setTimeout(() => {
      setOpenModal(0);
    }, 100);
  };

  const handleClickStart = () => {
    if (missionDetail.mission_locked) {
      enqueueSnackbar(
        "You can't start this mission because your level does not match with the level of this mission",
        {
          variant: "error",
        }
      );
      return;
    }
    setOpenModal(5);
  };
  const handleDetail = (disabled, schedule) => {
    if (disabled) {
      return;
    }
    navigate(
      `/mission-levels/schedule-details/${
        mission__id ?? "66a722347421ac375573836f"
      }/${schedule._id}`,
      {
        state: schedule,
      }
    );
  };

  useEffect(() => {
    get_mission_detail();
  }, []);
  useEffect(() => {
    let newObject = schedules.find((item) => item._id === missionSchedules._id);
    setData(newObject);
  }, [schedules, missionSchedules]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  // let data = missionSchedules;

  // console.log(data.content_settings, "datadatadata");
  // const TABS_OPTIONS = [];
  // schedules.map((schedule, index) => {
  //   TABS_OPTIONS.push({
  //     title: schedule.title,
  //     component: (
  //       <>
  //         {schedule.schedule_status === "locked" && (
  //           <div className="row mt-3">
  //             <div className="locked-component">
  //               <Tooltip
  //                 title={`${
  //                   missionDetail.mission_status === "not_started"
  //                     ? "Start mission before unlock schedule"
  //                     : "Click to unlock"
  //                 }`}
  //               >
  //                 <img
  //                   src={videoShowLock}
  //                   alt=""
  //                   className="thumbnail-icon"
  //                   onClick={() => handleClick(schedule)}
  //                 />
  //               </Tooltip>
  //             </div>
  //           </div>
  //         )}
  //         <DayVideoTab
  //           setOpenModal={setOpenModal}
  //           schedule={schedule}
  //           handleChangeAction={handleChangeAction}
  //           isSubmitting={isSubmitting}
  //           handleSubmit={handleSubmit}
  //         />
  //       </>
  //     ),
  //   });
  // });

  const today = moment();
  const startedAt = moment(data.started_at);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between challenge-video-detail-title">
              {missionDetail.mission_status === "not_started" &&
                missionDetail.content_settings?.start_mission_button_text && (
                  <div className="mb-2">
                    <button
                      className="mc-button-contained"
                      onClick={handleClickStart}
                    >
                      {
                        missionDetail.content_settings
                          ?.start_mission_button_text
                      }
                    </button>
                  </div>
                )}
            </div>
          </div>
          {/* <div className="col-12">
            <div className="row">
              <div className="col-lg-4 mb-3">
                <div className="mc-day-input">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h6>Coins attracted</h6>
                    <div className="d-flex align-items-center">
                      <span>{`${
                        missionDetail.attracted_coins || 0
                      } coins`}</span>
                      <img src={vectorTickIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="mc-day-input">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div>
                      <h6>Coins target</h6>
                    </div>
                    <div className="d-flex align-items-center">
                      <span>{`${missionDetail.target_coins || 0} coins`}</span>
                      <img src={vectorTickIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {missionResult.mission_start_date && (
                <div className="col-lg-4 mb-3">
                  <div className="mc-day-input">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h6>{`You are on day ${
                        two_date_difference(
                          missionResult.mission_start_date,
                          new Date(),
                          "days"
                        ) + 1
                      } of day ${missionDetail.mission_duration} 🔥`}</h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div> */}

          <div className="col-12">
            {/* <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
              className="mission-levels-schedule-tab challenge-video-days-tabs"
            /> */}
            <>
              <div className="row mt-3">
                <div className="locked-component">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <h3
                      className={`${"unlock-color"} mb-1 ms-1 challenge-heading`}
                      style={{ fontWeight: "500" }}
                    >
                      {data.title}
                    </h3>
                    {data.schedule_status === "locked" ? (
                      <>
                        {/* <Tooltip
                          title={`${
                            data.mission_status === "not_started"
                              ? "Start mission before unlock schedule"
                              : startedAt.isAfter(today, "day")
                              ? `You can't unlock it before ${dd_date_format(
                                  data.started_at
                                )}`
                              : "Click to unlock"
                          }`}
                        >
                          <img
                            src={videoShowLock}
                            alt=""
                            onClick={
                              startedAt.isAfter(today, "day")
                                ? ""
                                : () => handleClick(data)
                            }
                            className="thumbnail-icon me-2 unlockIconNew "
                          />
                        </Tooltip> */}
                      </>
                    ) : (
                      <span
                        className="view-detail-button me-2"
                        onClick={() =>
                          handleDetail(data.schedule_status === "locked", data)
                        }
                      >
                        View Schedule
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <DayVideoTab
                setOpenModal={setOpenModal}
                schedule={data}
                handleChangeAction={handleChangeAction}
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                handleUnlock={handleClick}
                openQuestionsPopup={openQuestionsPopup}
                hide_Top_section={hide_Top_section}
                hideSubmitButton={hideSubmitButton}
              />
            </>
          </div>
        </div>
      </div>
      <GeneralModelBox
        open={Boolean(openModal)}
        setOpen={setOpenModal}
        className={"challenge-video-days-questions-modal"}
        componentToPassDown={
          <>
            {openModal === 1 ? (
              <>
                <div className="questions-top-section">
                  <div
                    className="unlock-popup-cross-icon"
                    onClick={() => {
                      setOpenModal(0);
                      setHideSubmitButton(false);
                    }}
                  >
                    x
                  </div>
                </div>
                <GeneralQuestionsList
                  created_for="mission"
                  mission__id={mission__id}
                  mission_schedule_id={data._id}
                  remove_back_button={true}
                  hide_dynamite_reply={true}
                  prevent_single_answer={true}
                  handleSubmitMissionFinal={handleSubmit}
                  className="challenge-video-days-questions"
                  collapseAll={true}
                  for_mission={true}
                  submit_button_text={
                    tabData.question_configration?.submit_button_text
                  }
                  onSubmitSuccess={handleSuccess}
                />
              </>
            ) : openModal === 2 ? (
              openModal && (
                <CongratulationMessage
                  handleCross={() => setOpenModal(0)}
                  thanks_description={
                    tabData.question_configration?.thanks_description
                  }
                />
              )
            ) : openModal === 4 ? (
              openModal && (
                <CongratulationMessage
                  handleCross={() => setOpenModal(0)}
                  handleOpenCreateModal={handleOpenCreateModal}
                  missionContentSettings={missionContentSettings}
                  missionComoleted={missionComoleted}
                  scheduleData={data?.content_settings}
                  thanks_description={
                    tabData?.content_settings?.congratulation_description
                  }
                />
              )
            ) : openModal === 5 ? (
              openModal && (
                <StartMission
                  handleCross={() => setOpenModal(0)}
                  missionDetail={missionDetail}
                  handleUnlock={handleMissionStart}
                  isSubmitting={isSubmitting}
                />
              )
            ) : (
              openModal && (
                <UnlockVideo
                  handleCross={() => setOpenModal(0)}
                  selectedSchedule={selectedSchedule}
                  handleUnlock={handleUnlock}
                  isSubmitting={isSubmitting}
                />
              )
            )}
          </>
        }
      />
      <CustomConfirmation
        open={openConfirm}
        setOpen={setOpenConfirm}
        title={`Unlocking this Schedule will leave you with a remaining balance of ${number_with_comma(
          userCoin - unLockCoin
        )} coins. Are you sure you want to proceed?`}
        handleAgree={handleConfirmSubmit}
        isLoading={isSubmitting}
      />
      <CustomConfirmation
        open={openMissionConfirm}
        setOpen={setOpenMissionConfirm}
        title={`Starting this Mission will leave you with a remaining balance of ${number_with_comma(
          userCoin - unLockCoin
        )} coins. Are you sure you want to proceed?`}
        handleAgree={handleStartMission}
        isLoading={isSubmitting}
      />
      <>
        <CreatePostMission
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          rewardData={rewardData}
        />
      </>
      <WhatsappPopUpModel
        open={open}
        setOpen={setOpen}
        // title={"Dynamite Diary"}
        show_small_title={true}
        show_date_and_income={true}
        componentToPassDown={
          <>
            <MissionDynamiteDiary
              toolObject={ToolObject}
              complete_action={complete_action}
              setSchedules={setSchedules}
              schedules={schedules}
              setOpen={setOpen}
            />
          </>
        }
      />
    </>
  );
}
