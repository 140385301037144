import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { _change_currency } from "src/DAL/GeneralAPIs/GeneralAPIs";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const currnencyText = {
  gbp: "UK Pounds",
  eur: "Euro",
};

const replacedString = (string, selected) => {
  if (string) {
    string = string.replace(
      /{exchange_rate}/g,
      `<span class="text-golden">${selected?.cost_in_one_pound?.toFixed(
        2
      )}</span>`
    );
    string = string.replace(
      /{to_currency}/g,
      currnencyText[selected.which_currency_using.toLowerCase()] +
        " (" +
        selected.which_currency_using.toUpperCase() +
        ")"
    );
    return string;
  }
};

export default function CurrencyExchange(props) {
  const {
    selectedFireCountry,
    totalAmount,
    inputs,
    setTotalAmount,
    setInputs,
    billingObject,
    setSelectedFireCountry,
    grandTotal,
    isBankPayment,
  } = props;

  const { shopContentSettings, bankCurrencies } = useContentSetting();
  const { country, is_shipping_same_as_billing } = inputs;
  const [isCalculating, setIsCalculating] = useState(false);

  const handleCurrency = async (to) => {
    setIsCalculating(true);
    let postData = {
      from: "gbp",
      to: to.toLowerCase(),
      amount: grandTotal,
    };
    const result = await _change_currency(postData);
    if (result.code === 200) {
      setTotalAmount(result.data);
      setInputs((old) => ({ ...old, currency: to.toLowerCase() }));
      setIsCalculating(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsCalculating(false);
    }
  };

  const handle_change_currency = (country) => {
    let find = bankCurrencies.find((c) => c.value == country?.value);
    let to = find?.which_currency_using?.toLowerCase();
    setSelectedFireCountry(find);
    if (!find) {
      setTotalAmount(grandTotal);
      setSelectedFireCountry(null);
      setInputs((old) => ({ ...old, currency: "gbp" }));
      return;
    } else if (to == "gbp") {
      setInputs((old) => ({ ...old, currency: "gbp" }));
      setTotalAmount(grandTotal);
      return;
    }
    handleCurrency(to);
  };

  useEffect(() => {
    if (!isBankPayment) {
      setInputs((old) => ({ ...old, currency: "gbp" }));
      setTotalAmount(grandTotal);
      return;
    }
    handle_change_currency(country);
  }, [
    grandTotal,
    country,
    billingObject.country,
    is_shipping_same_as_billing,
    isBankPayment,
  ]);

  if (inputs.currency == "gbp" || !selectedFireCountry || !isBankPayment)
    return null;

  return (
    <>
      <tr>
        <td colSpan="3" className="text-right">
          <div
            dangerouslySetInnerHTML={{
              __html: replacedString(
                shopContentSettings.currency_conversion_text,
                selectedFireCountry
              ),
            }}
          ></div>
        </td>
        <td style={{ color: "var(--portal-theme-primary)" }}>
          {isCalculating ? (
            <CircularProgress className="calculating-custom-duty" />
          ) : (
            <>€{Number(totalAmount?.toFixed(2))}</>
          )}
        </td>
      </tr>
    </>
  );
}
