import React from "react";
import { Card, Typography, Button, Grid, Box } from "@mui/material";
import { fShortenNumber } from "src/utils/formatNumber";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

const MissionCard = ({ current_mission }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/mission-levels/level-details/${current_mission._id}`);
  };
  return (
    <Card
      id="mission-card"
      style={{
        backgroundColor: "#1c1c1c",
        color: "#fff",
        padding: "20px",
        borderRadius: "0px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        position: "relative", // Set the card to relative positioning
        overflow: "hidden", // Ensure content stays inside the card
        width: "100%",
      }}
    >
      {current_mission ? (
        <>
          <Box style={{ marginBottom: "10px" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "400",
                fontSize: "16px",
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                role="img"
                aria-label="icon"
                style={{ marginRight: "10px" }}
              >
                <img
                  src="https://dd-feature-app-bucket.s3.amazonaws.com/membership_level/324162d2-dcd6-4acf-98ef-6eb6b0f34c03.png"
                  alt="icon"
                  style={{ width: "20px" }}
                />
              </span>
              {current_mission.mission_title}
            </Typography>
          </Box>

          <div className="header bg-mission mb-3">
            <div className="d-flex justify-content-between">
              <div className="bg-info-of-mission text-center">
                <div className="title-mission">Level</div>
                <div className="info-of-mission-text">
                  {current_mission?.mission_level_info?.title}
                </div>
              </div>
              <div className="bg-info-of-mission text-center">
                <div className="title-mission">Coins</div>
                <div className="info-of-mission-text">
                  {" "}
                  {fShortenNumber(current_mission.attracted_coins)}
                </div>
              </div>
              <div className="bg-info-of-mission text-center">
                <div className="title-mission">Days</div>
                <div className="info-of-mission-text">
                  {" "}
                  {current_mission.completed_days}/{current_mission.total_days}
                </div>
              </div>
            </div>
          </div>

          <Typography
            variant="body2"
            style={{ marginBottom: "55px", lineHeight: "1.5", color: "#ccc" }}
          >
            {current_mission.short_description}
          </Typography>

          <Box
            style={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <button
              className="small-contained-button"
              style={{
                width: "100%",
                padding: "10px 15px",
                fontSize: "14px",
              }}
              onClick={handleNavigate}
            >
              Go To Mission
            </button>
          </Box>
        </>
      ) : (
        <div className="mt-3">
          <RecordNotFound title="Mission not found" />
        </div>
      )}
    </Card>
  );
};

export default MissionCard;
