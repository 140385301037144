import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { calculate_custom_duty_api } from "src/DAL/ShopApi/ApiShop";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_address } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";

export default function CustomDutyTax(props) {
  const { customDutyTax, setCustomDutyTax, inputs, billingObject } = props;
  const { country, state, is_shipping_same_as_billing } = inputs;
  const { order_id } = useParams();

  const { shopContentSettings } = useContentSetting();
  const [isCalculating, setIsCalculating] = useState(false);

  const calculate_custom_duty = async (data) => {
    setIsCalculating(true);
    let postData = { ...data };
    if (order_id) {
      postData.order = order_id;
    }
    const result = await calculate_custom_duty_api(postData);
    if (result.code === 200) {
      setCustomDutyTax(result.total_custom_duty);
      setIsCalculating(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsCalculating(false);
    }
  };

  const handle_custom_duty = (country, state) => {
    if (country) {
      let postData = {
        shipping_object: {
          state: get_address(state),
          country: country?.value,
        },
        currency: "gbp",
      };
      calculate_custom_duty(postData);
    } else {
      setCustomDutyTax(0);
    }
  };

  useEffect(() => {
    if (is_shipping_same_as_billing) {
      handle_custom_duty(country, state);
    } else {
      handle_custom_duty(billingObject.country, billingObject.state);
    }
  }, [country, billingObject.country, is_shipping_same_as_billing]);

  return (
    <tr>
      <td colSpan="3" className="text-right">
        {htmlDecode(shopContentSettings.custom_duty_text)}
      </td>
      <td style={{ color: "var(--portal-theme-primary)" }}>
        <>
          {isCalculating ? (
            <CircularProgress className="calculating-custom-duty" />
          ) : (
            <>£{customDutyTax?.toFixed(2)}</>
          )}
        </>
      </td>
    </tr>
  );
}
