import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
// material
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "../../config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import {
  source_session_recording_list_api,
  source_session_recording_list_api_v1,
} from "src/DAL/SourceSession/SourceSession";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SourceSessionsRecordingList() {
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [recoringsData, setRecoringsData] = useState([]);
  const [sourceSessionDetail, setSourceSessionDetail] = useState({});
  const [query, setQuery] = useState("");

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadMorePath, setLoadMorePath] = useState(
    `api/source_session/list_source_session_active_v1?page=0&limit=1`
  );
  const observer = useRef();

  const handleClick = (object) => {
    navigate(
      `/source-session/${params.sesson_id}/source-session-recording/${object.source_session_recording_slug}`
    );
  };

  const getSourceSessionListing = async (query, url) => {
    setQuery(query);

    const path = url
      ? url
      : `api/source_session_recording/source_session_recording_list_by_source_session_v1/${
          params.sesson_id
        }?page=0&limit=20&search_text=${query ? query : ""}`;

    const result = await source_session_recording_list_api_v1(path);

    if (result.code === 200) {
      setRecoringsData((prevSourceSessionList) => {
        const newSessions = result.source_session_recording.map(
          (recording) => ({
            ...recording,
            card_title: recording.title,
            card_short_description: recording.short_description,
            card_image:
              s3baseUrl + recording.session_recording_images.thumbnail_2,
            card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
          })
        );
        if (loadMorePath) {
          return [...prevSourceSessionList, ...newSessions];
        } else {
          return [...newSessions];
        }
      });
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setSourceSessionDetail(result.source_session);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/source-session`);
    }
  };
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };
  const searchFunction = (e) => {
    setIsLoading(true);
    if (e) {
      e.preventDefault();
    }

    localStorage.setItem("search_source", query);
    getSourceSessionListing(query, "");
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );
  const loadMoreData = () => {
    setIsLoadingMore(true);
    if (loadMorePath) {
      getSourceSessionListing(query, loadMorePath);
    }
  };

  useEffect(() => {
    let querySearch = localStorage.getItem("search_source");
    if (querySearch) {
      getSourceSessionListing(querySearch, "");
    } else {
      getSourceSessionListing(query, "");
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin ">
          <div className="col-12 d-flex w-100 justify-content-between ">
            <form onSubmit={searchFunction} className="w-100">
              <div className="row section-space">
                <div className="col-lg-8 col-md-8 col-sm-12 d-flex">
                  <span>
                    <IconButton
                      className="back-screen-button"
                      onClick={() => navigate(`/source-session`)}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </span>

                  <h2>{sourceSessionDetail.title}</h2>
                </div>{" "}
                <div className="col-lg-4 col-md-4 col-sm-5 d-flex ">
                  <FormControl
                    sx={{ m: 1, width: "25ch" }}
                    variant="outlined"
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Search
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      value={query}
                      onChange={handleChangeSearch}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Search"
                    />
                  </FormControl>
                  <div className="mt-2 ms-1 me-1 text-end">
                    <button className="small-contained-button">Search</button>
                  </div>{" "}
                </div>
              </div>
            </form>
          </div>
        </div>

        {recoringsData.length > 0 ? (
          <CustomMUICard data={recoringsData} handleClick={handleClick} />
        ) : (
          <RecordNotFound title="Source Sessions Recordings Not Found" />
        )}
        {loadMorePath ? (
          <div className="col-12 text-center">
            <button
              ref={lastBookElementRef}
              className="small-contained-button mt-3"
            >
              {isLoadingMore ? "Loading..." : "Load More"}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
