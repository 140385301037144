import {
  Accordion,
  AccordionSummary,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryCard from "src/components/NintyDay/HistoryCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { convertCurrencyToSign, dd_date_format } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ChartJsHistory from "src/components/NintyDay/ChartJsHistory";
import {
  deleteDiaryApi,
  get_diaries_list_api,
} from "src/DAL/dynamiteDiary/dynamiteDiary";
import moment from "moment";
import { htmlDecode } from "src/utils/convertHtml";
import { cointlogo } from "src/assets";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PastDiariesList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const classes = useStyles();
  const { userInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [diariesList, setDiariesList] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [contentSettings, setContentSetting] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const expandArea = (index) => {
    let temp_state = [...diariesList];
    let temp_element = { ...temp_state[index] };
    temp_element.isExpanded = !temp_element.isExpanded;
    temp_state[index] = temp_element;
    setDiariesList(temp_state);
  };

  const get_diaries_list = async (path) => {
    const result = await get_diaries_list_api(path);
    if (result.code == 200) {
      setContentSetting(result);
      let new_array = result.dynamite_diaries;
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more);
      if (path) {
        setDiariesList((old) => [...old, ...new_array]);
        setPageNumber((old) => old + 1);
      } else {
        new_array = new_array.map((item, index) => ({
          ...item,
          isExpanded: index === 0,
        }));
        setDiariesList(new_array);
        setPageNumber(1);
      }
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    get_diaries_list(loadMorePath);
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setSelectedObject(value);
  };

  const handleDeleteDiary = async () => {
    const result = await deleteDiaryApi(selectedObject._id);
    if (result.code == 200) {
      setDiariesList((old) =>
        old.filter((item) => item._id !== selectedObject._id)
      );
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };

  const handleNavigateToDate = (date) => {
    navigate(`/dynamite-diary?diary_date=${date}`, {
      state: { pastactivity: true },
    });
  };

  const handleEdit = (value) => {
    setIsLoading(true);
    let todayDate = moment(value.date, "DD-MM-YYYY").format("YYYY-MM-DD");
    handleNavigateToDate(todayDate);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    get_diaries_list();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container past-diaries-list-container">
        <div className="d-flex align-items-center">
          <IconButton
            className="back-screen-button me-2"
            onClick={() => navigate("/dynamite-diary")}
          >
            <ArrowBackIcon />
          </IconButton>
          <div className="d-flex justify-content-between flex-grow-1">
            <h2>Past Daily Dynamite</h2>
            {/* <div className="total-coins-attracted d-flex">
              <img className="img-fluid" src={cointlogo}></img>
              <div className="me-5 ms-1">
                <div className="coint-title">Total Coins</div>
                <div>{contentSettings.total_coins_count_attracted} </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="row">
          {diariesList.length > 0 ? (
            diariesList.map((item, index) => {
              console.log(item, "itemitemitemitem");
              const {
                statement_array,
                date_time,
                dynamiye_diary_coins_count,
                gratitude_info,
                note,
              } = item;

              let diary_date = moment(date_time, "YYYY-MM-DD HH:mm").format(
                "DD-MM-YYYY hh:mm A"
              );
              return (
                <div className="col-12 mt-2">
                  <Accordion expanded={item.isExpanded}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => expandArea(index)}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="svg-color"
                    >
                      <div className="d-flex justify-content-between w-100">
                        <Typography>
                          <i className="fa-regular fa-calendar-days"></i>{" "}
                          {diary_date}
                        </Typography>
                        <Typography className="diary-coins-count">
                          Coins Attracted: {dynamiye_diary_coins_count}
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <div className="px-3 pb-2">
                      <div className="row past-diaries-list position-relative">
                        <div className="col-md-6 mb-3">
                          <h4>Dynamite Intentions</h4>
                          {statement_array.map((statement) => {
                            return (
                              <div className="d-flex align-items-center">
                                <div className="statement-dots"></div>
                                <div className="intention-statement">
                                  {statement.option}{" "}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="d-flex justify-content-between">
                            <h4>Gratitude Intentions</h4>
                            <div className="dynamite-diary-edit-options">
                              <CustomPopover menu={MENU_OPTIONS} data={item} />
                            </div>
                          </div>
                          {gratitude_info.statement_array.map((statement) => {
                            return (
                              <div className="d-flex align-items-center">
                                <div className="statement-dots"></div>
                                <div>{statement.option} </div>
                              </div>
                            );
                          })}
                        </div>
                        {note && (
                          <div className="col-12">
                            <h4>Journal Note</h4>
                            <p>{htmlDecode(note)}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Accordion>
                </div>
              );
            })
          ) : (
            <div className="mt-5">
              <RecordNotFound title="History does not exists" />
            </div>
          )}
          {totlePages >= pageNumber ? (
            <div className="col-12 text-center">
              <button
                className="small-contained-button mt-3"
                onClick={loadMoreData}
                disabled={isLoadingMore}
              >
                {isLoadingMore ? "Loading..." : "Load More"}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this diary?"}
        handleAgree={handleDeleteDiary}
      />
    </>
  );
}
