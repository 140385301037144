import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactVideoPlayer } from "src/components";
import ActionBarComponentProps from "./calender";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  addDynamiteDiaryApi,
  get_dynamite_diary_by_date_api,
} from "src/DAL/dynamiteDiary/dynamiteDiary";
import moment_tz from "moment-timezone";
import moment from "moment/moment";

import PageDescription from "src/components/GeneralComponents/PageDescription";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import IntentionStatements from "./IntentionStatements";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { cointlogo } from "src/assets";
import DateComponent from "./DateComponent";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const defaultIntentions = () => [
  { option: "", complete: false, is_moved_to_tomorrow: false },
  { option: "", complete: false, is_moved_to_tomorrow: false },
  { option: "", complete: false, is_moved_to_tomorrow: false },
];

export default function DailyDynamiteAndGratitude() {
  const { userInfo } = useContentSetting();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const params_date = new URLSearchParams(location.search).get("diary_date");
  const today_date = moment_tz().tz(userInfo.time_zone).format("YYYY-MM-DD");
  let diary_date = params_date || today_date;

  const [isLoading, setIsLoading] = useState(true);
  const [openHide, setOpenHide] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [settingData, setSettingData] = useState({});
  const [dailyMessage, setDailyMessage] = useState({});
  const [note, setNote] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isToday, setIsToday] = useState(false);
  const [firstDiayDate, setFirstDiayDate] = useState(today_date);
  const [hideVideo, setHideVideo] = useState(
    localStorage.getItem("hide_dynamite_diary_video")
  );

  const [diaryIntentions, setDiaryIntentions] = useState(defaultIntentions());
  const [gratitudeIntentions, setGratitudeIntentions] = useState(
    defaultIntentions()
  );

  const handleNavigateToDate = (date) => {
    navigate(`/dynamite-diary?diary_date=${date}`);
  };

  const handleReset = () => {
    setDiaryIntentions(defaultIntentions());
    setGratitudeIntentions(defaultIntentions());
    setNote("");
  };

  const handleChangeDate = (value) => {
    setIsLoading(true);
    let todayDate = moment(value.$d).format("YYYY-MM-DD");
    handleNavigateToDate(todayDate);
  };

  const handleAgreeHide = (value) => {
    setOpenHide(true);
  };

  const handleHideVideo = () => {
    localStorage.setItem("hide_dynamite_diary_video", true);
    setHideVideo(true);
    setOpenHide(false);
  };

  const handleShowVideo = () => {
    localStorage.setItem("hide_dynamite_diary_video", false);
    setHideVideo(false);
  };

  const get_dynamite_diary_info = async (value) => {
    let todayDate = moment(diary_date, "YYYY-MM-DD").format("DD/MM/YYYY");
    let postData = { type: "date", date: todayDate };
    const result = await get_dynamite_diary_by_date_api(postData);
    if (result.code == 200) {
      let setting_data = {
        ...result.dynamite_diary_settings,
        total_coins_count_attracted: result.total_coins_count_attracted,
      };
      setSettingData(setting_data);
      setFirstDiayDate(result.first_dynamite_diary_date);
      setDailyMessage(result.daily_message);
      if (Object.keys(result.dynamite_diary).length > 0) {
        if (location.state && location?.state?.pastactivity) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
        setIsToday(true);
      }
      if (result.dynamite_diary?.date) {
        const { statement_array, gratitude_info, note } = result.dynamite_diary;
        setNote(note);
        if (statement_array?.length > 0) {
          setDiaryIntentions(statement_array);
        }
        if (gratitude_info.statement_array?.length > 0) {
          setGratitudeIntentions(gratitude_info.statement_array);
        }
      } else {
        handleReset();
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (disabled) return;
    setIsUpdating(true);

    let postData = {
      title: "",
      date: moment(diary_date, "YYYY-MM-DD").format("DD-MM-YYYY"),
      note: note,
      statement_array: diaryIntentions,
      gratitude_info: {
        statement_array: gratitudeIntentions,
        note: "",
      },
    };
    const result = await addDynamiteDiaryApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsUpdating(false);
      setIsToday(true);
      setDisabled(true);
      getDiaryAccordingDate("load");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsUpdating(false);
    }
  };

  const handleNavigate = (e, index) => {
    navigate(`/dynamite-diary/past-activities`);
  };
  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  console.log(isToday, isUpdating, "isToday");
  useEffect(() => {
    get_dynamite_diary_info();
  }, [params_date]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container dynamite-diary-page view-point-large">
        <form className="row" onSubmit={handleSubmit}>
          <div className={`col-lg-7 mb-2`}>
            <PageDescription
              parameter="dynamite_diary_description"
              else_title="Dynamite Diary"
            />
          </div>
          <div className="col-lg-5 mt-3 mt-lg-2 mb-3 mb-lg-0 d-flex justify-content-end">
            {/* <div className={`d-flex coint-image text-start align-items-center`}>
              <img className="img-fluid me-1" src={cointlogo}></img>
              <h4 className="mb-0">
                Total Coins Attracted: {settingData.total_coins_count_attracted}
              </h4>
            </div> */}
            {disabled && (
              <span
                onClick={() => setDisabled(false)}
                className="edit-icon cursor-pointer"
              >
                <Tooltip title="Update" placement="top">
                  <BorderColorIcon />
                </Tooltip>
              </span>
            )}
          </div>
          {/* <div className="col-lg-3 ">
            {(hideVideo == true || hideVideo == "true") && (
              <div className="col-lg-12 text-end mt-3">
                <button
                  className="small-contained-button float-end mt-1"
                  onClick={handleShowVideo}
                >
                  Show Video
                </button>
              </div>
            )}
          </div> */}
          <div className="col-lg-7">
            <ReactVideoPlayer url={settingData?.diary_video_url} />
            {/* <FormControlLabel
              control={<Checkbox onClick={handleAgreeHide} checked={false} />}
              label={
                settingData?.diary_dont_show_text ||
                "Don't Show me this video again"
              }
            /> */}
          </div>
          {dailyMessage?.media_url && (
            <div className="col-12 mt-4 d-block d-lg-none">
              <audio
                src={s3baseUrl + dailyMessage?.media_url}
                className="w-100"
                controls
              />
            </div>
          )}
          <div className="col-lg-5">
            <div className="d-flex calender w-100 full-open-calender">
              <ActionBarComponentProps
                diaryDate={diary_date}
                handleChangeDate={handleChangeDate}
                userCreateAt={firstDiayDate}
                containerProps={{ className: "mission-detail w-100" }}
              />
            </div>
          </div>
          {dailyMessage?.media_url && (
            <div className="col-12 my-4 d-none d-lg-block">
              <audio
                src={s3baseUrl + dailyMessage?.media_url}
                className="w-100"
                controls
              />
            </div>
          )}
          <IntentionStatements
            intentions={diaryIntentions}
            title={settingData.diary_title}
            setIntentions={setDiaryIntentions}
            disabled={disabled}
            intentionType="daily_dyanmite"
          />
          <IntentionStatements
            intentions={gratitudeIntentions}
            title={settingData.gratitude_title}
            setIntentions={setGratitudeIntentions}
            disabled={disabled}
            intentionType="gratitude_daily"
          />
          <div className="col-12">
            <div
              dangerouslySetInnerHTML={{
                __html: settingData?.diary_journal_text,
              }}
              className="diary-para"
            ></div>
          </div>
          <div className="col-12">
            <div className="mt-2">
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder={settingData?.notes_placeholder || ""}
                className="text-color textarea-autosize-general"
                value={note}
                disabled={disabled}
                sx={{ color: "white" }}
                minRows={7}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 mt-2 text-end">
            {firstDiayDate && (
              <button
                className="small-contained-button"
                onClick={handleNavigate}
              >
                Past Activities
              </button>
            )}
            <button className="small-contained-button ms-2">
              {isUpdating
                ? "Saving..."
                : isToday
                ? disabled
                  ? "Completed"
                  : "Update"
                : "Save"}
            </button>
          </div>
        </form>
      </div>
      <div className="container dynamite-diary-page view-point-small">
        <form className="row" onSubmit={handleSubmit}>
          <div className={`col-lg-7`}>
            <PageDescription
              parameter="dynamite_diary_description"
              else_title="Dynamite Diary"
            />
          </div>

          <div className="col-lg-5 mt-1 mt-lg-2 mb-3 mb-lg-0">
            {/* <div className={`d-flex coint-image text-start align-items-center`}>
              <img className="img-fluid me-1" src={cointlogo}></img>
              <h4 className="mb-0">
                Total Coins Attracted: {settingData.total_coins_count_attracted}
              </h4>
            </div> */}
          </div>
          <div className="col-12 mt-3 mb-3">
            <div>
              <DateComponent
                date={diary_date}
                handleChangeDate={handleChangeDate}
                userCreateAt={firstDiayDate}
              />
            </div>
          </div>

          <div className="col-lg-12">
            <ReactVideoPlayer url={settingData?.diary_video_url} />
          </div>

          <IntentionStatements
            intentions={gratitudeIntentions}
            title={settingData.gratitude_title}
            setIntentions={setGratitudeIntentions}
            intentionType="gratitude_daily"
            className="col-lg-12 mb-4 mt-4"
          />
          {dailyMessage?.media_url && (
            <div className="col-12 my-4 d-none d-lg-block">
              <audio
                src={s3baseUrl + dailyMessage?.media_url}
                className="w-100"
                controls
              />
            </div>
          )}
          {dailyMessage?.media_url && (
            <div className="col-12 d-block d-lg-none">
              <audio
                src={s3baseUrl + dailyMessage?.media_url}
                className="w-100"
                controls
              />
            </div>
          )}
          <IntentionStatements
            intentions={diaryIntentions}
            title={settingData.diary_title}
            intentionType="daily_dyanmite"
            setIntentions={setDiaryIntentions}
            className="col-lg-12 mb-4 mt-3"
          />

          <div className="col-12">
            <div
              dangerouslySetInnerHTML={{
                __html: settingData?.diary_journal_text,
              }}
              className="diary-para"
            ></div>
          </div>
          <div className="col-12">
            <div className="mt-2">
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder={settingData?.notes_placeholder || ""}
                className="text-color textarea-autosize-general"
                value={note}
                sx={{ color: "white" }}
                minRows={7}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 mt-2 text-end">
            {firstDiayDate && (
              <button
                className="small-contained-button"
                onClick={handleNavigate}
              >
                Past Activities
              </button>
            )}
            <button className="small-contained-button ms-2">
              {isUpdating ? "Saving..." : isToday ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </div>
      <CustomConfirmation
        open={openHide}
        setOpen={setOpenHide}
        title={"Are you sure you want to hide video ?"}
        handleAgree={handleHideVideo}
      />
    </>
  );
}
