import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";

const MissionVideoPlayer = (props) => {
  const playerRef = useRef(null);
  const { isDisabled, playedContent } = props;
  const [hasCalledPlayedContent, setHasCalledPlayedContent] = useState(false);

  const stopVideo = () => {
    if (isDisabled && playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

  const handleProgress = ({ played }) => {
    // Call playedContent() when half the video is played
    if (played >= 0.5 && !hasCalledPlayedContent) {
      playedContent();
      setHasCalledPlayedContent(true); // Ensure playedContent() is only called once
    }
  };

  return (
    <div className="wrapper">
      <ReactPlayer
        ref={playerRef}
        controls={!isDisabled}
        onContextMenu={(e) => e.preventDefault()}
        className="player"
        url={props.url}
        width="100%"
        height="100%"
        stopOnUnmount
        pip
        playsinline
        onPlay={stopVideo}
        onStart={stopVideo}
        onProgress={handleProgress} // Monitor video progress
      />
    </div>
  );
};

export default MissionVideoPlayer;
