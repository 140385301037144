import { Avatar } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { videoShowLock } from "src/assets";
import { s3baseUrl } from "src/config/config";
import { dd_date_format, get_short_string } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SkeletonColor from "src/pages/MissionLevels/Skulton";

export default function CommunityStoriesTab({ missionCommunity }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { mission__id } = useParams();
  const sliderRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const adjustHeight = () => {
      const videoDetailImageBox = document.getElementById(
        "video_detail_image_box"
      );
      const scheduleHeading = document.getElementById("schedule-heading-box");

      if (videoDetailImageBox && screen.width > 767) {
        const height = videoDetailImageBox.offsetHeight;
        const element = ref.current;
        if (height > 0 && element) {
          element.style.height = `${height - scheduleHeading.offsetHeight}px`;
          element.style.overflow = "auto";
        }
      }

      if (state?.schedule_id) {
        const element = document.getElementById(state?.schedule_id);
        if (element) {
          const container = element.closest(".mission-levels-schedule-tab");
          const elementPosition = element.offsetTop;
          const containerPosition = container.offsetTop;
          container.scrollTo({
            top: elementPosition - containerPosition,
            behavior: "smooth",
          });
        }
      }
    };

    const timeoutId = setTimeout(adjustHeight, 300);
    return () => clearTimeout(timeoutId);
  }, [ref, missionCommunity]);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const HandleNavigate = (item) => {
    navigate(
      `/mission-levels/level-details/${mission__id}/feed-detail/${item._id}`
    );
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  function getRandomRGBColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    const color1 = `rgb(${red}, ${green}, ${blue}, 0)`;
    const color2 = `rgb(${red}, ${green}, ${blue}, 0.5)`;
    return `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`;
  }

  useEffect(() => {
    if (sliderRef.current) {
      if (missionCommunity.length >= 4) {
        setIsScrollable(true);
      }
    }
  }, [missionCommunity]);

  return (
    <>
      <div
        className="mission-levels-schedule-tab"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="container position-relative"
          style={{
            display: "flex",
            justifyContent: isScrollable ? "start" : "start",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {isScrollable && (
            <button
              className="carousel-control-prev"
              type="button"
              onClick={scrollLeft}
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: "9999",
                display: "contents", // Show button when scrollable
              }}
            >
              <div
                className="slider-icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffd889",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              >
                <ChevronLeftIcon style={{ color: "black" }} />
              </div>
              <span className="visually-hidden">Previous</span>
            </button>
          )}
          {missionCommunity.length > 0 && (
            <>
              <div
                className={`scrollable-slider h-100 d-flex ${
                  isScrollable ? "overflow-auto" : ""
                } ${!isScrollable ? "justify-content-center" : ""}`}
                ref={sliderRef}
              >
                {missionCommunity && missionCommunity.length > 0 ? (
                  missionCommunity.map((schedule) => (
                    <div
                      className="pe-1 ps-1 mb-2"
                      style={{ width: "180px", flex: "none" }}
                      key={schedule._id}
                    >
                      <div
                        className="position-relative schadule-card"
                        style={{ height: "230px", borderRadius: "10px" }}
                      >
                        <div
                          className={`h-100 schedule-click p-0 mb-2 ${
                            schedule._id === state?.schedule_id
                              ? "in-progress-schedule"
                              : ""
                          }`}
                          key={schedule.title}
                          onClick={() => HandleNavigate(schedule)}
                          style={{
                            position: "relative",
                            background: `${
                              schedule?.feed_images.length == 0
                                ? getRandomRGBColor()
                                : `linear-gradient(
                rgba(0, 0, 0, 0.3), 
                rgba(0, 0, 0, 0.3)
              ), url(${s3baseUrl + schedule?.feed_images[0]?.thumbnail_2})`
                            }`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "20px",
                              left: "10px",
                            }}
                          >
                            <Avatar
                              alt={schedule?.action_info?.name}
                              src={
                                s3baseUrl + schedule?.action_info?.profile_image
                              }
                              sx={{
                                width: 35,
                                height: 35,
                                border: "2px solid rgb(255, 216, 137)",
                              }}
                            />
                          </div>

                          {schedule?.feed_images.length <= 0 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "60px",
                                left: "0px",
                                paddingRight: "13px",
                                paddingLeft: "13px",
                                width: "100%",
                                textAlign: "left",
                                color: "#a0a0a0",
                                wordWrap: "break-word",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: get_short_string(
                                  schedule.description,
                                  40
                                ),
                              }}
                            ></div>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              bottom: "20px",
                              left: "10px",
                              right: "10px",
                              zIndex: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: "white",
                            }}
                          >
                            <h6
                              className="schedule-title"
                              style={{
                                width: "90%",
                                color: "white",
                                fontSize: "14px",
                              }}
                            >
                              {schedule?.action_info?.name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <SkeletonColor />
                )}
              </div>
            </>
          )}
          {isScrollable && (
            <button
              className="carousel-control-next"
              type="button"
              onClick={scrollRight}
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: "9999",
                display: "contents", // Show button when scrollable
              }}
            >
              <div
                className="slider-icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffd889",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              >
                <ChevronRightIcon style={{ color: "black" }} />
              </div>
              <span className="visually-hidden">Next</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
