import { TextareaAutosize, Tooltip } from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { labels } from "src/utils/constants";

export default function IntentionStatements(props) {
  const {
    intentions,
    title,
    intentionType,
    disabled,
    setIntentions,
    className,
  } = props;

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let list = [...intentions];
    list[index][name] = value;
    setIntentions(list);
  };

  const handleDelete = (i) => {
    const delValue = [...intentions];
    delValue.splice(i, 1);
    setIntentions(delValue);
  };

  const handleAdd = () => {
    let new_item = { option: "", complete: false, is_moved_to_tomorrow: false };
    const addedElement = [...intentions, new_item];
    setIntentions(addedElement);
  };

  return (
    <div className={`${className ? className : "col-lg-6 mb-4  "}`}>
      <div className="allowed-actions-box">
        <h2 className="" style={{ marginBottom: 14, fontSize: 20 }}>
          {title}
        </h2>
        <div className="action-main-container">
          {intentions?.map((data, index) => {
            const lable =
              intentionType === "gratitude_daily"
                ? labels.gratitudeLabels[index]
                : labels.dailyDynamiteLabels[index];
            return (
              <div className="d-flex position-relative">
                <div className="mt-2 field-container">
                  <span className="ms-2  mb-1 custom-lable">{lable}</span>
                  <span className="required-asterisk ms-1">{"*"}</span>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder={`${index + 1}.`}
                    className="text-color textarea-autosize"
                    required
                    disabled={disabled}
                    value={data.option}
                    minRows={1}
                    name="option"
                    onChange={(e) => handleChange(e, index)}
                    style={{
                      width: "100%",
                      borderRadius: "11px",
                      background: "rgb(35 35 35)",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "15px",
                      color: "white",
                      fontSize: "15px",
                      border: "2px solid rgba(71, 66, 34, 0.3)",
                    }}
                  />
                </div>

                {/* <span className="cross-icon cross-daily">
              {intentions.length > 1 ? (
                <Tooltip title="Remove">
                  <RemoveCircleOutlineIcon
                    onClick={() => handleDelete(index)}
                    className="diary-icon-remove"
                  />
                </Tooltip>
              ) : (
                ""
              )}
              <Tooltip title="Add">
                <AddCircleOutlineIcon
                  onClick={() => handleAdd()}
                  className="diary-icon-add"
                />
              </Tooltip>
            </span> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
