import { Avatar, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import SendIcon from "@mui/icons-material/Send";
import { s3baseUrl } from "src/config/config";
import { community_feed_action } from "src/DAL/Community/Community";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  count_chars,
  decryptToken,
  get_date_with_user_time_zone,
  is_small_screen,
  LONG_TEXT_LIMIT,
  post_description,
  replaceUserNamesWithHTML,
  splitName,
  string_avatar,
} from "src/utils/constants";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import {
  add_feed_comment,
  delete_comment_api,
  edit_feed_comment,
  get_feeds_commnets,
} from "src/DAL/Community/Comments";
import { communityHeart } from "src/assets";
import ShowCommentLikes from "../LikesPopup/ShowCommentLikes";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import EmojiPicker from "src/components/GeneralComponents/EmojiPicker";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CircularProgress from "@mui/material/CircularProgress";
import MentionEditor from "../MentionEditor";

function SingleComment(props) {
  const {
    comment,
    handleCommentEdit,
    handleCommentDelete,
    childComponent,
    handleUpdateSpecificFeed,
    is_popup,
    is_detail_page,
    is_stream_page,
    feed,
    event_id,
    feedSetting,
    parent_comment,
    setComments,
    index,
  } = props;
  const settings = useContentSetting();
  const { handleAddChat } = useChat();
  const inputRef = useRef(null);
  const { socket, userInfo } = settings;
  const mentionEditorRef = useRef(null);
  const [updateValue, setUpdateValue] = useState({});
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [isShowCommentMore, setIsShowCommentMore] = useState(true);
  const [inputReply, setInputReply] = useState("");
  const [isLikedComment, setIsLikedComment] = useState(false);
  const [isSubmittedChild, setIsSubmittedChild] = useState(false);
  const [isShowReplyInput, setIsShowReplyInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedComment, setSelectedComment] = useState({});
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [formAction, setFormAction] = useState("ADD");
  const [openDeleteComment, setOpenDeleteComment] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [onClickLoading, setOnCLickLoading] = useState(false);
  const [previews, setPreviews] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [image, setImage] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [loadMore, setLoadMore] = useState(
    "api/feeds/list_comment_with_pagination/v1?page=0&limit=10"
  );
  // default
  let api_path = "api/feeds/list_comment_with_pagination/v1?page=0&limit=10";

  const handleRemove = () => {
    setPreviews("");
    setOldImage("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleReplyEdit = (selected_comment) => {
    setSelectedComment(selected_comment);
    setInputReply(selected_comment.message);
    if (selected_comment.image?.thumbnail_2) {
      setOldImage(selected_comment.image?.thumbnail_2);
    }
    setMentionedUsers(selected_comment.mentioned_users);
    setUpdateValue(selected_comment);
    setFormAction("EDIT");
    setIsShowReplyInput(true);
  };

  const handleChildCommentDelete = (selected_comment) => {
    setOpenDeleteComment(true);
    setSelectedComment(selected_comment);
  };

  const handleSendMessage = async (row) => {
    handleAddChat(row.user_info_action_for?.action_id);
  };

  //Deleting Category
  const handleDelete = async () => {
    setOpenDeleteComment(false);
    const result = await delete_comment_api(selectedComment._id);
    if (result.code === 200) {
      setIsSubmittedChild(!isSubmittedChild);
      const socketData = {
        action: "delete_comment_reply",
        feed_id: selectedComment.feeds,
        comment: selectedComment._id,
        token: decryptToken(localStorage.getItem("token")),
        creator_id: result.action_response.creator_id,
        action_response: result.action_response,
      };
      socket.emit("feed_room_action_event", socketData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [];

  if (
    comment?.comment_is_self == true ||
    settings.userInfo.is_super_executive
  ) {
    MENU_OPTIONS.push(
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: childComponent ? handleReplyEdit : handleCommentEdit,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: childComponent
          ? handleChildCommentDelete
          : handleCommentDelete,
      }
    );
  }
  if (
    (userInfo.is_super_executive || userInfo.is_executive) &&
    userInfo.is_chat_allow &&
    !is_popup &&
    comment?.user_info_action_for?.action_id !== userInfo._id
  ) {
    MENU_OPTIONS.push({
      label: "Message",
      icon: "material-symbols:send",
      handleClick: handleSendMessage,
    });
  }

  const handleShowReplyInput = (comment, is_child) => {
    setIsShowReplyInput(!isShowReplyInput);
    if (is_child) {
      let name = comment.user_info_action_for?.name;
      const _id = comment.user_info_action_for?.action_id;
      if (_id !== userInfo._id && name) {
        const { first_name, last_name } = splitName(name);

        const offset = 0;
        const length = name?.length;
        const community_level = feed.badge_level_info?._id;

        setInputReply(`${name}\u00A0`); //just adding a space after name
        let mentioned_user = {
          first_name,
          last_name,
          community_level,
          offset,
          length,
          _id,
        };
        setMentionedUsers([mentioned_user]);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("feed", comment.feeds);
    formData.append("parent_comment", parent_comment._id);
    formData.append("message", inputReply);
    formData.append("mentioned_users", JSON.stringify(mentionedUsers));

    if (image) {
      formData.append("image", image);
    }
    const result = await add_feed_comment(formData);

    if (result.code === 200) {
      const socketData = {
        action: "add_comment_reply",
        feed_id: comment.feeds,
        parent_comment: comment._id,
        token: decryptToken(localStorage.getItem("token")),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };
      socket.emit("feed_room_action_event", socketData);
      setIsSubmittedChild(!isSubmittedChild);
      setInputReply("");
      handleRemove();
      setIsShowReplyInput(false);
      setShowEmojiPicker(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleMoreChild = async (commentId, index, type) => {
    const formData = new FormData();
    formData.append("feed_id", feed._id);
    formData.append("parent_id", commentId);

    if (type === "next") {
      api_path = loadMore;
      setIsLoadingMore(true);
    } else {
      if (commentId === parent_comment._id) {
        setOnCLickLoading(true);
      }
    }

    const result = await get_feeds_commnets(api_path, formData);
    if (result.code === 200) {
      let child = result.comment;
      setComments((old) =>
        old.map((item) => {
          if (item._id === parent_comment._id) {
            item.isLoaded = true;
            item.child_comment =
              type === "next" ? [...child, ...item.child_comment] : child;
          }
          return item;
        })
      );
      setPageNumber(pageNumber + 1);
      setTotalPages(result.total_pages);
      setLoadMore(result.load_more);
      setOnCLickLoading(false);
      // localStorage.setItem("loading", "comments");
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });

      setIsLoadingMore(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("message", inputReply);
    formData.append("mentioned_users", JSON.stringify(mentionedUsers));

    if (image) {
      formData.append("image", image);
    } else if (selectedComment.image?.thumbnail_2 && !oldImage) {
      formData.append("is_image_deleted", true);
    }

    const result = await edit_feed_comment(formData, selectedComment._id);
    if (result.code === 200) {
      const socketData = {
        action: "edit_comment_reply",
        feed_id: comment.feeds,
        comment: selectedComment._id,
        parent_comment: result.action_response.comment.parent_comment,
        token: decryptToken(localStorage.getItem("token")),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };
      socket.emit("feed_room_action_event", socketData);
      setIsSubmittedChild(!isSubmittedChild);
      setInputReply("");
      handleRemove();
      setIsShowReplyInput(false);
      setFormAction("ADD");
      setShowEmojiPicker(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleFeedAction = async (comment_id, feed_id, action) => {
    if (action === "commentlike") {
      setIsLikedComment(true);
    } else {
      setIsLikedComment(false);
    }
    const formData = new FormData();
    formData.append("action", action);
    formData.append("feed", feed_id);
    formData.append("comment", comment_id);
    const result = await community_feed_action(formData);
    if (result.code === 200) {
      setIsLikedComment(result.action_response.is_liked);
      const socketData = {
        action,
        feed_id,
        token: decryptToken(localStorage.getItem("token")),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        comment: comment_id,
        action_response: result.action_response,
        child_comment: childComponent,
      };

      socket.emit("feed_room_action_event", socketData);
    } else {
      if (action === "commentlike") {
        setIsLikedComment(false);
      } else {
        setIsLikedComment(true);
      }
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    setIsLikedComment(comment?.is_liked);
  }, [comment, isSubmittedChild]);

  return (
    <>
      {comment && (
        <div
          className={`${
            !childComponent
              ? "mt-2 position-relative profile-comments"
              : "position-relative profile-comments"
          }`}
        >
          <div className="">
            <div className="col-12">
              <div className="poster-name-image d-flex w-100 single-comment-card">
                <Avatar
                  src={s3baseUrl + comment?.user_info_action_for?.profile_image}
                  alt="photoURL"
                >
                  {string_avatar(comment?.user_info_action_for?.name)}
                </Avatar>
                <div className="w-100">
                  <div className="text-start set-title-width poster-name ps-2 w-100">
                    <span className="card-title mb-0">
                      {htmlDecode(comment?.user_info_action_for?.name)}
                    </span>

                    {!is_small_screen() &&
                      !is_detail_page &&
                      !is_stream_page && (
                        <span className="date-color float-end  date-space">
                          {get_date_with_user_time_zone(
                            comment?.comment_date_time,
                            "DD MMM YYYY [at] hh:mm A",
                            settings.userInfo.time_zone,
                            settings.adminTimeZone
                          )}
                        </span>
                      )}
                  </div>
                  {(is_small_screen() || is_detail_page || is_stream_page) && (
                    <span className="date-color ps-2">
                      {get_date_with_user_time_zone(
                        comment?.comment_date_time,
                        "DD MMM YYYY [at] hh:mm A",
                        settings.userInfo.time_zone,
                        settings.adminTimeZone
                      )}
                    </span>
                  )}
                  <div className="post-description mt-1 ps-2">
                    {comment?.message && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: urlify(
                            count_chars(comment?.message) > LONG_TEXT_LIMIT &&
                              isShowCommentMore
                              ? post_description(
                                  replaceUserNamesWithHTML(
                                    comment?.message,
                                    comment?.mentioned_users
                                  ),
                                  LONG_TEXT_LIMIT
                                )
                              : replaceUserNamesWithHTML(
                                  comment?.message,
                                  comment?.mentioned_users
                                )
                          ),
                        }}
                      ></p>
                    )}
                    {count_chars(comment?.message) > LONG_TEXT_LIMIT && (
                      <span
                        className="ms-2"
                        onClick={() => {
                          setIsShowCommentMore(!isShowCommentMore);
                        }}
                      >
                        {isShowCommentMore ? "See More" : "See Less"}
                      </span>
                    )}
                    {comment.image?.thumbnail_2 && (
                      <div className="mt-2">
                        <a
                          href={s3baseUrl + comment.image.thumbnail_1}
                          className="preview mt-2"
                          target="_blank"
                        >
                          <img
                            src={s3baseUrl + comment.image.thumbnail_2}
                            alt=""
                          />
                        </a>
                      </div>
                    )}
                    <div className="like-comment text-start date-color mt-1 d-flex justify-content-between likes-post">
                      <div className="like-reply-box">
                        <span
                          className={`${isLikedComment ? "liked-comment" : ""}`}
                          onClick={() => {
                            handleFeedAction(
                              comment?._id,
                              comment?.feeds,
                              isLikedComment === true
                                ? "commentunlike"
                                : "commentlike"
                            );
                          }}
                        >
                          {isLikedComment === true ? "Liked" : "Like"}
                        </span>
                        <span
                          onClick={() =>
                            handleShowReplyInput(comment, childComponent)
                          }
                        >
                          Reply
                        </span>
                      </div>
                      {comment.like_count > 0 && (
                        <Tooltip title={`${comment.like_count} Likes`}>
                          <div
                            className="d-flex comment-like-heart"
                            onClick={() => {
                              setOpenLikesModal(true);
                            }}
                          >
                            <div className="comment-like-heart-image">
                              <img src={communityHeart} alt="" />
                            </div>
                            {comment.like_count > 0 && (
                              <div className="comment-like-heart-count">
                                {comment.like_count}
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      )}
                      {!childComponent && comment.child_comments_count > 0 && (
                        <div className="text-end  replies-button">
                          {onClickLoading ? (
                            <span>Please wait...</span>
                          ) : comment.isLoaded ||
                            comment.child_comments_count ==
                              comment?.child_comment.length ? (
                            ""
                          ) : (
                            <span
                              onClick={() =>
                                handleMoreChild(comment._id, index, "current")
                              }
                            >
                              View {comment.child_comments_count} Replies
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {comment?.child_comment && (
              <>
                {comment?.child_comment.map((child_comment, index) => {
                  return (
                    <div key={index}>
                      <div className="col-1"></div>
                      <div
                        className={`col-11 mt-2 ms-auto ${
                          childComponent ? "reply-comment-component" : ""
                        }`}
                      >
                        <SingleComment
                          comment={child_comment}
                          handleCommentEdit={handleCommentEdit}
                          handleCommentDelete={handleCommentDelete}
                          handleUpdateSpecificFeed={handleUpdateSpecificFeed}
                          childComponent={true}
                          is_popup={is_popup}
                          key={index}
                          is_detail_page={is_detail_page}
                          is_stream_page={is_stream_page}
                          event_id={event_id}
                          feed={feed}
                          feedSetting={feedSetting}
                          parent_comment={comment}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {totalPages > pageNumber ? (
              <div className="view-comment mt-2 text-end me-3 date-color replies-button">
                {isLoadingMore ? (
                  <span className="">Loading...</span>
                ) : (
                  <span
                    onClick={() => handleMoreChild(comment._id, index, "next")}
                    id="load-more-comments"
                  >
                    View more Replies
                  </span>
                )}
              </div>
            ) : (
              ""
            )}

            {isShowReplyInput && (
              <div className="col-12">
                <div className="new-memories mt-2 w-100">
                  <div className="position-relative">
                    <MentionEditor
                      ref={mentionEditorRef}
                      setInputs={setInputReply}
                      setMentionedUsers={setMentionedUsers}
                      mentionedUsers={mentionedUsers}
                      feedLevel={feed?.badge_level_info?._id}
                      placeholder={`Write a reply`}
                      event_id={event_id}
                      editorClass="comment-editor mt-2 reply-editor"
                      addPosition={{ top: 15, left: 15 }}
                      value={inputReply}
                      updateValue={updateValue}
                    />
                    <div className="insert-reply-button">
                      {isLoading ? (
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <CircularProgress
                            style={{
                              height: "24px",
                              width: "24px",
                            }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={
                            formAction === "ADD" ? handleSubmit : handleUpdate
                          }
                          edge="end"
                        >
                          <SendIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="mt-3 mb-2 picker-empty-div"></div>
                    {previews || oldImage ? (
                      <span className="preview-comment-image mt-2 ms-2">
                        <span onClick={handleRemove}>x</span>
                        {previews ? (
                          <img src={previews} />
                        ) : (
                          <img src={s3baseUrl + oldImage} />
                        )}
                      </span>
                    ) : (
                      <>
                        <input
                          color="primary"
                          accept="image/*"
                          type="file"
                          id="message-chat-upload-button"
                          style={{ display: "none" }}
                          name=""
                          onChange={handleUpload}
                        />
                        <label>
                          <Tooltip title="Add Image" placement="top">
                            <IconButton
                              onClick={() => {
                                document.getElementById(
                                  "message-chat-upload-button"
                                ) &&
                                  document
                                    .getElementById(
                                      "message-chat-upload-button"
                                    )
                                    .click();
                              }}
                              className="upload-image-icon-comment mb-2"
                            >
                              <AddPhotoAlternateIcon />
                            </IconButton>
                          </Tooltip>
                        </label>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

            {showEmojiPicker && (
              <div className="col-12 mt-4 mt-lg-0 ms-auto">
                <EmojiPicker inputRef={inputRef} setInput={setInputReply} />
              </div>
            )}
          </div>
          {(comment?.comment_is_self == true ||
            settings.userInfo.is_super_executive ||
            settings.userInfo.is_executive) &&
            MENU_OPTIONS.length > 0 && (
              <div className="affirmation-dots single-comment-card-dots">
                <CustomPopover menu={MENU_OPTIONS} data={comment} />
              </div>
            )}
        </div>
      )}
      {openLikesModal === true && (
        <ShowCommentLikes
          openLikesModal={openLikesModal}
          setOpenLikesModal={setOpenLikesModal}
          comment_id={comment._id}
        />
      )}
      <CustomConfirmation
        open={openDeleteComment}
        setOpen={setOpenDeleteComment}
        title={"Are you sure you want to delete this comment?"}
        handleAgree={handleDelete}
      />
    </>
  );
}

export default SingleComment;
