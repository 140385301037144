import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import GeneralQuestionsList from "src/pages/GeneralQuestions/GeneralQuestionsList";
import { vectorTickIcon, videoShowLock } from "src/assets";
import CongratulationMessage from "src/components/MissionLevels/CongratulationMessage";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import DayVideoTab from "src/components/MissionLevels/DayVideoTab";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import {
  complete_actions_api,
  complete_mission_schedule_api,
  complete_mission_schedule_api_v1,
  mission_level_detail_api,
  onscreen_questions_api,
  played_content_api,
  start_mission_api,
  unlock_mission_schedule_api,
  update_mission_schedule_data_api,
} from "src/DAL/MissionLevels/MissionLevels";
import UnlockVideo from "src/components/MissionLevels/UnlockVideo";
import {
  dd_date_format,
  dd_date_format_UPDATE,
  number_with_comma,
  two_date_difference,
} from "src/utils/constants";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { htmlDecode } from "src/utils/convertHtml";
import StartMission from "src/components/MissionLevels/StartMission";
import CongratulationAnimation from "src/components/challenges/CongratulationAnimation";
import CreatePostMission from "./CreatePostMission";
import moment from "moment";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import MissionDynamiteDiary from "../DynamiteDiary/MissionDynamiteDiary";
import MissionComplete from "./MissionComplete";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const statement_array = [
  {
    complete: true,
    is_moved_to_tomorrow: false,
  },
  {
    complete: true,
    is_moved_to_tomorrow: false,
  },
  {
    complete: true,
    is_moved_to_tomorrow: false,
  },
];
export default function ScheduleDetails() {
  const { state } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openMissionConfirm, setOpenMissionConfirm] = useState(false);
  const [missionDetail, setMissionDetail] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const { created_for_id, schadule_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabData, setTabData] = useState(0);
  const [missionResult, setMissionResult] = useState({});
  const [unLockCoin, setUnLockCoin] = useState(0);
  const [userCoin, setUserCoin] = useState(0);
  const [missionContentSettings, setMissionContentSettings] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [rewardData, setRewardData] = useState({});
  const [missionComoleted, setMissionCompleted] = useState(false);
  const [hideSubmitButton, setHideSubmitButton] = useState(false);
  const [ToolObject, setToolObject] = useState(null);
  const [played, setPlayed] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [showQuestionsSreen, setShowQuestionsSreen] = useState(0);
  const [isLoadingDataOnScreen, setIsLoadingDataOnScreen] = useState(false);
  const [isLoadBookACallButton, setIsLoadBookACallButton] = useState(false);
  const [missionBadges, setMissionBadges] = useState([]);
  const [isLoadMembershipUpgradeButton, setIsLoadMembershipUpgradeButton] =
    useState(false);
  const [book_a_call_button_settings, setBookACallButtonSettings] =
    useState(null);
  const [membership_upgrade_button_setting, setMembershipUpgradeButtonSetting] =
    useState(null);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setTabData(schedules[newValue]);
  };
  const handleOpenCreateModal = () => {
    setOpenModal(0);
    setOpenCreateModal(true);
  };
  const playedContent = async () => {
    const result = await played_content_api(schadule_id);
    if (result.code == 200) {
      setPlayed(true);
    }
  };

  const get_mission_detail = async () => {
    setIsLoading(true);
    const result = await mission_level_detail_api(created_for_id);
    if (result.code == 200) {
      console.log(result, "resultresultresult finall");
      setMissionResult(result);
      setMissionBadges(result.mission_badges_earned);
      setRewardData(result.mission.content_settings);
      setMissionContentSettings(result.mission.content_settings);
      setBookACallButtonSettings(result?.book_a_call_button_setting);
      setMembershipUpgradeButtonSetting(
        result?.membership_upgrade_button_setting
      );
      setMissionDetail({ ...result.mission, user_data: result.user_data });
      let mission_schedules = result.mission_schedules.map((item) => {
        item.general_allowed_actions.map((i) => {
          let find_answer = item.completed_allowed_actions.find(
            (j) => j._id == i._id
          );

          i.is_checked = Boolean(find_answer);
          return i;
        });

        item.growth_tool_allowed_actions.map((i) => {
          let find_answer = item.completed_allowed_actions.find(
            (j) => j._id == i._id
          );
          i.is_checked = Boolean(find_answer);
          if (!i.growth_tool_data) {
            i.growth_tool_data = { statement_array: [] };
          }
          i.growth_tool_data.statement_array = find_answer?.options
            ? find_answer?.options
            : statement_array;
          return i;
        });
        item.schedule_questions.map((i) => {
          let find_answer = item.schedule_question_replies.find(
            (j) => j._id == i._id
          );

          if (!i.answer_statement) {
            i.answer_statement = "";
          }
          i.answer_statement = find_answer?.answer_statement
            ? find_answer?.answer_statement
            : "";
          return i;
        });

        return item;
      });

      if (schadule_id) {
        let find_index = mission_schedules.findIndex(
          (item) => item._id == schadule_id
        );

        console.log(find_index, "find_indexfind_index");
        if (find_index >= 0) {
          setTabValue(find_index);
          setTabData(mission_schedules[find_index]);
        }
      }

      setSchedules(mission_schedules);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      handleBack();
    }
  };

  const handleUnlock = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start Schedule", {
        variant: "error",
      });
      return;
    }
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setOpenConfirm(true);
    setTimeout(() => {
      setOpenModal(0);
    }, 500);
  };

  const handleConfirmSubmit = async () => {
    setIsSubmitting(true);
    const result = await unlock_mission_schedule_api(selectedSchedule._id);
    if (result.code == 200) {
      setSchedules((old) =>
        old.map((item) => {
          if (item._id === selectedSchedule._id) {
            item.schedule_status = "in_progress";
          }
          return item;
        })
      );
      setOpenConfirm(false);
      setIsSubmitting(false);
      setOpenModal(0);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    if (showQuestionsSreen == 1) {
      setShowQuestionsSreen(0);
      localStorage.removeItem("showQuestionsSreen", 0);
      return;
    } else if (showQuestionsSreen == 2) {
      localStorage.setItem("tabValuesMission", 3);
      navigate(`/mission-levels/level-details/${created_for_id}`);
      localStorage.removeItem("showQuestionsSreen", 0);
    } else {
      navigate(-1);
      localStorage.removeItem("showQuestionsSreen", 0);
    }
  };

  const handleClick = (schedule) => {
    const today = moment();
    const startedAt = moment(schedule.started_at);
    setSelectedSchedule(schedule);
    if (schedule.schedule_status === "locked") {
      if (startedAt.isAfter(today, "day")) {
        let error = `You can't unlock it before ${dd_date_format_UPDATE(
          schedule.started_at
        )}`;
        enqueueSnackbar(error, { variant: "error" });
        return;
      }
    }
    setSelectedSchedule(schedule);
    setOpenModal(3);
  };

  const handleCheckStatement = async (tab_data) => {
    let postData = {
      allowed_actions: tab_data.allowed_actions
        .filter((item) => item.is_checked == true)
        .map((item) => ({ _id: item._id })),
    };
    await update_mission_schedule_data_api(tabData._id, postData);
  };

  const handleChangeAction = (id, isDisabled, schedule, action) => {
    if (isDisabled) return;
    // if (action.is_checked) {
    //   return;
    // }
    const ObjectToPassDown = {
      id,
      isDisabled,
      schedule,
      action,
    };
    if (action.action_type == "general") {
      complete_general_action(ObjectToPassDown);
    } else {
      complete_growth_tool_action(ObjectToPassDown);
    }
  };

  const handleActionSubmit = async (data) => {
    const result = await complete_actions_api(data);
    if (result.code == 200) {
    } else {
    }
  };

  const complete_general_action = (data) => {
    const { id, schedule } = data;
    let tab_data = { ...schedule };
    tab_data.general_allowed_actions.map((item) => {
      if (item._id === id) {
        let is_checked = Boolean(item.is_checked);
        item.is_checked = !is_checked;
      }
      return item;
    });
    setData(tab_data);
    let action = schedule.general_allowed_actions.find(
      (item) => item._id === id
    );
    const postData = {
      action: {
        _id: action._id,
        type: action.action_type,
        statement: action.statement,
      },
      mission_schedule_id: schedule._id,
    };

    handleActionSubmit(postData);
  };
  const complete_growth_tool_action = (data) => {
    const { id, schedule } = data;

    let action = schedule.growth_tool_allowed_actions.find(
      (item) => item._id === id
    );
    for (let item of action.growth_tool_data.statement_array) {
      if (!item.option) {
        enqueueSnackbar("All options must be completed", { variant: "error" });
        return;
      }
    }
    let tab_data = { ...schedule };
    tab_data.growth_tool_allowed_actions.map((item) => {
      if (item._id === id) {
        let is_checked = Boolean(item.is_checked);
        item.is_checked = !is_checked;
      }
      return item;
    });
    setData(tab_data);
    const postData = {
      action: {
        _id: action._id,
        type: action.tool,
        options: action.growth_tool_data.statement_array.map((item) => ({
          option: item.option,
        })),
      },
      mission_schedule_id: schedule._id,
    };
    handleActionSubmit(postData);
  };

  const complete_action = (data) => {};
  const handleSuccess = (result) => {
    if (tabData.question_configration.thanks_description) {
      // setOpenModal(2);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsSubmitting(true);
    const dynamite_diary_action = data.growth_tool_allowed_actions.find(
      (item) => item.tool == "dynamite_dairy"
    );
    const gratitude_action = data.growth_tool_allowed_actions.find(
      (item) => item.tool == "gratitude_daily"
    );

    let postData = {
      general_note: data.general_note,
    };
    if (dynamite_diary_action) {
      postData.dynamite_diary_action = {
        _id: dynamite_diary_action._id,
        options:
          dynamite_diary_action.growth_tool_data &&
          dynamite_diary_action.growth_tool_data.statement_array.length > 0
            ? dynamite_diary_action.growth_tool_data.statement_array.map(
                (item, index) => {
                  return {
                    option: item.option,
                  };
                }
              )
            : [],
        gratitude_options:
          gratitude_action.growth_tool_data.statement_array.map(
            (item, index) => {
              return {
                option: item.option,
              };
            }
          ),
      };
    }
    // if (gratitude_action) {
    //   postData.gratitude_action = {
    //     _id: gratitude_action._id,
    //     options:
    //       gratitude_action.growth_tool_data &&
    //       gratitude_action.growth_tool_data.statement_array.length > 0
    //         ? gratitude_action.growth_tool_data.statement_array.map(
    //             (item, index) => {
    //               return {
    //                 option: item.option,
    //               };
    //             }
    //           )
    //         : [],
    //   };
    // }
    const result = await complete_mission_schedule_api_v1(
      tabData._id,
      postData
    );

    if (result.code == 200) {
      setMissionCompleted(result.user_mission_completed);
      get_mission_detail();
      let find_schedule = result.mission_schedules.find(
        (item) => item._id === data._id
      );
      let object = {
        ...data,
        schedule_status: find_schedule.schedule_status,
        completed_at: find_schedule.completed_at,
        reward_coins: find_schedule.reward_coins,
      };
      setData(object);
      setBookACallButtonSettings(result.book_a_call_button_setting);
      setMembershipUpgradeButtonSetting(
        result.membership_upgrade_button_setting
      );

      if (result.user_mission_completed) {
        setShowQuestionsSreen(2);
        localStorage.setItem("showQuestionsSreen", 2);
      } else {
        setShowQuestionsSreen(0);
        localStorage.setItem("showQuestionsSreen", 0);
      }

      if (tabData.content_settings?.congratulation_description) {
        setOpenModal(4);
      } else {
        enqueueSnackbar(result.message, { variant: "success" });
      }
      setIsSubmitting(false);
    } else {
      setHideSubmitButton(false);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitting(false);
    }
  };
  const openQuestionsPopup = (e, schedule) => {
    e.preventDefault();

    if (schedule.video_url && !played) {
      enqueueSnackbar("Please watch video first to complete your schedule", {
        variant: "error",
      });
      return;
    } else if (schedule?.audio_url && !played) {
      enqueueSnackbar("Please listen audio first to complete your schedule", {
        variant: "error",
      });
      return;
    }
    const find = schedule.general_allowed_actions.find(
      (item) => item.is_required === true && !item.is_checked
    );

    const hasEmptyOrMissingOption = schedule.growth_tool_allowed_actions.some(
      (item) =>
        item.is_required &&
        item.growth_tool_data?.statement_array.some(
          (statement) =>
            !("option" in statement) || // Checks if `option` does not exist
            statement.option === undefined ||
            statement.option === "" ||
            statement.option === null
        )
    );

    if (hasEmptyOrMissingOption) {
      enqueueSnackbar("Please complete all the intentions with *", {
        variant: "error",
      });
      return;
    }

    if (find) {
      enqueueSnackbar("Actions with * must be checked", { variant: "error" });
      return;
    }
    if (schedule.schedule_questions.length > 0) {
      onscreen_questions_data(e, schedule);
    } else {
      if (
        schedule.selected_questions.filter(
          (question) => question.show_question !== "on_screen"
        ).length > 0
      ) {
        setShowQuestionsSreen(1);
        localStorage.setItem("showQuestionsSreen", 1);
      } else {
        handleSubmit(e);
      }
    }
  };

  const handleStartMission = async () => {
    const result = await start_mission_api(missionDetail._id);
    if (result.code == 200) {
      let mission_status = "in_progress";
      let user_data = result.userMission.user_info;
      setMissionDetail((old) => ({ ...old, mission_status, user_data }));
      setSchedules(result.userMission.mission_schedules);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setOpenMissionConfirm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setOpenMissionConfirm(false);
    }
  };

  const handleMissionStart = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start this Mission", {
        variant: "error",
      });
      return;
    }
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setOpenMissionConfirm(true);
    setTimeout(() => {
      setOpenModal(0);
    }, 100);
  };

  const handleClickStart = () => {
    if (missionDetail.mission_locked) {
      enqueueSnackbar(
        "You can't start this mission because your level does not match with the level of this mission",
        {
          variant: "error",
        }
      );
      return;
    }
    setOpenModal(5);
  };
  const handleSubmitOnScreenActions = async (postData, schedule) => {
    setIsLoadingDataOnScreen(true);
    const result = await onscreen_questions_api(postData);
    if (result.code == 200) {
      if (
        schedule.selected_questions.filter(
          (question) => question.show_question !== "on_screen"
        ).length > 0
      ) {
        setShowQuestionsSreen(1);
        localStorage.setItem("showQuestionsSreen", 1);
      } else {
        handleSubmit();
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoadingDataOnScreen(false);
  };
  const onscreen_questions_data = (e, schedule) => {
    e.preventDefault();
    const requiredQuestion = schedule.schedule_questions.find(
      (item) => item.is_required && !item.answer_statement
    );

    if (requiredQuestion) {
      enqueueSnackbar("Please answer all the questions with *", {
        variant: "error",
      });
      return;
    }

    let postData = {
      created_for: "mission",
      created_for_id: created_for_id,
      other_module_id: schedule?._id,
      question_answer_array: schedule.schedule_questions.map((item) => ({
        _id: item._id,
        answer_statement: item.answer_statement,
      })),
    };
    handleSubmitOnScreenActions(postData, schedule);
  };
  useEffect(() => {
    if (localStorage.getItem("showQuestionsSreen") == 1) {
      setShowQuestionsSreen(1);
    } else if (localStorage.getItem("showQuestionsSreen") == 2) {
      setShowQuestionsSreen(2);
    }
    get_mission_detail();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      let find_schedule = schedules.find((item) => item._id === schadule_id);
      setPlayed(find_schedule.content_watched);
      console.log(find_schedule, "find_schedulefind_schedule");
      const find_gratitude_growth_tool_object =
        find_schedule.completed_allowed_actions &&
        find_schedule.completed_allowed_actions.length > 0 &&
        find_schedule.completed_allowed_actions.find(
          (item) => item.type == "dynamite_dairy"
        );
      console.log(
        "find_gratitude_growth_tool_object",
        find_gratitude_growth_tool_object
      );
      const find_gratitude_growth_tool = find_gratitude_growth_tool_object
        ? find_gratitude_growth_tool_object.gratitude_options
        : [];
      console.log("find_gratitude_growth_tool", find_gratitude_growth_tool);
      let data = {
        ...find_schedule,
        schedule_questions:
          find_schedule.schedule_questions &&
          find_schedule.schedule_questions.length > 0
            ? find_schedule.schedule_questions.map((item) => {
                return {
                  ...item,
                  answer_statement: item.answer_statement || "",
                };
              })
            : [],
        general_allowed_actions:
          find_schedule.general_allowed_actions &&
          find_schedule.general_allowed_actions.length > 0
            ? find_schedule.general_allowed_actions.map((item) => {
                return {
                  ...item,
                };
              })
            : [],
        growth_tool_allowed_actions:
          find_schedule.growth_tool_allowed_actions &&
          find_schedule.growth_tool_allowed_actions.length > 0
            ? find_schedule.growth_tool_allowed_actions
                .map((item) => {
                  return {
                    ...item,
                    growth_tool_data:
                      item.growth_tool_data &&
                      Object.keys(item.growth_tool_data).length > 0
                        ? item.growth_tool_data
                        : { statement_array: [] },
                  };
                })
                .concat(
                  find_schedule.growth_tool_allowed_actions.map((item) => {
                    return {
                      ...item,
                      tool: "gratitude_daily",
                      growth_tool_data:
                        item.growth_tool_data &&
                        Object.keys(item.growth_tool_data).length > 0
                          ? {
                              ...item.growth_tool_data,
                              statement_array:
                                find_gratitude_growth_tool.length > 0
                                  ? find_gratitude_growth_tool
                                  : item.growth_tool_data.statement_array,
                            }
                          : { statement_array: [] },
                    };
                  })
                )
            : [],
      };

      setData(data);
    }
  }, [isLoading, schadule_id]);
  const today = moment();
  let startedAt;
  if (data) {
    startedAt = moment(data?.started_at);
  }
  if (isLoading || !data) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  console.log(missionDetail, "missionDetailmissionDetailmissionDetail");

  return (
    <>
      {
        <>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {missionDetail && (
                  <div className="d-flex justify-content-between challenge-video-detail-title align-items-center">
                    <div className="d-flex">
                      <span>
                        <IconButton
                          className="back-screen-button"
                          onClick={handleBack}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </span>
                      {showQuestionsSreen == 2 ? (
                        <h2 className="ms-2">
                          {htmlDecode(missionDetail?.title)}
                        </h2>
                      ) : (
                        <h2 className="ms-2">
                          {htmlDecode(tabData?.main_heading)}
                        </h2>
                      )}
                    </div>
                    {showQuestionsSreen !== 2 && (
                      <>
                        {data.schedule_status === "locked" && (
                          <>
                            {data.allowed_status !== "missed" && (
                              <>
                                <Tooltip
                                  title={`${
                                    data.mission_status === "not_started"
                                      ? "Start mission before unlock schedule"
                                      : startedAt.isAfter(today, "day")
                                      ? `You can't unlock it before ${dd_date_format_UPDATE(
                                          data.started_at
                                        )}`
                                      : "Click to unlock"
                                  }`}
                                >
                                  <img
                                    src={videoShowLock}
                                    onClick={() => handleClick(data)}
                                    alt=""
                                    className="thumbnail-icon me-2 unlockIconNewDetail "
                                  />
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>

              <div className="col-12">
                <>
                  {showQuestionsSreen == 0 ? (
                    <DayVideoTab
                      setOpenModal={setOpenModal}
                      schedule={data}
                      handleChangeAction={handleChangeAction}
                      isSubmitting={isSubmitting}
                      handleSubmit={handleSubmit}
                      handleUnlock={handleClick}
                      openQuestionsPopup={openQuestionsPopup}
                      hideSubmitButton={hideSubmitButton}
                      playedContent={playedContent}
                      setData={setData}
                      played={played}
                      missionContentSettings={missionContentSettings}
                      onscreen_questions_data={onscreen_questions_data}
                      isLoadingDataOnScreen={isLoadingDataOnScreen}
                      setShowQuestionsSreen={setShowQuestionsSreen}
                      missionResult={missionResult}
                    />
                  ) : showQuestionsSreen == 1 ? (
                    <GeneralQuestionsList
                      created_for="mission"
                      created_for_id={created_for_id}
                      mission_schedule_id={schadule_id}
                      hideSubmitButton={Boolean(
                        data.schedule_status === "completed" ||
                          data.allowed_status == "missed"
                      )}
                      remove_back_button={true}
                      hide_dynamite_reply={true}
                      prevent_single_answer={true}
                      handleSubmitMissionFinal={handleSubmit}
                      className="challenge-video-days-questions"
                      collapseAll={true}
                      for_mission={true}
                      submit_button_text={
                        tabData.question_configration?.submit_button_text
                      }
                      onSubmitSuccess={handleSuccess}
                    />
                  ) : (
                    <MissionComplete
                      missionDetail={missionDetail}
                      rewardData={rewardData}
                      missionResultData={missionResult}
                      missionBadges={missionBadges}
                      book_a_call_button_settings={book_a_call_button_settings}
                      membership_upgrade_button_setting={
                        membership_upgrade_button_setting
                      }
                      isLoadBookACallButton={isLoadBookACallButton}
                      isLoadMembershipUpgradeButton={
                        isLoadMembershipUpgradeButton
                      }
                      setIsLoadBookACallButton={setIsLoadBookACallButton}
                      setIsLoadMembershipUpgradeButton={
                        setIsLoadMembershipUpgradeButton
                      }
                    />
                  )}
                </>
              </div>
            </div>
          </div>
          {showQuestionsSreen !== 2 && (
            <GeneralModelBox
              open={Boolean(openModal)}
              setOpen={setOpenModal}
              className={"challenge-video-days-questions-modal"}
              componentToPassDown={
                <>
                  {openModal === 1 ? (
                    <>
                      <div className="questions-top-section">
                        <div
                          className="unlock-popup-cross-icon"
                          onClick={() => {
                            setOpenModal(0);
                            setHideSubmitButton(false);
                          }}
                        >
                          x
                        </div>
                      </div>
                    </>
                  ) : openModal === 2 ? (
                    openModal && (
                      <CongratulationMessage
                        handleCross={() => setOpenModal(0)}
                        thanks_description={
                          tabData.question_configration?.thanks_description
                        }
                      />
                    )
                  ) : openModal === 4 ? (
                    openModal && (
                      <CongratulationMessage
                        handleCross={() => setOpenModal(0)}
                        handleOpenCreateModal={handleOpenCreateModal}
                        missionContentSettings={missionContentSettings}
                        missionComoleted={missionComoleted}
                        schedule={data}
                        scheduleData={data?.content_settings}
                        thanks_description={
                          tabData?.content_settings?.congratulation_description
                        }
                      />
                    )
                  ) : openModal === 5 ? (
                    openModal && (
                      <StartMission
                        handleCross={() => setOpenModal(0)}
                        missionDetail={missionDetail}
                        handleUnlock={handleMissionStart}
                        isSubmitting={isSubmitting}
                      />
                    )
                  ) : (
                    openModal && (
                      <UnlockVideo
                        handleCross={() => setOpenModal(0)}
                        selectedSchedule={selectedSchedule}
                        handleUnlock={handleUnlock}
                        isSubmitting={isSubmitting}
                      />
                    )
                  )}
                </>
              }
            />
          )}
          <CustomConfirmation
            open={openConfirm}
            setOpen={setOpenConfirm}
            title={`Unlocking this Schedule will leave you with a remaining balance of ${number_with_comma(
              userCoin - unLockCoin
            )} coins. Are you sure you want to proceed?`}
            handleAgree={handleConfirmSubmit}
            isLoading={isSubmitting}
          />
          <CustomConfirmation
            open={openMissionConfirm}
            setOpen={setOpenMissionConfirm}
            title={`Starting this Mission will leave you with a remaining balance of ${number_with_comma(
              userCoin - unLockCoin
            )} coins. Are you sure you want to proceed?`}
            handleAgree={handleStartMission}
            isLoading={isSubmitting}
          />
          <>
            <CreatePostMission
              openCreateModal={openCreateModal}
              setOpenCreateModal={setOpenCreateModal}
              rewardData={data?.content_settings}
            />
          </>
          <WhatsappPopUpModel
            open={open}
            setOpen={setOpen}
            title={"Dynamite Diary"}
            show_small_title={true}
            show_date_and_income={true}
            componentToPassDown={
              <>
                <MissionDynamiteDiary
                  toolObject={ToolObject}
                  complete_action={complete_action}
                  setSchedules={setSchedules}
                  schedules={schedules}
                  setOpen={setOpen}
                />
              </>
            }
          />
        </>
      }
    </>
  );
}
