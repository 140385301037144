import React from "react";
import { Box, Typography, Grid, Chip } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { useNavigate } from "react-router-dom";

function SourceSession({ session_recording }) {
  const navigate = useNavigate();
  const handleNavigate = (path, item) => {
    if (path == "session") {
      navigate(
        `/source-session/${item.source_session._id}/source-session-recordings`
      );
    } else {
      navigate(
        `/source-session/${item?.source_session?._id}/source-session-recording/${item.source_session_recording_slug}`
      );
    }
  };
  return (
    <Box className="p-0">
      <Grid container spacing={2}>
        {session_recording.map((session, index) => (
          <Grid item xs={12} md={12} key={index}>
            <Box
              className="card"
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: "12px",
                overflow: "hidden",
                height: "100%",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              }}
            >
              {/* <h4
                variant="h6"
                className="ms-1 p-2 bg-top-session text-session mb-0"
              >
                {session.title}
              </h4> */}

              <Grid container className="bg-session">
                <Grid item xs={12} sm={3} sx={{ padding: "12px" }}>
                  <Box
                    component="img"
                    src={
                      s3baseUrl + session.session_recording_images.thumbnail_2
                    }
                    alt={session.title}
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "123px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleNavigate("session", session)}
                  />
                </Grid>

                <Grid item xs={12} sm={9} sx={{ padding: "12px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      onClick={() => handleNavigate("session", session)}
                      style={{ cursor: "pointer" }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "16px",
                          marginBottom: "0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {session.source_session.title}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: "12px",
                          color: "#B0B0B0",
                          marginBottom: "10px",
                        }}
                      >
                        {session?.source_session?.short_description}
                      </Typography>
                    </span>

                    <div className="col-sm-12 col-md-12 program-category-btn mt-2 text-start">
                      <Chip
                        label={htmlDecode(session.title)}
                        color="primary"
                        className="me-1 dd-chip chip-update"
                        variant="outlined"
                        onClick={() => handleNavigate("iugiy", session)}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default SourceSession;
