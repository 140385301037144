import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, MenuItem } from "@mui/material";
// components
import MenuPopover from "../MenuPopover";
import { get_root_value } from "src/utils/domUtils";
// import { get_root_value } from "src/utils/domUtils";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CustomPopoverSection(props) {
  const anchorRef = useRef(null);
  const { menu, data, copyLink } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      className="remove-border menu-background-circle"
    >
      {copyLink ? (
        <ContentCopyIcon
          style={{
            cursor: "pointer",
            fontSize: 23,
            color: get_root_value("--portal-theme-primary"),
          }}
          className="pointer custom-popover-icon"
          ref={anchorRef}
          onClick={handleOpen}
        />
      ) : (
        <MoreVertIcon
          style={{
            cursor: "pointer",
            fontSize: 20,
            color: get_root_value("--portal-theme-primary"),
          }}
          className="pointer custom-popover-icon"
          ref={anchorRef}
          onClick={handleOpen}
        />
      )}
      <MenuPopover
        className="custom-popover"
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ marginLeft: 1.8, maxHeight: 300 }}
      >
        {menu.map((option) => (
          <MenuItem
            key={option.label}
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
              option.handleClick(data);
            }}
          >
            {option.icon && (
              <Icon
                fontSize="18"
                style={{ color: "var(--portal-theme-primary)" }}
                className="me-2"
                icon={option.icon}
              />
            )}
            <span>{option.label}</span>
          </MenuItem>
        ))}

        {/* <PositionedMenu /> */}
      </MenuPopover>
    </div>
  );
}
