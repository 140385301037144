import { Avatar, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import {
  decryptToken,
  get_date_with_user_time_zone,
  string_avatar,
} from "src/utils/constants";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import {
  _add_chat,
  _delete_message,
  _get_chat_message,
  _send_message,
  _update_message,
} from "src/DAL/Chat/Chat";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_local_preview } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { update_image_on_s3_for_chat } from "src/DAL/LiveEvents/LiveEvents";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Markdown from "markdown-to-jsx";
import LinkPopup from "./LinkPopup";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import VoiceRecorder from "./VoiceRecorder";
import ReactAudioPlayer from "./ReactAudioPlayer";
import moment from "moment";
import EmojiPickerPopup from "src/components/GeneralComponents/EmojiPickerPopup";
import { saveAs } from "file-saver";

function ChatMessages({ is_popup }) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedChat,
    selectedUser,
    messagesList,
    loadMoreData,
    lastBookElementRef,
    isLoadingMore,
    count,
    setSelectedMessage,
    selectedMessage,
    setMessagesList,
    get_formatted_message,
    scroll_to_bottom_on_message,
    get_chat_history_list,
    setChatList,
    isLoadingChat,
  } = useChat();
  const inputRef = useRef(null);
  const { userInfo, socket } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openFormatter, setOpenFormatter] = useState(false);
  const [isStartRecording, setIsStartRecording] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [inputHeight, setInputHeight] = useState(0);

  const receiver_profile = selectedChat?.receiver_profile;
  const my_profile = selectedChat?.my_profile;

  const handleUploadFile = (e) => {
    const _file = e.target.files[0];
    setFile(_file);
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      setIsStartRecording(true);
    } catch (error) {
      enqueueSnackbar(
        "You have blocked microphone access. Please allow microphone to start recording",
        { variant: "error" }
      );
    }
  };

  const handleRemoveFile = (e) => {
    setFile(null);
    setImage(null);
  };

  const handleCancelUpdate = async () => {
    setSelectedMessage(null);
    setMessage("");
    setFile(null);
    setImage(null);
    setIsUpdate(false);
  };

  const handleEdit = async (message_obj) => {
    setSelectedMessage(message_obj);
    setMessage(message_obj.message);
    setImage(message_obj.image);
    setIsUpdate(true);
  };

  const handleCancelAudio = () => {
    setIsStartRecording(false);
  };

  const handleSendAudio = async (file, duration) => {
    let receiver_id = selectedUser?._id;
    let action;

    if (!receiver_id) {
      receiver_id = selectedChat.receiver_profile._id;
      action = selectedChat.receiver_profile.action_user;
    }
    let image = "";
    let is_error = false;
    if (!file) {
      enqueueSnackbar("message content is required", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("image", file, "audio.mp3");
    formData.append("width", 600);
    formData.append("height", 700);
    // formData.append("file_type", "audio");
    const result = await update_image_on_s3_for_chat(formData);
    if (result.code === 200) {
      image = result.image_path;
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      is_error(true);
    }
    if (is_error) {
      return;
    }

    const postData = {
      receiver_id: receiver_id,
      message: message,
      audio_duration: duration,
      audio_url: image,
      receiver_type: action ? action : "member_user",
      x_sh_auth: decryptToken(localStorage.getItem("token")),
    };
    socket.emit("send_chat_message", postData);
    setMessage("");
    setFile(null);
    setImage(null);
    setIsLoading(false);
    setIsStartRecording(false);
  };

  const handleSendMessage = async (receiver_id, action) => {
    let image = "";
    let is_error = false;
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("width", 600);
      formData.append("height", 700);
      const result = await update_image_on_s3_for_chat(formData);

      if (result.code === 200) {
        image = result.image_path;
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        is_error(true);
      }
    }

    if (is_error) {
      return;
    }
    if (!message && !image) {
      enqueueSnackbar("message content is required", { variant: "error" });
      return;
    }

    const postData = {
      receiver_id: receiver_id,
      message: message,
      image: image,
      receiver_type: action ? action : "member_user",
      x_sh_auth: decryptToken(localStorage.getItem("token")),
    };
    socket.emit("send_chat_message", postData);
    setMessage("");
    setFile(null);
    setImage(null);
    setIsLoading(false);
  };

  const handleSubmitMessage = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isUpdate) {
      handleUpdateMessage();
      return;
    }

    if (selectedUser) {
      handleSendMessage(selectedUser._id);
      console.log(selectedUser, "upper ");
    } else {
      console.log(selectedChat, "lower");
      // hitting for main chat
      handleSendMessage(
        selectedChat.receiver_profile._id,
        selectedChat.receiver_profile.action_user
      );
    }
  };

  const handleDelete = async (message_obj) => {
    setSelectedMessage(message_obj);
    setOpenDelete(true);
  };

  const handleAgreeDelete = async () => {
    setOpenDelete(false);
    const postData = {
      chat_id: selectedMessage.chat_id,
      message_id: selectedMessage._id,
    };
    socket.emit("delete_chat_message", postData);
  };

  const handleUpdateMessage = async () => {
    let _image = image;
    let is_error = false;
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("width", 600);
      formData.append("height", 700);
      const result = await update_image_on_s3_for_chat(formData);

      if (result.code === 200) {
        _image = result.image_path;
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        is_error(true);
      }
    }

    if (is_error) {
      return;
    }
    if (!message && !_image) {
      enqueueSnackbar("message content is required", { variant: "error" });
      return;
    }

    const postData = {
      message_id: selectedMessage._id,
      message: message,
      image: _image,
    };

    setMessage("");
    setFile(null);
    setImage(null);
    setSelectedMessage(null);
    setIsUpdate(false);
    setIsLoading(false);
    socket.emit("update_chat_message", postData);
  };

  const handleGetSelectedText = () => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      return selectedText;
    }
  };

  const handleOnBold = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "**" + seleted_text + "**"
      );
      setMessage(newText);
    }
  };

  const handleOnItalic = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "*" + seleted_text + "*"
      );
      setMessage(newText);
    }
  };

  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address})`;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;

    // Insert the text at the cursor position
    setMessage(message.slice(0, start) + make_link + message.slice(end));

    // Move the cursor after the inserted text
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };

  const handleOpenLinkPopup = () => {
    setOpenLink(true);
  };

  const handleToggleFormatter = () => {
    let heigh = 0;
    let marginTop = 0;
    let paddingTop = 0;
    let elements = document.getElementsByClassName("chat-formatters-box")[0];
    if (!openFormatter) {
      setTimeout(() => {
        setOpenFormatter(!openFormatter);
      }, 100);
      heigh = "35px";
    } else {
      setOpenFormatter(!openFormatter);
    }
    elements.style.height = heigh;
    elements.style.marginTop = marginTop;
    elements.style.paddingTop = paddingTop;
  };

  const handleDownload = async (message) => {
    try {
      const file_path = `${s3baseUrl}${message.audio_url}`;
      const response = await fetch(file_path);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const fileName = message.audio_url.split("/").pop();
      saveAs(blob, fileName);
    } catch (error) {
      console.error("Failed to fetch the file:", error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handle_menus = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleDelete,
      },
    ];
    if (row.message_type === "audio") {
      MENU_OPTIONS.push({
        label: "Download",
        icon: "humbleicons:download",
        handleClick: handleDownload,
      });
    } else {
      MENU_OPTIONS.unshift({
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      });
    }
    return MENU_OPTIONS;
  };

  const received_message_option = (row) => {
    const MENU_OPTIONS = [];
    if (row.message_type === "audio") {
      MENU_OPTIONS.push({
        label: "Download",
        icon: "humbleicons:download",
        handleClick: handleDownload,
      });
    }
    return MENU_OPTIONS;
  };

  // handle new message from socket
  const handle_new_message = async (data) => {
    const new_message = get_formatted_message(data.message_obj);
    setMessagesList((old_messages) => [...old_messages, new_message]);
    scroll_to_bottom_on_message();
    get_chat_history_list({});
  };

  const handle_edit_message = async (data) => {
    const new_message = get_formatted_message(data.message_obj);
    setMessagesList((old_messages) => {
      return old_messages.map((message) => {
        if (message._id === data.message_obj._id) {
          return new_message;
        } else {
          return message;
        }
      });
    });
    setChatList((prev) => {
      return prev.map((chat) => {
        if (chat._id == new_message.chat_id) {
          return {
            ...chat,
            last_message: new_message.message,
            last_message_date_time: new_message.message_date_time,
            message_id: new_message._id,
          };
        } else {
          return chat;
        }
      });
    });
  };
  const handle_delete_message = async (data) => {
    setMessagesList((old_messages) => {
      return old_messages.filter((message) => message._id !== data.message_id);
    });
    if (data.is_last_message) {
      setChatList((prev) => {
        return prev.map((chat) => {
          if (chat._id == data.chat_id) {
            return {
              ...chat,
              last_message: data.message_obj.message,
              last_message_date_time: data.message_obj.message_date_time,
              message_id: data.message_obj._id,
            };
          } else {
            return chat;
          }
        });
      });
    }
  };

  useEffect(() => {
    handleCancelUpdate();
    socket.on("send_chat_message_event_for_sender", (data) => {
      handle_new_message(data);
    });
    socket.on("update_chat_message_event_for_sender", (data) => {
      handle_edit_message(data);
    });
    socket.on("delete_chat_message_event_for_sender", (data) => {
      handle_delete_message(data);
    });

    return () => {
      socket.off("send_chat_message_event_for_sender");
      socket.off("update_chat_message_receiver");
      socket.off("delete_chat_message_event_for_sender");
    };
  }, [selectedChat?._id]);

  useEffect(() => {
    let input_element = document.getElementById("chat-right-bottom");
    if (input_element) {
      setTimeout(() => {
        let input_height = input_element.offsetHeight;
        setInputHeight(input_height);
      }, 200);
    }
  }, [message, openFormatter]);

  const renderLink = (props) => (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );

  return (
    <>
      <div>
        {/* {isLoadingChat && (
          <div className="d-flex justify-content-center">
            <CircularProgress
              className=""
              style={{ width: 20, height: 20, marginTop: "25%" }}
              color="primary"
            />
          </div>
        )} */}

        {!selectedChat ? (
          <div className="d-flex justify-content-center align-items-center no-chat-wrapper">
            {/* <img src={chat_banner_image} className="pt-5" width="80%" alt="" /> */}
            <div className="no-chat-div">
              <Icon
                className="no-chat-image"
                icon="material-symbols:chat-bubble-outline"
              />
            </div>
          </div>
        ) : (
          <div>
            <div
              className="user-chat-box-body ps-2 pe-2"
              style={
                is_popup
                  ? {
                      height: `calc(57vh - ${inputHeight}px)`,
                      paddingBottom: "10px",
                      overflow: isLoadingMore ? "hidden" : "scroll",
                    }
                  : {
                      height: `calc(60vh)`,
                      overflow: isLoadingMore ? "hidden" : "scroll",
                    }
              }
              id="chat_box_body"
            >
              {isLoadingChat ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress
                    className=""
                    style={{ width: 40, height: 40, marginTop: "25%" }}
                    color="primary"
                  />
                </div>
              ) : (
                selectedChat &&
                count > messagesList.length && (
                  <div className="text-center">
                    {isLoadingMore ? (
                      <CircularProgress
                        className="mt-3"
                        style={{ width: 20, height: 20 }}
                        color="primary"
                      />
                    ) : (
                      <span
                        ref={lastBookElementRef}
                        onClick={loadMoreData}
                        id="load-more-messages"
                      >
                        <CircularProgress
                          className="mt-3"
                          onClick={loadMoreData}
                          style={{ width: 20, height: 20 }}
                          color="primary"
                        />
                      </span>
                    )}
                  </div>
                )
              )}
              {messagesList.map((message_obj, i) => {
                return (
                  <>
                    {message_obj.is_self ? (
                      <div
                        className="w-100 d-flex justify-content-end control-dots"
                        key={message_obj._id}
                      >
                        <div className="chat-message-dots">
                          <CustomPopover
                            menu={handle_menus(message_obj)}
                            data={message_obj}
                          />
                        </div>
                        <div
                          className={`chat-outgoing ${
                            message_obj.message_type == "video" &&
                            message_obj.embed_code
                              ? "w-85"
                              : ""
                          }`}
                          style={{
                            backgroundColor: is_popup
                              ? "#0d0d0d"
                              : "var(--background-secondary-color)",
                          }}
                        >
                          {message_obj.message_type == "audio" &&
                            message_obj.audio_url && (
                              <ReactAudioPlayer
                                audio_url={s3baseUrl + message_obj.audio_url}
                                duration={message_obj.audio_duration}
                              />
                            )}
                          {message_obj.message_type == "image" &&
                            message_obj.image && (
                              <img
                                className="mb-2"
                                src={s3baseUrl + message_obj.image}
                              ></img>
                            )}
                          {message_obj.message_type == "video" &&
                            message_obj.embed_code && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: message_obj.embed_code,
                                }}
                              ></div>
                            )}
                          {message_obj.message_type == "html" ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: message_obj.message,
                              }}
                            ></div>
                          ) : (
                            !!message_obj.message && (
                              <Markdown
                                options={{
                                  overrides: { a: { component: renderLink } },
                                }}
                              >
                                {message_obj.message}
                              </Markdown>
                            )
                          )}
                          <Tooltip
                            title={get_date_with_user_time_zone(
                              message_obj.createdAt,
                              "DD MMM YYYY [at] hh:mm A",

                              userInfo.time_zone,
                              userInfo.adminTimeZone
                            )}
                            arrow
                          >
                            <span className="message-time">
                              {get_date_with_user_time_zone(
                                message_obj.createdAt,
                                "DD-MM-YYYY hh:mm A",
                                userInfo.time_zone,
                                userInfo.adminTimeZone
                              )}
                            </span>
                          </Tooltip>
                        </div>
                        {!is_popup && (
                          <Avatar
                            src={s3baseUrl + my_profile?.profile_image}
                            style={{ height: 35, width: 35, marginTop: 10 }}
                          >
                            {string_avatar(my_profile?.first_name[0])}
                          </Avatar>
                        )}
                      </div>
                    ) : (
                      <div
                        className="w-100 d-flex justify-content-start control-dots"
                        key={message_obj._id}
                      >
                        {!is_popup && (
                          <Avatar
                            src={s3baseUrl + receiver_profile?.profile_image}
                            style={{ height: 35, width: 35, marginTop: 10 }}
                          >
                            {string_avatar(receiver_profile?.first_name[0])}
                          </Avatar>
                        )}
                        <div
                          className={`chat-incoming ${
                            message_obj.message_type == "video" &&
                            message_obj.embed_code
                              ? "w-85"
                              : ""
                          }`}
                        >
                          {message_obj.message_type == "image" &&
                            message_obj.image && (
                              <img
                                className="mb-2"
                                src={s3baseUrl + message_obj.image}
                              ></img>
                            )}
                          {message_obj.message_type == "video" &&
                            message_obj.embed_code && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: message_obj.embed_code,
                                }}
                              ></div>
                            )}
                          {message_obj.message_type == "audio" &&
                            message_obj.audio_url && (
                              // <audio controls>
                              //   <source
                              //     src={s3baseUrl + message_obj.audio_url}
                              //   />
                              // </audio>
                              <ReactAudioPlayer
                                audio_url={s3baseUrl + message_obj.audio_url}
                                duration={message_obj.audio_duration}
                              />
                            )}
                          {message_obj.message_type == "html" ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: message_obj.message,
                              }}
                            ></div>
                          ) : (
                            !!message_obj?.message && (
                              <Markdown
                                options={{
                                  overrides: { a: { component: renderLink } },
                                }}
                              >
                                {message_obj?.message}
                              </Markdown>
                            )
                          )}
                          {/* {message_obj.message_type == "audio" && (
                            <div className="message-duration">
                              {moment
                                .utc(message_obj.audio_duration)
                                .format("mm:ss")}
                            </div>
                          )} */}
                          <Tooltip
                            title={get_date_with_user_time_zone(
                              message_obj.createdAt,
                              "DD MMM YYYY [at] hh:mm A",

                              userInfo.time_zone,
                              userInfo.adminTimeZone
                            )}
                            arrow
                          >
                            <span className="message-time">
                              {get_date_with_user_time_zone(
                                message_obj.createdAt,
                                "DD-MM-YYYY hh:mm A",
                                userInfo.time_zone,
                                userInfo.adminTimeZone
                              )}
                            </span>
                          </Tooltip>
                        </div>
                        {received_message_option(message_obj).length > 0 && (
                          <div className="chat-message-dots mt-3">
                            <CustomPopover
                              menu={received_message_option(message_obj)}
                              data={message_obj}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div
        className="chat-right-bottom"
        id="chat-right-bottom"
        style={{
          position: is_popup ? "absolute" : "",
        }}
      >
        {(receiver_profile || is_popup) && (
          <div
            className="chat-send-message-wrapper"
            style={{
              backgroundColor: is_popup
                ? "var(--sidebars-background-color)"
                : "#0d0d0d",
            }}
          >
            {/* {openFormatter && <hr className="mb-1 mt-0" />} */}

            {isStartRecording ? (
              <VoiceRecorder
                handleSubmit={handleSendAudio}
                handleCancel={handleCancelAudio}
              />
            ) : (
              <div>
                <form
                  onSubmit={handleSubmitMessage}
                  className="d-flex align-items-end w-100"
                >
                  {/* <textarea
                rows={4}
                className="chat-send-input"
                type="text"
                value={message}
                style={{ height: is_popup ? "40px" : "100px" }}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write your message..."
                required={!file}
                autoFocus
              /> */}

                  {!file && !image && (
                    <span className="">
                      <input
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="message-chat-upload-button"
                        style={{ display: "none" }}
                        name=""
                        onChange={handleUploadFile}
                      />
                      <label>
                        <Tooltip title="Add Image" placement="top">
                          <IconButton
                            onClick={() => {
                              document.getElementById(
                                "message-chat-upload-button"
                              ) &&
                                document
                                  .getElementById("message-chat-upload-button")
                                  .click();
                            }}
                            className="upload-image-icon mb-2"
                          >
                            <AddPhotoAlternateIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </span>
                  )}

                  <div className="w-100">
                    <div
                      className={`chat-formatters-box d-flex ${
                        openFormatter ? "chat-formatters-border" : ""
                      }`}
                      style={{
                        backgroundColor: is_popup
                          ? "var(--background-secondary-color)"
                          : "#0d0d0d",
                      }}
                    >
                      {openFormatter && (
                        <>
                          <div
                            className="formatter-icon"
                            onClick={handleOnBold}
                          >
                            <Icon
                              fontSize="15"
                              style={{ color: "var(--portal-theme-primary)" }}
                              icon="octicon:bold-24"
                            />
                          </div>
                          <div
                            className="formatter-icon"
                            onClick={handleOnItalic}
                          >
                            <Icon
                              fontSize="15"
                              style={{ color: "var(--portal-theme-primary)" }}
                              icon="fe:italic"
                            />
                          </div>
                          <div
                            className="formatter-icon"
                            onClick={handleOpenLinkPopup}
                          >
                            <Icon
                              fontSize="15"
                              style={{ color: "var(--portal-theme-primary)" }}
                              icon="ion:link"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {image && (
                      <div
                        className="chat-image-preview-box"
                        style={{
                          borderRadius: openFormatter ? "0px" : "15px 15px 0 0",
                          backgroundColor: is_popup
                            ? "var(--background-primary-color) "
                            : "var(--background-secondary-color)",
                        }}
                      >
                        <div className="chat-preview-image chat-image">
                          <img
                            className="chat-preview-image"
                            src={s3baseUrl + image}
                            alt=""
                          />

                          <Icon
                            className="cross"
                            onClick={handleRemoveFile}
                            icon="charm:circle-cross"
                          />
                        </div>
                      </div>
                    )}
                    {file && (
                      <div
                        className="chat-image-preview-box"
                        style={{
                          borderRadius: openFormatter ? "0px" : "15px 15px 0 0",
                          backgroundColor: is_popup
                            ? "#0d0d0d"
                            : "var(--background-secondary-color)",
                        }}
                      >
                        <div className="chat-preview-image chat-image">
                          <img
                            className="chat-preview-image"
                            src={get_local_preview(file)}
                            alt=""
                          />

                          <Icon
                            className="cross"
                            onClick={handleRemoveFile}
                            icon="charm:circle-cross"
                          />
                        </div>
                      </div>
                    )}

                    <div className="position-relative">
                      <EmojiPickerPopup
                        setInput={setMessage}
                        inputRef={inputRef}
                      />
                      <TextareaAutosize
                        ref={inputRef}
                        id="inputField"
                        className={`chat-send-input ${
                          file || image || openFormatter
                            ? "border-with-image"
                            : ""
                        }`}
                        style={{
                          height: is_popup ? "50px" : "100px",
                          backgroundColor: is_popup
                            ? "#0d0d0d"
                            : "var(--background-secondary-color)",
                        }}
                        type="text"
                        value={message}
                        maxRows={6}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Write your message..."
                        required={!(image || file)}
                        autoFocus
                      />
                      <Icon
                        fontSize={20}
                        className="show-hide-chat-formatter"
                        icon={`${
                          openFormatter
                            ? "mingcute:down-line"
                            : "fluent:text-edit-style-16-regular"
                        }`}
                        onClick={handleToggleFormatter}
                      />
                    </div>
                  </div>
                  {!message && !image && !file && !isLoading && (
                    <Tooltip title="Start Recording">
                      <IconButton
                        className="ms-2 mb-2"
                        onClick={handleStartRecording}
                      >
                        <MicIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(message || image || file) && !isLoading && (
                    <IconButton type="submit" className="ms-2 mb-2">
                      <SendIcon />
                    </IconButton>
                  )}
                  {isLoading && (
                    <IconButton className="ms-2 mb-2">
                      <CircularProgress
                        style={{
                          width: 23,
                          height: 23,
                        }}
                      />
                    </IconButton>
                  )}
                  {isUpdate && (
                    <IconButton
                      onClick={handleCancelUpdate}
                      className="ms-2 mb-2"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </form>
              </div>
            )}
          </div>
        )}
      </div>
      <CustomConfirmation
        title={"Are you sure you want to delete this message?"}
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleAgreeDelete}
      />

      <GeneralPopUpModel
        open={openLink}
        setOpen={setOpenLink}
        title={"Insert Link"}
        componentToPassDown={<LinkPopup handleAddLink={handleAddLink} />}
      />
    </>
  );
}

export default ChatMessages;
