import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import {
  get_member_event_detail_for_member,
  live_events_list_api,
} from "src/DAL/LiveEvents/LiveEvents";
import CatagoryChatCard from "./components/CatagoryChatCard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ReplaysList from "./ReplaysList";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ChatCard from "./components/Chat-Box/ChatCard";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import {
  get_live_event_detail,
  handleFeatureStatus,
  handle_delete_message,
  handle_send_message,
  handle_update_message,
  handle_validate_redirect,
  receive_delete_live_message_by_socket,
  receive_feature_status_by_socket,
  receive_new_live_message_by_socket,
  receive_update_live_message_by_socket,
  scroll_to_bottom,
} from "./live_event_functions";
import ShowEventCommentLikes from "../Community/components/LikesPopup/ShowEventCommentLikes";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const menu_array = [
  {
    id: 1,
    title: "Chat",
    chat_tab_status: true,
  },
  {
    id: 2,
    title: "Replays",
    replay_tab_status: true,
  },
];

export default function CatagoriesDetailScreen({ categories }) {
  const { userInfo, socket, adminTimeZone } = useContentSetting();

  const classes = useStyles();
  const { event_id, category_id } = useParams();
  const [tabsConfiguartion, setTabConfiguration] = useState();
  const [categoryDetail, setCategoryDetail] = useState(null);
  const [videosList, setVideosList] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showChat, setShowChat] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChat, setIsLoadngChat] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const breadCrumbMenu = [
    {
      title: categoryDetail?.title,
      navigation: `/live-events/${event_id}`,
      active: false,
    },
    {
      title: categoryDetail?.dynamite_event_category?.title,
      navigation: "",
      active: true,
    },
  ];

  // live chat functions start

  const { handleSetIsChatShow } = useChat();

  const [newChat, setNewChat] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [parentMessage, setParentMessage] = useState({});
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [eventDetail, setEventDetail] = useState();
  const [isMemberFree, setIsMemberFree] = useState();
  const [liveChat, setLiveChat] = useState([]);
  const [pinnedComments, setPinnedComments] = useState([]);
  const [currentEventsArray, setCurrentEventsArray] = useState([]);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [upcomingEventsArray, setUpcomingEventsArray] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [image, setImage] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [previews, setPreviews] = useState("");
  const [selectedComment, setSelectedComment] = useState({});
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams();

  const [isEventAvailable, setIsEventAvailable] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleEmojiSelect = (emoji) => {
    setChatMessage((msg) => msg + emoji.native);
  };

  const handleOpenReplyBox = (object) => {
    setParentMessage(object);
    setIsReply(true);
  };

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleRemoveAll = () => {
    handleRemove();
    setChatMessage("");
    setIsSubmitting(false);
    setParentMessage({});
    setFormType("ADD");
    setIsReply(false);
  };

  const handleDeleteLiveComment = async () => {
    handle_delete_message(eventDetail, selectedComment, socket);
    setOpenDelete(false);
  };
  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowEmojiPicker(false);
    handle_send_message(
      image,
      eventDetail,
      chatMessage,
      userInfo,
      isReply,
      parentMessage,
      socket,
      selectedVideo
    );
    handleRemoveAll();
  };

  const handleMessageUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowEmojiPicker(false);
    handle_update_message(
      eventDetail,
      oldImage,
      previews,
      image,
      chatMessage,
      socket,
      selectedComment
    );
    handleRemoveAll();
  };

  const handleCommentEdit = (comment) => {
    setSelectedComment(comment);
    setChatMessage(comment.message);
    if (comment.file_url) {
      setPreviews(s3baseUrl + comment.file_url);
      setOldImage(comment.file_url);
    } else {
      setImage("");
      setPreviews("");
      setOldImage("");
    }
    setFormType("EDIT");
  };

  const handleCommentPin = (comment) => {
    handleFeatureStatus(comment, eventDetail, socket);
  };

  const handleCommentDelete = (comment) => {
    setSelectedComment(comment);
    setOpenDelete(true);
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const getOneDigit = (digi) => {
    let reminder = digi % 10;
    let deviser = digi / 10;
    let decimalPart = Math.floor(deviser);
    return { reminder, decimalPart };
  };

  const handleLikeComment = (comment, parentComment) => {
    const event_id = eventDetail._id;
    const comment_id = comment._id;
    const video_id = eventDetail.feature_video._id;
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };

    let socket_object = {
      event_id,
      video_id,
      member,
      comment_id,
    };

    if (parentComment) {
      socket_object.parent_message = parentComment._id;
    }
    socket.emit("live_event_message_like", socket_object);
  };

  const handleLikeParentComment = (data) => {
    setLiveChat((old) => {
      return old.map((old_comment) => {
        let like_count = old_comment.like_count;
        let is_liked = old_comment.is_liked;
        if (data.action == "like") {
          like_count = like_count + 1;
        } else {
          like_count = like_count - 1;
        }

        if (data.member._id == userInfo._id) {
          is_liked = !is_liked;
        }

        if (old_comment._id == data.comment_id) {
          return {
            ...old_comment,
            like_count: like_count,
            is_liked: is_liked,
          };
        } else {
          return old_comment;
        }
      });
    });
    setPinnedComments((old) => {
      return old.map((old_comment) => {
        let like_count = old_comment.like_count;
        let is_liked = old_comment.is_liked;
        if (data.action == "like") {
          like_count = like_count + 1;
        } else {
          like_count = like_count - 1;
        }

        if (data.member._id == userInfo._id) {
          is_liked = !is_liked;
        }

        if (old_comment._id == data.comment_id) {
          return {
            ...old_comment,
            like_count: like_count,
            is_liked: is_liked,
          };
        } else {
          return old_comment;
        }
      });
    });
  };

  const handleLikeChildComment = (data) => {
    setLiveChat((old) => {
      return old.map((old_comment) => {
        if (old_comment._id == data.parent_message) {
          let replies = old_comment.replies;
          let new_replies = replies.map((reply) => {
            if (reply._id == data.comment_id) {
              let is_liked = reply.is_liked;
              let like_count = reply.like_count;
              if (data.action == "like") {
                like_count = like_count + 1;
              } else {
                like_count = like_count - 1;
              }

              if (data.member._id == userInfo._id) {
                is_liked = !is_liked;
              }

              return {
                ...reply,
                like_count: like_count,
                is_liked: is_liked,
              };
            } else {
              return reply;
            }
          });
          return {
            ...old_comment,
            replies: new_replies,
          };
        } else {
          return old_comment;
        }
      });
    });
    setPinnedComments((old) => {
      return old.map((old_comment) => {
        if (old_comment._id == data.parent_message) {
          let replies = old_comment.replies;
          let new_replies = replies.map((reply) => {
            if (reply._id == data.comment_id) {
              let is_liked = reply.is_liked;
              let like_count = reply.like_count;
              if (data.action == "like") {
                like_count = like_count + 1;
              } else {
                like_count = like_count - 1;
              }

              if (data.member._id == userInfo._id) {
                is_liked = !is_liked;
              }

              return {
                ...reply,
                like_count: like_count,
                is_liked: is_liked,
              };
            } else {
              return reply;
            }
          });
          return {
            ...old_comment,
            replies: new_replies,
          };
        } else {
          return old_comment;
        }
      });
    });
  };

  const handle_like_message_receiver = (data) => {
    if (data.parent_message) {
      handleLikeChildComment(data);
    } else {
      handleLikeParentComment(data);
    }
  };

  const handleOpenLikes = (comment) => {
    setSelectedComment(comment);
    setOpenLikesModal(true);
  };

  useEffect(() => {
    if (eventDetail) {
      // event handling
      socket.emit("live_event_room", eventDetail._id);
      socket.on("live_event_message_receiver", (data) => {
        receive_new_live_message_by_socket(
          data,
          eventDetail,
          userInfo,
          setLiveChat,
          setNewChat,
          selectedVideo
        );
      });
      socket.on("live_event_message_like_receiver", (data) => {
        handle_like_message_receiver(data);
      });

      socket.on("live_event_message_update_receiver", (data) => {
        receive_update_live_message_by_socket(
          data,
          userInfo,
          setLiveChat,
          setPinnedComments
        );
      });

      socket.on("live_event_message_delete_receiver", (data) => {
        receive_delete_live_message_by_socket(
          data,
          setLiveChat,
          setPinnedComments
        );
      });

      socket.on("make_message_featured_unfeatured_receiver", (data) => {
        receive_feature_status_by_socket(
          data,
          eventDetail,
          setEventDetail,
          setLiveChat,
          setPinnedComments,
          setNewChat,
          setShowScrollArrow,
          userInfo,
          setIsLoading
        );
      });
    }

    return () => {
      socket.off("member_online_for_portal");
      socket.off("live_event_message_receiver");
      socket.off("live_event_message_like_receiver");
      socket.off("live_event_message_update_receiver");
      socket.off("live_event_message_delete_receiver");
      socket.off("make_message_featured_unfeatured_receiver");
    };
  }, [eventDetail]);

  console.log(selectedVideo, " selectedVideo?._id selectedVideo?._id");

  useEffect(() => {
    setIsLoadngChat(true);
    get_live_event_detail(
      params.event_id,
      setIsEventAvailable,
      params.category_id,
      setTabValue,
      setEventDetail,
      setIsMemberFree,
      setCurrentEventsArray,
      setUpcomingEventsArray,
      setShowChat,
      setIsLoadngChat,
      setLiveChat,
      setPinnedComments,
      setNewChat,
      setShowScrollArrow,
      userInfo,
      selectedVideo?._id
    );
    setInterval(function () {
      setRefresh((r) => !r);
    }, 10000);
    return () => {
      handleSetIsChatShow(false);
    };
  }, [params.category_id, selectedVideo?._id]);

  useEffect(() => {
    const savedTab = localStorage.getItem("live_event_tab");
    if (savedTab) {
      setTabValue(parseInt(savedTab));
    }
  }, []);

  useEffect(() => {
    if (!isEventAvailable) {
      navigate(`/live-events`);
    }
  }, [isEventAvailable]);

  useEffect(() => {
    setTimeout(() => {
      setRefresh((r) => !r);
    }, 0);

    window.scrollTo(0, 0);
  }, [showChat]);

  // end
  const getCategoryDetail = async () => {
    setIsLoading(true);
    let data = {
      dynamite_event_id: event_id,
      event_category_id: category_id,
    };
    const result = await get_member_event_detail_for_member(data);
    if (result.code === 200) {
      setTabConfiguration(result.member_dynamite_event.tab_configration);
      console.log(
        result.member_dynamite_event,
        "result.member_dynamite_eventresult.member_dynamite_event"
      );
      setCategoryDetail(result.member_dynamite_event);
      const videos =
        result?.member_dynamite_event?.dynamite_event_category?.videos;
      setVideosList(videos);
      if (videos && videos.length > 0) {
        setSelectedVideo(videos[0]);
        get_live_event_detail(
          params.event_id,
          setIsEventAvailable,
          params.category_id,
          setTabValue,
          setEventDetail,
          setIsMemberFree,
          setCurrentEventsArray,
          setUpcomingEventsArray,
          setShowChat,
          setIsLoading,
          setLiveChat,
          setPinnedComments,
          setNewChat,
          setShowScrollArrow,
          userInfo,
          videos[0]._id
        );
      } else {
        setSelectedVideo(null);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleAskQuestion = (video) => {
    if (video) {
      let question_configration = video?.question_configration;
      if (
        question_configration &&
        question_configration.show_question &&
        question_configration.button_text
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleVideoQuestions = (video) => {
    navigate(`/live-events/video/question-answers/${video._id}`);
  };
  const handleSelectedVideo = (video) => {
    setShowChat(true);
    setSelectedVideo(video);
    window.scrollTo(0, 0);
  };
  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem("live_event_tab_menu", newValue);
  };

  const TABS_OPTIONS = [];
  const numAscending =
    tabsConfiguartion &&
    [...tabsConfiguartion].sort((a, b) => +a.order - +b.order);
  numAscending?.map((value, i) => {
    console.log(
      value?.chat_tab_status,
      selectedVideo?.is_chat_enable,
      "value?.chat_tab_status"
    );
    if (value?.chat_tab_status && selectedVideo?.is_chat_enable) {
      TABS_OPTIONS.push({
        title: value.chat_tab_title,
        component: (
          <>
            <ChatCard
              eventDetail={eventDetail}
              pinnedComments={pinnedComments}
              handleCommentDelete={handleCommentDelete}
              handleCommentEdit={handleCommentEdit}
              handleCommentPin={handleCommentPin}
              liveChat={liveChat}
              handleOpenReplyBox={handleOpenReplyBox}
              newChat={newChat}
              setNewChat={setNewChat}
              setShowScrollArrow={setShowScrollArrow}
              showScrollArrow={showScrollArrow}
              isReply={isReply}
              chatMessage={chatMessage}
              setChatMessage={setChatMessage}
              formType={formType}
              handleRemoveAll={handleRemoveAll}
              previews={previews}
              handleRemove={handleRemove}
              handleUpload={handleUpload}
              isSubmitting={isSubmitting}
              handleSendMessage={handleSendMessage}
              handleMessageUpdate={handleMessageUpdate}
              setIsLoading={setIsLoading}
              setShowEmojiPicker={setShowEmojiPicker}
              handleLikeComment={handleLikeComment}
              handleOpenLikes={handleOpenLikes}
              selectedVideo={selectedVideo}
              isPortalChat={true}
            />
          </>
        ),
      });
    }
    if (value?.replays_tab_status) {
      TABS_OPTIONS.push({
        title: value?.replays_tab_title ?? "replays",
        component: (
          <>
            <ReplaysList
              categories={categoryDetail?.categories}
              eventDetail={categoryDetail}
            />
          </>
        ),
      });
    }
  });

  useEffect(() => {
    getCategoryDetail();
  }, [category_id, event_id]);
  useEffect(() => {
    if (tabsConfiguartion) {
      const defaultTabIndex = tabsConfiguartion.findIndex(
        (tab) =>
          tab.is_default_tab && (tab.chat_tab_status || tab.replays_tab_status)
      );
      if (defaultTabIndex != -1) {
        setTabValue(defaultTabIndex);
      } else {
        setTabValue(0);
      }
    }
  }, [tabsConfiguartion]);

  if (isLoading || isLoadingChat) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-8">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
        <div className="col-4 text-end">
          <div className="d-flex justify-content-end">
            {handleAskQuestion(selectedVideo) && (
              <button
                className="ms-3 small-contained-button text-nowrap"
                onClick={() => handleVideoQuestions(selectedVideo)}
              >
                {selectedVideo?.question_configration?.button_text}
              </button>
            )}
            {selectedVideo?.is_chat_enable && !showChat && (
              <button
                onClick={() => setShowChat(!showChat)}
                className="ms-3 small-contained-button text-nowrap"
              >
                {` ${showChat ? "Hide" : "Show"}`} Chat
              </button>
            )}
          </div>
        </div>
      </div>
      {selectedVideo ? (
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2 style={{ fontSize: "26px" }}>{selectedVideo?.title}</h2>
          </div>

          <div className={`col-12 ${showChat ? "col-md-8" : ""}`}>
            <div>
              <div className="col-12 mb-3 mt-2" id="video-col">
                {selectedVideo?.video_url && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedVideo.video_url,
                    }}
                  ></div>
                )}
                {selectedVideo?.video_url === "" && (
                  <img
                    src={s3baseUrl + selectedVideo.image.thumbnail_1}
                    alt=""
                    srcSet=""
                  />
                )}
              </div>
              {selectedVideo?.audio_file_url &&
                selectedVideo.video_type === "audio" && (
                  <div className="col-12 mt-3 text-center">
                    <div>
                      <audio
                        controlsList="nodownload"
                        className="w-100"
                        src={s3baseUrl + selectedVideo.audio_file_url}
                        controls
                      />
                    </div>
                  </div>
                )}
              <div className="col-12 mt-3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedVideo?.detail_description,
                  }}
                ></div>
              </div>
            </div>
          </div>

          {showChat && (
            <div className="col-12 col-lg-4 position-relative">
              <div
                class="cross-icon"
                onClick={() => setShowChat(false)}
                style={{
                  cursor: "pointer",
                  right: "20px",
                  top: "10px",
                  zIndex: "999999999999999",
                }}
              >
                x
              </div>

              <MUICustomTabs
                data={TABS_OPTIONS}
                value={tabValue}
                handleChange={handleChangeTabs}
              />
              {showEmojiPicker && (
                <div className="mt-4 mt-lg-0 ms-auto">
                  <Picker
                    data={data}
                    onEmojiSelect={handleEmojiSelect}
                    theme="dark"
                    dynamicWidth={true}
                  />
                </div>
              )}
            </div>
          )}

          <div className="col-md-6 mt-3 ">
            {selectedVideo.banner1_image &&
              selectedVideo.banner1_image.thumbnail_1 && (
                <a
                  style={{ cursor: "pointer" }}
                  className="mt-4"
                  onClick={() =>
                    handle_validate_redirect(
                      selectedVideo,
                      selectedVideo.banner1_link,
                      setIsLoading,
                      true
                    )
                  }
                >
                  <img
                    className="banner-image"
                    src={s3baseUrl + selectedVideo.banner1_image.thumbnail_1}
                  ></img>
                </a>
              )}
            {selectedVideo?.banner1_button_text &&
              selectedVideo?.banner1_link && (
                <div className="text-center mt-3">
                  <button
                    onClick={() =>
                      handle_validate_redirect(
                        selectedVideo,
                        selectedVideo.banner1_link,
                        setIsLoading,
                        true
                      )
                    }
                    class="join-today-btn fw-16 mt-0"
                  >
                    {selectedVideo?.banner1_button_text}
                  </button>
                </div>
              )}
          </div>
          <div className="col-md-6 mt-3 ">
            {selectedVideo.banner2_image &&
              selectedVideo.banner2_image.thumbnail_1 && (
                <a
                  style={{ cursor: "pointer" }}
                  className="mt-4"
                  onClick={() =>
                    handle_validate_redirect(
                      selectedVideo,
                      selectedVideo?.banner2_link,
                      setIsLoading,
                      true
                    )
                  }
                >
                  <img
                    className="banner-image"
                    src={s3baseUrl + selectedVideo.banner2_image.thumbnail_1}
                  ></img>
                </a>
              )}
            {selectedVideo?.banner2_button_text &&
              selectedVideo?.banner2_link && (
                <div className="text-center mt-3">
                  <button
                    onClick={() =>
                      handle_validate_redirect(
                        selectedVideo,
                        selectedVideo?.banner2_link,
                        setIsLoading,
                        true
                      )
                    }
                    class="join-today-btn fw-16 mt-0"
                  >
                    {selectedVideo?.banner2_button_text}
                  </button>
                </div>
              )}
          </div>
          {videosList.length > 0 && (
            <>
              <div className="col-6 mt-4">
                <h2
                  style={{ fontSize: "24px", textTransform: "capitalize" }}
                  className="mb-0"
                >
                  {categoryDetail.session_heading ?? "session Videos "}
                </h2>
              </div>

              <div className="col-12">
                <div className="row">
                  {videosList.map((video) => (
                    <div
                      className="col-12 col-md-3 col-lg-3 mt-3 cursor-pointer"
                      onClick={() => handleSelectedVideo(video)}
                    >
                      <div
                        className={`card-vedio position-relative ${
                          selectedVideo?._id === video._id
                            ? "card-vedio-highlight"
                            : ""
                        }`}
                      >
                        <div className="video_img_dev position-relative">
                          <img
                            src={s3baseUrl + video.image.thumbnail_1}
                            className="img_width w-100"
                            style={{
                              borderTopLeftRadius: "8px",
                              borderTopRightRadius: "8px",
                            }}
                          />
                        </div>
                        <div className="p-2 pt-3">
                          <h3 style={{ fontSize: "14px", fontWeight: "600" }}>
                            {video.title}
                          </h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="mt-5">
          <RecordNotFound />
        </div>
      )}
      <ShowEventCommentLikes
        open={openLikesModal}
        setOpen={setOpenLikesModal}
        comment_id={selectedComment._id}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete live comment?"}
        handleAgree={handleDeleteLiveComment}
      />
    </div>
  );
}
