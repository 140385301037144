import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";

import {
  AddtoCArt,
  removetoCArt,
  removetoCArtall,
} from "src/DAL/ShopApi/ApiShop";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { s3baseUrl } from "src/config/config";
import { convertCurrencyToSign } from "src/utils/constants";
import { useNavigate } from "react-router-dom";
import CustomConfirmationShop from "./CustomConfirmationShop";
import { htmlDecode } from "src/utils/convertHtml";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    width: "20px !important",
    height: "auto !important",
  },
}));

const ShopCart = () => {
  const { cartElement, setCardElement, shopContentSettings } =
    useContentSetting();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);
  const navigate = useNavigate();
  const [deletePasssValue, setdeletePasssValue] = useState();
  const [loadingPopUp, setloadingPopUp] = useState(false);
  const handleRemove = (itemId) => {
    setCardElement((data) => data.filter((item) => item._id !== itemId));
  };
  const handleRemoveall = (itemId) => {
    setCardElement([]);
  };
  const handleAgreeDelete = (item, index) => {
    let data_object = {
      item: item,
      index: index,
    };
    setdeletePasssValue(data_object);
    setOpenDeleteSingle(true);
  };
  const handleAgreeDeleteAll = (item, index) => {
    setOpenDelete(true);
  };
  const handleSubmit = async (item, quantityCount) => {
    let postData = {
      product_id: item.product_id,
      quantity: quantityCount,
      price: item.price,
    };
    const result = await AddtoCArt(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleShoppingCart = () => {
    navigate(`/shop`);
  };
  const handleShoppingCheckout = () => {
    navigate(`/shop/shopping-checkout`);
  };
  const handleIncrease = (item) => {
    let quantityCount = Number(item.selected_quantity) + 1;
    setCardElement((old) =>
      old.map((product) => {
        if (product._id == item._id) {
          return {
            ...product,
            selected_quantity: Number(product.selected_quantity) + 1,
          };
        } else {
          return product;
        }
      })
    );
    handleSubmit(item, quantityCount);
  };
  const handleDecrease = (item) => {
    let quantityCount = Number(item.selected_quantity) - 1;
    if (item.selected_quantity > 1) {
      setCardElement((old) =>
        old.map((product) => {
          if (product._id == item._id) {
            return {
              ...product,
              selected_quantity: Number(product.selected_quantity) - 1,
            };
          } else {
            return product;
          }
        })
      );
    }
    handleSubmit(item, quantityCount);
  };
  const totalPrice = cartElement?.reduce((total, product) => {
    return total + product.selected_quantity * product.price;
  }, 0);
  const handleRemoveApi = async () => {
    let postData = { product_id: deletePasssValue.item.product_id };
    const result = await removetoCArt(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleRemove(deletePasssValue.item._id);
      setOpenDeleteSingle(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDeleteSingle(fasle);
    }
  };
  const handleChangeQuantity = (event, item) => {
    let value = event.target.value;
    if (value >= 0 && value <= item.remaining_quantity) {
      setCardElement((old) =>
        old.map((product) => {
          if (product._id == item._id) {
            return {
              ...product,
              selected_quantity: value ? value : "",
            };
          } else {
            return product;
          }
        })
      );
      if (value) {
        handleSubmit(item, value ? value : 0);
      }
    }
  };
  const handleClearAll = async () => {
    setloadingPopUp(true);
    const result = await removetoCArtall();
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenDelete(false);
      setloadingPopUp(false);
      handleRemoveall();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
      setloadingPopUp(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col-12 d-flex mb-3">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <h2 className="ms-3">
                {htmlDecode(shopContentSettings.shop_cart_heading)}
              </h2>
            </div>
          </div>
          <div
            className="cart over_flow "
            style={{ width: "100%", overflow: "auto" }}
          >
            <table className="w-100 table-main ">
              <thead className=" cart__row ">
                <tr>
                  <th colSpan={2} className="text-center">
                    Product
                  </th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">Total</th>
                  <th className="action" style={{ visibility: "hidden" }}>
                    Tota
                  </th>
                </tr>
              </thead>

              <tbody>
                {cartElement && cartElement.length ? (
                  cartElement.map((item, index) => {
                    return (
                      <>
                        {" "}
                        <tr className="cart__row cart-flex cart__border">
                          <td className="cart__image-wrapper cart-flex-item border-cart">
                            <a>
                              {item?.image ? (
                                <img
                                  src={s3baseUrl + item?.image[0]?.image}
                                  className="cart__image-wrapper pointer"
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </a>
                          </td>
                          <td className="cart__meta small--text-left cart-flex-item border-cart">
                            <div className="slider-vid xmark__icon pointer">
                              {/* <CartdelModal handleDelete={handleDelete} item={item} /> */}
                            </div>
                            <div className="list-view-item__title word___wrap">
                              <h6
                                class="d-inline"
                                style={{ color: "white", marginTop: "2px" }}
                              >
                                {item.name}
                              </h6>
                            </div>
                          </td>
                          <td className="cart__price-wrapper small--hide cart-flex-item border-cart">
                            <div className="product-price text-center">
                              <span className="price ">
                                {convertCurrencyToSign(item.currency)}
                                {item?.price?.toFixed(2)}
                              </span>
                            </div>
                          </td>
                          <td className="cart__update-wrapper cart-flex-item text-right  border-cart">
                            <div className="col-12  d-flex justify-content-between mt-2 mb-3 text-center detail-item-quantity">
                              <Tooltip
                                title={`Quantity should be less than or equal to ${item.remaining_quantity}`}
                              >
                                <div className="item-quantity m-auto">
                                  <span
                                    onClick={
                                      item.selected_quantity <= 1
                                        ? ""
                                        : () => handleDecrease(item)
                                    }
                                    className={`cursor-pointer px-2 ${
                                      item.selected_quantity <= 1
                                        ? "item-quantity-diabled"
                                        : ""
                                    }`}
                                  >
                                    -
                                  </span>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    value={item.selected_quantity}
                                    onChange={(e) => {
                                      handleChangeQuantity(e, item);
                                    }}
                                  />
                                  <span
                                    className={`cursor-pointer px-2 ${
                                      item.selected_quantity >=
                                      item.remaining_quantity
                                        ? "item-quantity-diabled"
                                        : ""
                                    }`}
                                    onClick={
                                      item.selected_quantity >=
                                      item.remaining_quantity
                                        ? ""
                                        : () => handleIncrease(item)
                                    }
                                  >
                                    +
                                  </span>
                                </div>
                              </Tooltip>
                            </div>
                          </td>
                          <td className=" text-center small--hide border-cart">
                            <h6>
                              {convertCurrencyToSign(item.currency)}
                              {(item.selected_quantity * item.price).toFixed(2)}
                            </h6>
                          </td>
                          <td className="text-center small--hide  border-cart">
                            <div className="col-1 position-relative">
                              <span
                                className="cart-cross-icon icon-postion"
                                onClick={() => handleAgreeDelete(item, index)}
                              >
                                x
                              </span>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr className="cart__row cart-flex cart__border pb-2 pt-2 text-center">
                    <td
                      colSpan={6}
                      className="border-cart"
                      style={{ fontSize: "18px" }}
                    >
                      {" "}
                      No Item Found
                    </td>
                  </tr>
                )}
              </tbody>

              <tfoot>
                <tr className="cart__border">
                  <td colSpan={6} className="text-end border-cart">
                    <button
                      className="small-contained-button  ms-2"
                      onClick={handleShoppingCart}
                    >
                      {htmlDecode(
                        shopContentSettings.conutinue_shopping_button_text
                      )}
                    </button>

                    {cartElement.length > 0 ? (
                      <button
                        className="small-contained-button add-to-cart-button mt-1 ms-2"
                        onClick={handleAgreeDeleteAll}
                      >
                        {htmlDecode(shopContentSettings.clear_all_button_text)}
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        <div className="payment_box">
          <div className="col-12 cart__footer ">
            <div className="solid-border table-main">
              <div className="row  pb-2">
                <h6 className="col-6 col-sm-6 cart__subtotal-title pb-1 pt-1">
                  {htmlDecode(shopContentSettings.subtotal_text)}
                </h6>
                <div className="col-6 col-sm-6 cart-p  pb-1 pt-1">
                  <h6>£ {totalPrice.toFixed(2)}</h6>
                </div>
              </div>
              <div className="row  pb-2 pt-2">
                <div className="col-6 col-sm-6 cart__subtotal-title">
                  <h6>{htmlDecode(shopContentSettings.tex_text)}</h6>
                </div>
                <div className="col-6 col-sm-6  cart-p">
                  <h6>£0.00</h6>
                </div>
              </div>
              <div className="row  pb-2 pt-2">
                <div className="col-6 col-sm-6 cart__subtotal-title">
                  <span
                    className="text-bold"
                    style={{ color: "var(--portal-theme-primary)" }}
                  >
                    {htmlDecode(shopContentSettings.grand_total_text)}
                  </span>
                </div>
                <div className="col-6 col-sm-6 cart__subtotal-title cart__subtotal cart-p">
                  <span style={{ color: "var(--portal-theme-primary)" }}>
                    £ {totalPrice.toFixed(2)}
                  </span>
                </div>
              </div>
              <Tooltip
                title={cartElement.length > 0 ? "" : "No item in your Cart"}
              >
                <button
                  type="button"
                  onClick={
                    cartElement.length > 0 ? handleShoppingCheckout : " "
                  }
                  className={`mt-2 small-contained-button cartCheckout ${
                    cartElement.length > 0 ? "" : "disable-pointer dim-color"
                  }`}
                >
                  {htmlDecode(
                    shopContentSettings.proceed_to_checkout_button_text
                  )}
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <CustomConfirmationShop
        open={openDeleteSingle}
        setOpen={setOpenDeleteSingle}
        title={"Are you sure you want to remove this item"}
        handleAgree={handleRemoveApi}
      />{" "}
      <CustomConfirmationShop
        open={openDelete}
        setOpen={setOpenDelete}
        setloadingPopUp={setloadingPopUp}
        loadingPopUp={loadingPopUp}
        title={"Are you sure you want to remove all item"}
        handleAgree={handleClearAll}
      />
    </>
  );
};

export default ShopCart;
