import { Avatar } from "@mui/material";
import React from "react";
import { communityHandScreenshot, communityHeart } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  get_date_with_user_time_zone,
  TIME_ZONE_CONVERSION,
} from "src/utils/constants";

export default function UserInfoCard({
  profile_image,
  user_name,
  date,
  activity_type,
  avatar_char,
  is_live_streaming,
  badge_level_info,
}) {
  const { userInfo, adminTimeZone } = useContentSetting();

  return (
    <>
      <div className="post-creator user-info-card align-items-center">
        <div
          className="like-profile-image"
          style={
            badge_level_info && {
              border: `2px solid ${badge_level_info?.color_code}`,
            }
          }
        >
          <Avatar
            src={profile_image}
            alt="photoURL"
            sx={{ width: 36, height: 36 }}
          >
            {avatar_char}
          </Avatar>
          {activity_type &&
            (activity_type === "like" || activity_type === "gratitude") && (
              <div className="tiny-like-icon">
                {activity_type === "like" ? (
                  <img src={communityHeart} alt="" />
                ) : (
                  <img src={communityHandScreenshot} alt="" />
                )}
              </div>
            )}
        </div>
        <div className="creator-name ps-2 text-start">
          <span className="d-flex feed-level">
            <h3 className="mb-0">{`${user_name} ${
              is_live_streaming ? "is live now" : ""
            }`}</h3>
          </span>

          <span className="date-color">
            {TIME_ZONE_CONVERSION(
              date,
              "DD MMM YYYY [at] hh:mm A",
              adminTimeZone,
              userInfo.time_zone
            )}
          </span>
        </div>
      </div>
    </>
  );
}
