import { Padding } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import { get_root_value } from "src/utils/domUtils";

function CustomConfirmationForBlockUser({
  open,
  setOpen,
  handleAgree,
  title,
  isLoading,
}) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: get_root_value("--popup-background-color"),
            color: "green",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle style={{ paddingBottom: "4px" }}>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <p style={{ padding: "0px 25px", color: "white" }}>
          Blocking this user will prevent you from seeing any of their content
          e.g. Posts. They will also not be able to interact with you.
        </p>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAgree}>
            {isLoading ? "Loading..." : "Agree"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmationForBlockUser;
