import { replace } from "lodash";
import millify from "millify";
import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  // Format the number with numeral and replace '.00' if present
  const formatted = replace(numeral(number).format("0.00a"), ".00", "");
  // Convert the suffix to uppercase
  return formatted.replace(/([a-z])$/, (match) => match.toUpperCase());
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

export function get_kmb_number(number) {
  if (number) {
    return millify(number, { precision: 1 });
  } else {
    return number;
  }
}
