import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { s3baseUrl } from "src/config/config";
import LockIcon from "@mui/icons-material/Lock";
import { mission_levels_new } from "src/DAL/MissionLevels/MissionLevels";
import { handle_change_page } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { tick } from "src/assets";
import DoneIcon from "@mui/icons-material/Done";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LevelMissions() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [upgradeButton, setUpgradeButton] = useState(false);
  const [missionLevels, setMissionLevels] = useState([]);
  const [Levels, setLevels] = useState({});

  const [paddingMap, setPaddingMap] = useState({});
  const { mission__id } = useParams();

  const get_mission_levels = async () => {
    const result = await mission_levels_new(mission__id);
    if (result.code === 200) {
      console.log(result, "resultresult");
      setMissionLevels(result.level_badge.missions);
      setLevels(result.level_badge);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDetail = (object) => {
    if (
      object.user_mission_status == "in_progress" ||
      object.user_mission_status == "completed"
    ) {
      navigate(`/mission-levels/level-details/${object._id}`);
    } else {
      navigate(`/mission-levels/level-detail/${object._id}`);
    }
  };

  const replacedString = (string, unlock_coins) => {
    if (!string) return;
    return string.replace(/{unlock_coins}/g, unlock_coins);
  };

  let breadCrumbMenu = [
    {
      title: Levels?.tagline,
      navigation: `/mission-levels`,
      active: false,
    },

    {
      title: "Mission",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    get_mission_levels();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container mission-levels mb-3">
      <div className="mission-level-heading mb-3">
        <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          {Levels.detailed_description && (
            <div
              className="mission_dashboard_description mt-1 mb-2"
              dangerouslySetInnerHTML={{
                __html: Levels.detailed_description,
              }}
            ></div>
          )}
          <div className="challenge-video-card">
            {missionLevels.length > 0 && (
              <div className="row">
                {missionLevels.map((mission) => {
                  let note_id = `note_${mission._id}`;
                  return (
                    <div
                      className="col-12 col-md-6 col-lg-4 mb-3 position-relative"
                      key={mission._id}
                    >
                      {mission.user_mission_status == "not_started" && (
                        <div
                          className="lock-icon-journey-main-container "
                          style={{ left: "-12px" }}
                        >
                          <span className="lock-icon-journey-main">
                            <LockIcon
                              style={{ fontSize: "22px", color: "black" }}
                            />
                          </span>
                        </div>
                      )}
                      {mission.user_mission_status == "completed" && (
                        <div
                          className="lock-icon-journey-main-container-complete "
                          style={{ left: "-12px" }}
                        >
                          <span className="lock-icon-journey-main">
                            <div className="completed-images">
                              <img src={tick} alt="Completed" />
                            </div>
                          </span>
                        </div>
                      )}
                      <div
                        className="card mission-level-card"
                        style={{
                          paddingBottom: paddingMap[note_id] || 0,
                        }}
                        onClick={() => handleDetail(mission)}
                      >
                        <div className="mission-days">
                          {mission.mission_duration} Days Mission
                        </div>
                        <img
                          alt={mission.title}
                          src={s3baseUrl + mission.image.thumbnail_2}
                        />
                        <div className="p-3">
                          <h3>{htmlDecode(mission.title)}</h3>
                          {mission.short_description && (
                            <p className="mui-custom-card-description">
                              {htmlDecode(mission.short_description)}
                            </p>
                          )}
                          {/* {mission.user_mission_status === "not_started" &&
                          mission.content_settings?.lock_description && (
                            <p className="start-mission-note" id={note_id}>
                              <span className="note-label">Note :</span>{" "}
                              {htmlDecode(
                                replacedString(
                                  mission.content_settings?.lock_description,
                                  mission.coins_to_unlock
                                )
                              )}
                            </p>
                          )} */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {missionLevels.length == 0 && !Levels.detailed_description && (
            <RecordNotFound title="No Data Found" />
          )}
        </div>
        {Levels.badge_locked &&
          Levels?.configration?.upgrade_button_url &&
          Levels?.configration?.upgrade_button_text && (
            <div className="col-12">
              <button
                className="mc-button-contained floating-button"
                onClick={() =>
                  handle_change_page(
                    Levels?.configration?.upgrade_button_url,
                    setUpgradeButton
                  )
                }
              >
                {upgradeButton
                  ? "Please Wait..."
                  : Levels?.configration?.upgrade_button_text}
              </button>
            </div>
          )}
      </div>
    </div>
  );
}
