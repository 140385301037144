import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SkeletonColor from "src/pages/MissionLevels/Skulton";
import { useNavigate } from "react-router-dom";
import { handle_change_page } from "src/utils/constants";
import { Typography, Grid, Chip } from "@mui/material";

function UpComingEvents({ upcoming_events_array }) {
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const [isScrollable, setIsScrollable] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -250, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 250, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      // Reset scroll position to the start
      sliderRef.current.scrollLeft = 0;

      // Check if the content is scrollable
      const isOverflowing =
        sliderRef.current.scrollWidth > sliderRef.current.offsetWidth;
      setIsScrollable(isOverflowing);
    }
  }, [upcoming_events_array]);

  return (
    <>
      {/* <div
        className="mission-levels-schedule-tab mt-2"
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <div
          className="container position-relative"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {isScrollable && (
            <button
              className="carousel-control-prev"
              type="button"
              onClick={scrollLeft}
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 9999,
                display: "contents",
              }}
            >
              <div
                className="slider-icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffd889",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              >
                <ChevronLeftIcon style={{ color: "black" }} />
              </div>
              <span className="visually-hidden">Previous</span>
            </button>
          )}
          <div
            className={`scrollable-slider upcoming-events-new h-100 w-100 ps-3 d-flex ${
              isScrollable ? "overflow-auto" : ""
            }`}
            ref={sliderRef}
          >
            {upcoming_events_array.length > 0 ? (
              upcoming_events_array.map((upcoming_events, index) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-6 mb-3 ms-2 me-2"
                  key={index}
                  style={{ flex: "0 0 auto", width: "300px" }}
                >
                  <div
                    className="card h-100"
                    style={{
                      position: "relative",
                      paddingBottom: "50px",
                    }}
                  >
                    <img
                      onClick={() =>
                        navigate(`/mission-control/Upcoming-events/detail`, {
                          state: { upcoming_events },
                        })
                      }
                      className="card-img-top"
                      src={
                        s3baseUrl +
                        upcoming_events?.mission_control_settings?.banner_image
                      }
                      alt="event"
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    <div className="card-body pt-2">
                      <p className="card-text mb-1  upcomingTitile">
                        {htmlDecode(
                          upcoming_events?.mission_control_settings?.title
                        )}
                      </p>
                      <p
                        className="card-title upcomingdes mb-2"
                        dangerouslySetInnerHTML={{
                          __html:
                            upcoming_events?.mission_control_settings
                              ?.short_description,
                        }}
                      ></p>
                      <Box
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          transform: "translateX(-50% 50%)",
                          width: "90%",
                        }}
                      >
                        <button
                          className="small-contained-button"
                          disabled={upcoming_events.is_already_purchase}
                          style={{
                            width: "100%",
                            padding: "10px 15px",
                            fontSize: "14px",
                          }}
                          onClick={
                            upcoming_events.is_already_purchase
                              ? undefined
                              : () =>
                                  handle_change_page(
                                    upcoming_events.page_url,
                                    setIsLoading
                                  )
                          }
                        >
                          {upcoming_events.is_already_purchase
                            ? "Already Subscribed"
                            : htmlDecode(
                                upcoming_events?.mission_control_settings
                                  ?.button_text
                              )}
                        </button>
                      </Box>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <SkeletonColor />
            )}
          </div>
          {isScrollable && (
            <button
              className="carousel-control-next"
              type="button"
              onClick={scrollRight}
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 9999,
                display: "contents",
              }}
            >
              <div
                className="slider-icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffd889",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              >
                <ChevronRightIcon style={{ color: "black" }} />
              </div>
              <span className="visually-hidden">Next</span>
            </button>
          )}
        </div>
      </div> */}
      <Box className="p-0">
        <Grid container spacing={2}>
          {upcoming_events_array.map((session, index) => (
            <Grid item xs={12} md={12} key={index}>
              <Box
                className="card"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "12px",
                  overflow: "hidden",
                  height: "100%",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                }}
              >
                {/* <h4
                variant="h6"
                className="ms-1 p-2 bg-top-session text-session mb-0"
              >
                {session.title}
              </h4> */}

                <Grid container className="bg-session">
                  <Grid item xs={12} sm={3} sx={{ padding: "12px" }}>
                    <Box
                      component="img"
                      src={
                        s3baseUrl +
                        session?.mission_control_settings?.banner_image
                      }
                      onClick={() =>
                        navigate(`/mission-control/Upcoming-events/detail`, {
                          state: { upcoming_events: session },
                        })
                      }
                      alt={session.title}
                      sx={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "150px",
                        objectFit: "cover",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={9} sx={{ padding: "12px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        onClick={() =>
                          navigate(`/mission-control/Upcoming-events/detail`, {
                            state: { upcoming_events: session },
                          })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "16px",
                            marginBottom: "0px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {session?.mission_control_settings?.title}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: "12px",
                            color: "#B0B0B0",
                            marginBottom: "10px",
                          }}
                        >
                          <p
                            className="card-title upcomingdes"
                            dangerouslySetInnerHTML={{
                              __html: htmlDecode(
                                session?.mission_control_settings
                                  ?.short_description
                              ),
                            }}
                          ></p>
                        </Typography>
                      </span>

                      <div className="col-sm-12 col-md-12  mt-2 text-start position-relative text-center">
                        <Chip
                          label={
                            session.is_already_purchase
                              ? "Already Subscribed"
                              : htmlDecode(
                                  session?.mission_control_settings?.button_text
                                )
                          }
                          color="primary"
                          className="me-1 dd-chip chip-update chip-update-new"
                          variant="outlined"
                          disabled={session.is_already_purchase}
                          onClick={
                            session.is_already_purchase
                              ? undefined
                              : () =>
                                  handle_change_page(
                                    session.page_url,
                                    setIsLoading
                                  )
                          }
                        />
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default UpComingEvents;
