import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import { handle_change_page } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UpComingEventsAllDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [upgradeButton, setUpgradeButton] = useState(false);

  const classes = useStyles();
  console.log(state, "statestate");
  return (
    <div className="container mission-levels mb-3">
      <div className="d-flex mb-2">
        <span>
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </span>

        <h2
          style={{
            fontSize: "400",
          }}
        >
          {state?.upcoming_events?.mission_control_settings?.title}
        </h2>
      </div>{" "}
      <div
        className={`profile-cards  community-card-title`}
        style={{
          background: "transparent",
        }}
      >
        <div className="row">
          <div className="col-12 col-lg-8 ">
            <img
              src={
                s3baseUrl +
                state?.upcoming_events?.mission_control_settings?.banner_image
              }
              className="img-fluid"
            />
          </div>
          <div className="col-lg-4 mission-levels-overview-tab py-2 ">
            <p
              className="mt-1"
              dangerouslySetInnerHTML={{
                __html:
                  state?.upcoming_events?.mission_control_settings
                    ?.detail_description,
              }}
            ></p>
          </div>
          <div className="col-12 text-end mt-2">
            <button
              className="small-contained-button mt-2"
              disabled={state?.upcoming_events?.is_already_purchase}
              style={{
                // width: "100%",
                padding: "10px 15px",
                fontSize: "16px",
                // position: "fixed",
                zIndex: "9999999",
                right: "30px",
                bottom: "20px",
                textTransform: "capitalize",
                // width: "20%",
              }}
              onClick={
                state?.upcoming_events?.is_already_purchase
                  ? undefined
                  : () =>
                      handle_change_page(
                        state?.upcoming_events.page_url,
                        setUpgradeButton
                      )
              }
            >
              {state?.upcoming_events.is_already_purchase
                ? "Already Subscribed"
                : htmlDecode(
                    state?.upcoming_events?.mission_control_settings
                      ?.button_text
                  )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
