import React from "react";
import TwitterFeeds from "./TwitterFeeds";
import { Tooltip } from "@mui/material";
import { get_short_string } from "src/utils/constants";
import { get_kmb_number } from "src/utils/formatNumber";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import CurrentPods from "./CurrentPods";

function RightPart({
  feeds_type,
  current_events_array,
  upcoming_events_array,
  eventDetail,
  communityMission,
  livePods,
  startingSoonPods,
  upcomingEvents,
  currenEvents,
  userInfo,
}) {
  return (
    <>
      <div className="community-left-sidebar">
        {(feeds_type === "general" || feeds_type == "program") && (
          <>
            <CurrentPods
              feeds_type={feeds_type}
              livePods={livePods}
              startingSoonPods={startingSoonPods}
            />
            <TwitterFeeds
              upcomingEvents={upcomingEvents}
              currenEvents={currenEvents}
              userInfo={userInfo}
              feeds_type={feeds_type}
            />
          </>
        )}
        {feeds_type === "event" && (
          <>
            <TwitterFeeds
              upcomingEvents={upcoming_events_array}
              currenEvents={current_events_array}
              userInfo={userInfo}
              feeds_type={feeds_type}
              eventDetail={eventDetail}
            />
          </>
        )}
        {feeds_type === "mission" && (
          <div className="hide-in-mobile">
            <div className="">
              <div className="mission-control-card  p-3">
                <div className="d-flex justify-content-between members-leader-board">
                  <h4 style={{ textAlign: "left" }} className="mb-3">
                    Acceptable Mission
                  </h4>
                </div>

                <div className="challenges-card">
                  {communityMission.other_missions.length > 0 ? (
                    communityMission.other_missions.map((item, index) => {
                      return (
                        <div className="challenge-item mt-2 mb-2">
                          <dv className="d-flex justify-content-between">
                            <div className="challenge-title">
                              <Tooltip title={item?.title}>
                                <div className="challenge-title">
                                  {get_short_string(item?.title, 12)}
                                </div>
                              </Tooltip>
                            </div>
                            <div className="" style={{ color: "#ffd889" }}>
                              {get_kmb_number(item.mission_duration)} days
                            </div>
                          </dv>
                        </div>
                      );
                    })
                  ) : (
                    <RecordNotFound
                      title="Data not found"
                      show_new_image={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <TotalPoints /> */}
      </div>
    </>
  );
}

export default RightPart;
