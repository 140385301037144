import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { _get_affiliate_links } from "src/DAL/AffiliateLinks/AffiliateLinks";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import SalePages from "src/components/_dashboard/app/SalePages";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AffiliateLinks() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [linksList, setLinksList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getLinksList = async () => {
    const result = await _get_affiliate_links();
    if (result.code === 200) {
      setLinksList(result.affiliate_links);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLinksList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <PageDescription
        parameter="affiliate_links_description"
        else_title="Affiliate Links"
      />
      <div className="row">
        <div className="col-12">
          <SalePages sourceSessions={linksList} />
        </div>
      </div>
    </div>
  );
}
