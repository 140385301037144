import {
  _get_admin_time_zone_localStorage,
  _get_user_from_localStorage,
} from "src/DAL/localStorage/localStorage";
import moment from "moment";
import moment_tz from "moment-timezone";
import CryptoJS from "crypto-js";
import {
  DashboardIcons,
  DiaryIcon,
  lifescriptIcon,
  mediationIcon,
  the_source_img,
} from "src/assets";
import { token_generate_for_upgrade_portal_api } from "src/DAL/WheelOfLife/WheelOfLifeApi";
import { websiteBaseUrl } from "src/config/config";

export const MAX_RECORDING_MINUTES = 5;

// create a function to encrypt and decrypt the token
export const encryptToken = (token) => {
  return CryptoJS.AES.encrypt(token, "secret-key").toString();
};

export const decryptToken = (token) => {
  try {
    return CryptoJS.AES.decrypt(token, "secret-key").toString(
      CryptoJS.enc.Utf8
    );
  } catch (error) {
    return ""; // Return empty string if decryption fails
  }
};

export const get_date_with_user_time_zone = (
  date,
  format,
  user_time_zone,
  admin_time_zone
) => {
  if (!date || !format || !user_time_zone) {
    return "-";
  }

  var moment = require("moment-timezone");
  var admin_date_time = moment.tz(date, admin_time_zone);
  var user_date_time = admin_date_time.clone().tz(user_time_zone);
  return user_date_time.format(format);
};

export const current_time_with_timezone = (time_zone, format) => {
  return moment().tz(time_zone).format(format);
};

export const get_address = (text) => {
  if (
    text &&
    text !== "undefined" &&
    text !== "null" &&
    text !== "N/A" &&
    text !== "n/a" &&
    !!text
  ) {
    return text;
  }
  return "";
};

export const number_with_comma = (number) => {
  if (number) {
    const fixedNumber = Number(number).toFixed(2);
    return new Intl.NumberFormat().format(fixedNumber);
  }
  return 0;
};

export const TIME_ZONE_CONVERSION = (
  date,
  format,
  from_time_zone = from_time_zone || "Europe/Dublin",
  to_time_zone = moment_tz.tz.guess()
) => {
  let formated_date = moment_tz(date, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm"
  );

  let momentObj = moment_tz.tz(
    formated_date,
    "YYYY-MM-DD HH:mm:ss",
    from_time_zone
  );

  let final_date = moment_tz(momentObj).tz(to_time_zone).format(format);
  return final_date;
};

export const get_date_with_user_time_zone_only = (
  date,
  format,
  user_time_zone
) => {
  if (!date || !format || !user_time_zone) {
    return "-";
  }
  var moment = require("moment-timezone");
  var user_date_time = moment.tz(date, user_time_zone);
  return user_date_time.format(format);
};

export const capitalizeFirst = (str) => {
  if (!str) {
    return;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const splitName = (name) => {
  let first_name = "";
  let last_name = "";
  if (name) {
    const nameParts = name.trim().split(" ");
    first_name = nameParts[0];
    last_name = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  }
  return { first_name, last_name };
};

export const addDaysToDate = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return moment(result).format("DD MMM YYYY");
};

export const dd_date_format = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
export const dd_date_format_UPDATE = (date) => {
  return moment(date).format("DD MMM, YYYY");
};

export const dateDifferenceInDays = (startDate, lastDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(lastDate);
  const Difference_In_Time = date2.getTime() - date1.getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  const NinetyDay = Difference_In_Days;
  return Math.floor(NinetyDay);
};

export const two_date_difference = (start, end, diff_in) => {
  const start_date = moment(moment(start).format("YYYY-MM-DD"));
  const end_date = moment(moment(end).format("YYYY-MM-DD"));
  const total_days = end_date.diff(start_date, diff_in);
  return total_days;
};

export const convertCurrencyToSign = (currency) => {
  currency = currency?.toLowerCase();
  if (currency === "gbp") {
    return "£";
  } else if (currency === "eur") {
    return "€";
  } else if (currency === "usd") {
    return "$";
  } else {
    return "";
  }
};

export const get_short_string = (str, limit = 30) => {
  return str && str.length < limit ? str : str?.slice(0, limit) + "...";
};

export const count_chars = (str) => {
  if (!str) return 0;
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  function countText(node) {
    let charCount = 0;
    function traverse(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        charCount += node.textContent.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((childNode) => traverse(childNode));
      }
    }
    traverse(node);
    return charCount;
  }
  const charCount = countText(doc.body);
  return charCount;
};

export const post_description = (str, limit = 500) => {
  if (!str) return "";
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  function extractText(node, limit) {
    let result = "";
    let charCount = 0;
    function traverse(node) {
      if (charCount >= limit) return;
      if (node.nodeType === Node.TEXT_NODE) {
        const remainingChars = limit - charCount;
        const text = node.textContent.slice(0, remainingChars);
        result += text;
        charCount += text.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        result += `<${node.nodeName.toLowerCase()}`;
        Array.from(node.attributes).forEach((attr) => {
          result += ` ${attr.name}="${attr.value}"`;
        });
        result += ">";

        Array.from(node.childNodes).forEach((childNode) => traverse(childNode));
        result += `</${node.nodeName.toLowerCase()}>`;
      }
    }
    traverse(node);
    return result;
  }

  const extractedText = extractText(doc.body, limit);
  return extractedText + (extractedText.length < str.length ? "..." : "");
};

export function generateRandomID(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }
  return id;
}

export function replacePlaceholders(template, replacements) {
  return template.replace(/\{(.*?)}/g, (match, key) => {
    return replacements[key] || match;
  });
}

export const replaceUserNamesWithHTML = (string, users, editable) => {
  // Normalize line breaks
  string = string.replace(/\r\n/g, "\n");

  function generateUserHTML(user) {
    const random_id = user._id + "-" + generateRandomID(16);
    return `<span spellCheck="false" contentEditable="false" class="mentioned-name" id="${random_id}">${user.first_name} ${user.last_name}</span>`;
  }

  if (users) {
    // Sort users based on offset in descending order
    users.sort((a, b) => b.offset - a.offset);

    // Replace mentions
    users.forEach((user) => {
      const { offset, length } = user;
      const userHTML = generateUserHTML(user);
      string =
        string.slice(0, offset) + userHTML + string.slice(offset + length);
    });

    if (editable) {
      // Replace newlines with <br> tags for editable content
      string = string.replace(/\n/g, "<br>");
    }
  }

  return string;
};

export const decode_markdown = (text) => {
  var outputText = text.replace(/\[(.*?)\]\s?\(.*?\)/g, "$1");
  var replaced = outputText.replace(/\*/g, "");
  return replaced;
};

export const get_exact_string = (text) => {
  let replace_string = "";
  if (text) {
    replace_string = text.replace(/-/g, " ").replace(/_/g, " ");
  }
  return replace_string;
};

export const clickAbleUrl = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <a href={part} target="_blank">
          {part}
        </a>
      );
    }
    return part;
  });
};

export const BOOKING_STATUS = [
  {
    name: "pending",
    label: "Pending",
    bgcolor: "rgb(24, 144, 255)",
  },
  {
    name: "no_action",
    label: "No Action",
    bgcolor: "rgb(204 9 217)",
  },
  {
    name: "reschedule",
    label: "Rescheduled",
    bgcolor: "rgb(255, 193, 7)",
  },
  {
    name: "complete",
    label: "Completed",
    bgcolor: "rgb(84, 214, 44)",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    bgcolor: "rgb(255, 72, 66)",
  },
  {
    name: "no_show",
    label: "No Show",
    bgcolor: "rgb(231 116 18)",
  },
];

const get_date = (add_days, dateTime, userInfo, adminTimeZone, format_time) => {
  let date = moment(
    format_time(dateTime, "YYYY-MM-DD", userInfo, adminTimeZone)
  )
    .add("day", add_days)
    .format("YYYY-MM-DD");
  return date;
};

export const show_proper_words = (text) => {
  let replace_string = "";
  if (text) {
    // Replace hyphens and underscores with spaces
    replace_string = text.replace(/[-_]/g, " ");
    // Capitalize the first letter of every word
    replace_string = replace_string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return replace_string;
};

export const is_lesson_locked = (
  object,
  program,
  userInfo,
  adminTimeZone,
  format_time
) => {
  let lesson_status = false;
  let start_days = object.access_after_days;
  if (start_days > 0) {
    start_days = start_days - 1;
  }

  let unlock_date = get_date(
    start_days,
    program.purchase_date_time,
    userInfo,
    adminTimeZone,
    format_time
  );

  if (program?.program_lock_status) {
    if (object.locked) {
      lesson_status = true;
    }
  } else {
    if (new Date(unlock_date) > new Date()) {
      lesson_status = true;
    }
  }
  return lesson_status;
};

export const TimeZones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export const LONG_TEXT_LIMIT = 200;
export const FEED_TXET_LIMIT = 300;

export const DEFAULT_IMAGE =
  "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";

export function string_avatar(name) {
  if (name) {
    const splitted = name.split(" ");
    if (splitted.length > 1) {
      return `${splitted[0][0]}${splitted[1][0]}`.toUpperCase();
    } else if (splitted.length === 1) {
      return `${splitted[0][0]}`.toUpperCase();
    }
  }
}

export const is_small_screen = () => {
  if (window.innerWidth < 500) {
    return true;
  } else {
    return false;
  }
};

export const is_safari = () => {
  const userAgent = window.navigator.userAgent;
  let get_browser = (type) => userAgent.indexOf(type) !== -1;
  if (get_browser("Chrome") && get_browser("Safari")) {
    return false;
  } else if (userAgent.indexOf("Safari") !== -1) {
    return true;
  } else {
    return false;
  }
  // var isSafari =
  //   /constructor/i.test(window.HTMLElement) ||
  //   (function (p) {
  //     return p.toString() === "[object SafariRemoteNotification]";
  //   })(
  //     !window["safari"] ||
  //       (typeof safari !== "undefined" && window["safari"].pushNotification)
  //   );
  //   return
};

export const handle_open_browser = (link) => {
  if (is_safari()) {
    window.open(link, "_self");
  } else {
    window.open(link);
  }
};

export const handle_change_page = async (page_link, setIsLoading) => {
  setIsLoading(true);
  let page_url = {
    page_url: page_link,
  };
  const result = await token_generate_for_upgrade_portal_api(page_url);
  if (result.code === 200) {
    handle_open_browser(
      `${websiteBaseUrl}auth-redirect-controller/${result.token}`
    );
    setIsLoading(false);
  } else {
    setIsLoading(false);
  }
};

export const handleGetCountry = () => {
  const userTimezone = moment_tz.tz.guess();
  let country = moment_tz.tz.zone(userTimezone).countries();
  if (country.length > 0) {
    country = country[0];
    return country;
  }
};

export const settings_routes = [
  "/payment-request",
  "/transaction",
  "/manage-card",
  "/manage-notification",
  "/qr-scanner",
  "/pending-request",
  "/active-request",
  "/manage-subscription",
  "/change-card",
  "/agreement",
];

export const default_paths = [
  "/change-password",
  "/manage-notification",
  "/qr-scanner",
  "/manage-subscription",
  "/manage-card",
  "/transaction",
  "/payment-request",
  "/chat",
  "/edit-profile",
  "/active-request",
  "/pending-request",
  "/ShopCart",
  "/change-card",
  "/questions",
  "/Thansk-you-page",
  "/email-template",
  "/feed-detail",
  "/terms-and-conditions",
  "/agreement",
  "/leader-boards",
  "/HlsVideoPlayer",
  "/HtmlRecorder",
  "/VideoEncoderComponent",
  "/RecordFrame",
  "/RLIveStream",
  "/unsubscribe",
];

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export function isTimePassedUTC(eventDate, time_zone) {
  const currentDate = moment_tz.tz(time_zone).format("YYYY-MM-DD HH:mm:ss");
  return moment_tz(eventDate).isBefore(currentDate);
}
export const labels = {
  dailyDynamiteLabels: [
    "Where will I focus my energy today?",
    "What am I committed to achieving today?",
    "What 1 decision or action can I take today?",
  ],
  gratitudeLabels: [
    "What are you grateful for today?",
    "Who do you want to send love to today?",
    "What do you desire most out of today?",
  ],
  journal:
    "What did you take away from todays lesson? Keep a note of all your aha moments! ",
};
