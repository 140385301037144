import {
  Accordion,
  AccordionSummary,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { earning_history_api } from "src/DAL/90Day/NinetyDay";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import GeneralQuestionsList from "../GeneralQuestions/GeneralQuestionsList";
import { dd_date_format } from "src/utils/constants";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AllHistoriesQuestions({
  type,
  historyTitle,
  pageTitle,
  created_title,
  is_inline,
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { pathname } = useLocation();
  const { history_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [memberHistories, setMemberHistories] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [contentSettings, setContentSetting] = useState({});

  const expandArea = (index) => {
    let temp_state = [...memberHistories];
    let temp_element = { ...temp_state[index] };
    temp_element.isExpanded = !temp_element.isExpanded;
    temp_state[index] = temp_element;
    setMemberHistories(temp_state);
  };

  const get_tracker_history = async (path) => {
    const result = await earning_history_api(path, type, history_id);
    setContentSetting(result.ninety_day_content_settings);
    if (result.code == 200) {
      let find_index = result.ninety_day_plans_history.findIndex(
        (history) => history._id === history_id
      );
      if (find_index === -1) find_index = 0;
      let new_array = [...result.ninety_day_plans_history];
      if (pageNumber == 0 && result.ninety_day_plans_history.length > 0) {
        new_array[find_index].isExpanded = true;
      }

      setTotlePages(result.total_page);
      setLoadMorePath(result.load_more_url);
      if (path) {
        setMemberHistories((old) => [...old, ...new_array]);
        setPageNumber((old) => old + 1);
      } else {
        setMemberHistories(new_array);
        setPageNumber(1);
      }
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    get_tracker_history(loadMorePath);
  };

  useEffect(() => {
    get_tracker_history();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const replacedString = (item) => {
    let { start_date, target_date, completed_date } = item;
    start_date = dd_date_format(start_date);
    target_date = dd_date_format(target_date);
    let string = contentSettings?.tracker_history_title;
    if (!string) {
      completed_date = moment(completed_date).format("DD MMM YYYY");
      string = historyTitle;
    } else {
      completed_date = dd_date_format(completed_date);
    }
    if (!string) return;
    let reslut = string.replace(/{start_date}/g, start_date);
    let new_result = reslut.replace(/{target_date}/g, target_date);
    return new_result.replace(/{completed_date}/g, completed_date);
  };

  const navigate_to = `/${pathname.split("/")[1]}`;

  return (
    <div className="container">
      <div className="d-flex align-items-center">
        <IconButton
          className="back-screen-button me-2"
          onClick={() => navigate(navigate_to)}
        >
          <ArrowBackIcon />
        </IconButton>
        <div
          dangerouslySetInnerHTML={{
            __html:
              contentSettings?.tracker_history_page_description ||
              `<h2>${pageTitle}</h2>`,
          }}
        ></div>
      </div>

      <div className="row">
        {memberHistories.length > 0 ? (
          memberHistories.map((item, index) => {
            return (
              <div className="col-12 mt-2">
                <Accordion expanded={item.isExpanded}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => expandArea(index)}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="svg-color"
                  >
                    <Typography>{replacedString(item)}</Typography>
                  </AccordionSummary>
                  <div className="px-3 pb-2">
                    <Typography className="theme-color">
                      {contentSettings?.history_questions_list_heading}
                    </Typography>
                    <GeneralQuestionsList
                      questions_list={item.ninety_day_formula.questions}
                      question_answers={
                        item.ninety_day_formula.question_replies
                      }
                      isDisabled={true}
                      hideSubmitButton={true}
                      remove_back_button={true}
                      prevent_single_answer={true}
                      className="inside-accordion-questions"
                      created_for={type}
                      historyTitle={replacedString(item)}
                      created_title={created_title}
                      is_inline={is_inline}
                    />
                  </div>
                </Accordion>
              </div>
            );
          })
        ) : (
          <div className="mt-5">
            <RecordNotFound title="History does not exists" />
          </div>
        )}
        {totlePages >= pageNumber ? (
          <div className="col-12 text-center">
            <button
              className="small-contained-button mt-3"
              onClick={loadMoreData}
              disabled={isLoadingMore}
            >
              {isLoadingMore ? "Loading..." : "Load More"}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
