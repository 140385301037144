import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

export default function PageDescription({ parameter, else_title }) {
  const { contentSettingData } = useContentSetting();

  return (
    <div className="row">
      <div className="col-12">
        {contentSettingData && contentSettingData[parameter] ? (
          <div
            className="dashboard_description mb-2"
            dangerouslySetInnerHTML={{
              __html: contentSettingData[parameter],
            }}
          ></div>
        ) : (
          <h2>{else_title}</h2>
        )}
      </div>
    </div>
  );
}
